import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Bowser from "bowser";

const findBestContentType = (videoTypes, videoCodecs) => {
  const supportedTypes = [];

  for (const videoType of videoTypes) {
    const prefix = `video/${videoType}`;

    for (const videoCodec of videoCodecs) {
      const contentType = `${prefix};codecs=${videoCodec}`;
      if (MediaRecorder.isTypeSupported(contentType)) {
        supportedTypes.push(contentType);
      }
    }

    if (MediaRecorder.isTypeSupported(prefix)) {
      // Also check the plain prefix but with lower priority
      supportedTypes.push(prefix);
    }
  }

  return supportedTypes.length > 0 ? supportedTypes[0] : null;
};

const findSupportedContentType = () => {
  const primaryContentType = findBestContentType(
    ['mp4', 'x-matroska'],
    ['h264', 'avc1', 'av1'],
  );
  if (primaryContentType) {
    return primaryContentType;
  }

  return findBestContentType(
    ['webm', 'ogg'],
    ['vp8,opus', 'vp8', 'vp9,opus', 'vp9'],
  );
};

const useCheckMediaRecorder = () => {
  const { t } = useTranslation();

  const result = useMemo(() => {
    if (!window.MediaRecorder) {
      return { error: t('Unable to record media.') };
    }

    const browser = Bowser.getParser(window.navigator.userAgent);
    const isBadSafari = browser.satisfies({ safari: '<=14.3' });
    if (isBadSafari) {
      // https://bugs.webkit.org/show_bug.cgi?id=85851
      return { error: t('Unable to record media on this version of Safari.') };
    }

    const contentType = findSupportedContentType();
    if (!contentType) {
      return { error: t('Unable to record a supported video format.') };
    }

    return { contentType };
  }, [t]);

  return result;
};

export default useCheckMediaRecorder;