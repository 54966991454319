import React from 'react';
import { Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const AspectRatio = styled(({ children, ...props }) => {
  return (
    <Box {...props}>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        {children}
      </Box>
    </Box>
  );
})(props => ({
  position: 'relative',
  width: '100%',
  paddingTop: `${((props.vertical / props.horizontal) * 100).toFixed(2)}%`,
  '& > *': {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: '#000000',
  }
}));

export default AspectRatio;