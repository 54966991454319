import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as Sentry from "@sentry/browser";
import { BrowserTracing } from "@sentry/tracing";

import startApp from './App';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: "https://07c2ce107a9e4835b7eed193ff0eda51@o1249734.ingest.sentry.io/6410727",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.00,
    beforeBreadcrumb: (breadcrumb, hint) => {
      if (breadcrumb.category === 'fetch' && breadcrumb.data.url.startsWith('data:image/')) {
        breadcrumb.data.url = 'data:image/...';
      }
      return breadcrumb;
    },
  });
}

i18n
  .use(initReactI18next)
  .init({
    lng: 'en',

    // allow keys to be phrases having `:`, `.`
    nsSeparator: false,
    keySeparator: false,

    // do not load a fallback
    fallbackLng: false,

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    }
  }).then(startApp);