import React from 'react';
import { CircularProgress, Backdrop } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const LoadingBackdrop = styled(({ ...props }) =>
  <Backdrop {...props}>
    <CircularProgress color="primary" />
  </Backdrop>
)({
  zIndex: 2000,
  backgroundColor: 'rgba(255, 255, 255, 0.5)',
});

export default LoadingBackdrop;