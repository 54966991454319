import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { styled } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { generatePath, useHistory } from "react-router";

import StyledProductName from '../StyledProductName';
import ProgressButton from '../ProgressButton'
import AuthPage from '../AuthPage';
import api from '../../API';
import useQuery from '../../hooks/useQuery';

const SigninForm = styled(({ returnToPath, ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);

  const canContinue = !!email;

  const handleEmailChange = event => {
    setError(null);
    setEmail(event.target.value);
  };

  const handleContinue = async event => {
    event?.preventDefault();

    if (!canContinue) {
      return;
    }

    setLoading(true)
    try {
      await api.sendMagicLink(email);
      setLoading(false);
      let magicCodeHref = `/magic-code?email=${encodeURIComponent(email)}`;
      if (returnToPath) {
        magicCodeHref += `&returnToPath=${encodeURIComponent(returnToPath)}`;
      }
      history.push(magicCodeHref);
    } catch (e) {
      setLoading(false);
      const message = e?.userMessage?.() || t('Unable to send a magic code at this time. Please contact us.');
      setError(message);
    }
  };

  return (
    <form noValidate {...props} onSubmit={handleContinue}>
      {error && <Alert severity="error">{error}</Alert>}
      <TextField
        variant="outlined"
        margin="normal"
        fullWidth
        label={t('Email address')}
        autoFocus
        disabled={loading}
        value={email}
        onChange={handleEmailChange}
      />
      <ProgressButton
        label={t('Sign in with email address')}
        subLabel={t("We'll send you a magic code for a password-free sign-in.")}
        progress={loading}
        disabled={!canContinue}
        onClick={handleContinue}
      />
    </form>
  );
}
)(props => ({
  marginTop: props.theme.spacing(2),
}));

const RegisterLink = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <Box align="center" {...props}>
      <Typography variant="overline" component="span">
        {t('New to Canvass?')}
      </Typography>{' '}
      <Link variant="overline" href={'#' + generatePath('/register')}>
        {t('Create an account')}
      </Link>
    </Box>
  );
};

export default function SigninPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();
  const returnToPath = query.get('returnToPath');

  useEffect(() => {
    if (api.hasSession()) {
      if (returnToPath) {
        history.push(returnToPath);
      } else {
        history.push('/companies');
      }
    }
  }, [history, returnToPath]);

  const styledTitle = (
    <>
      <span>{t('Sign in to')}</span>&nbsp;
      <StyledProductName />
    </>
  );

  return (
    <AuthPage title={styledTitle}>
      <SigninForm returnToPath={returnToPath}/>
      <RegisterLink mt={6} />
    </AuthPage>
  );
}