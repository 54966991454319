import _ from 'lodash';

import React from 'react';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const FullWidthSelectControl = styled(({ label, labelId, value, onChange, options, ...props }) => {
  const menuItems = options.map(([optionLabel, optionValue]) =>
    <MenuItem value={optionValue} key={optionValue}>{optionLabel}</MenuItem>
  );
  const optionValues = options.map(option => option[1]);
  const selectedValue = _.indexOf(optionValues, value) >= 0 ? value : '';

  if (label && labelId) {
    return (
      <FormControl {...props}>
        <InputLabel id={labelId}>
          {label}
        </InputLabel>
        <Select labelId={labelId} value={selectedValue} onChange={onChange}>
          {menuItems}
        </Select>
      </FormControl>
    );
  } else {
    return (
      <FormControl {...props}>
        <Select value={selectedValue} onChange={onChange}>
          {menuItems}
        </Select>
      </FormControl>
    );
  }
})({
  width: '100%',
});

export default FullWidthSelectControl;