import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { styled, useTheme } from '@material-ui/core/styles';
import { Trans, useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardActions, CardContent, Chip, CircularProgress, Dialog, DialogTitle, DialogContent, FormControl, FormControlLabel, Grid, IconButton, InputLabel, Link, MenuItem, Paper, Select, Switch, Tab, Tabs, TextField, Tooltip, Typography, useMediaQuery, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import SendIcon from '@material-ui/icons/Send';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import AddIcon from '@material-ui/icons/Add';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { Alert } from '@material-ui/lab';
import { BlockPicker } from 'react-color';
import SelectPlanGrid from './SelectPlanGrid';
import ProgressButton from './ProgressButton'
import EmptyTabState from './EmptyTabState';
import FetchedContent from './FetchedContent';
import TabPanel from './TabPanel';
import api from '../API';
import { trackError } from '../errors';
import utils from '../utils';
import useIntegrations from '../hooks/useIntegrations';
import useMediaGroups from '../hooks/useMediaGroups';
import { CopyTextIconButton } from './CopyTextIconButton';

const ToggleSecretIconButton = ({ isVisible, setIsVisible }) => {
  const handleClickToggleVisibility = event => {
    setIsVisible(isVisible => !isVisible);
  };

  const icon = isVisible ? <VisibilityOffIcon /> : <VisibilityIcon />;

  return (
    <IconButton onClick={handleClickToggleVisibility}>
      {icon}
    </IconButton>
  );
};

const IntegrationCard = ({ companyId, integration, onIntegrationRemoved, hasAnyPlan, setTab }) => {
  const { t } = useTranslation();
  const { getIntegrationName, getAuthTokenLabel, isDisplayCompanyId, getCompanyIdLabel } = useIntegrations();
  const [isCompanyIdVisible, setIsCompanyIdVisible] = useState(false);
  const [isAuthTokenVisible, setIsAuthTokenVisible] = useState(false);
  const [removing, setRemoving] = useState(false);

  const handleClickRemoveIntegration = async () => {
    setRemoving(true);
    try {
      await api.deleteIntegration(integration.integrationId);
      if (onIntegrationRemoved) {
        onIntegrationRemoved(integration);
      }
      setRemoving(false);
    } catch (e) {
      setRemoving(false);
    }
  };

  const integrationName = getIntegrationName(integration.integrationType);
  const setupGuideHref = `${process.env.REACT_APP_CANVASS_HELP_CENTER_HREF}/integrations/${integration.integrationType}`;
  const hasAutomaticStageTransitions = !!integration.integrationProperties?.greenhouse?.automaticStageTransitions;

  return (
    <Box mt={1}>
      <Card>
        <CardContent>
          <Typography variant="h5" gutterBottom>
            {integrationName}
          </Typography>
          {
            hasAutomaticStageTransitions &&
            <Box mb={1} display="flex" alignItems="center" color="success.main">
              <VerifiedUserIcon />
              <Box ml={0.5}>
                {t('Automatic transitions through the Canvass stage are enabled')}
              </Box>
            </Box>
          }
          {
            !hasAnyPlan &&
            <Box mt={1} mb={1}>
              <Alert severity="warning">
                <Trans>
                  You'll need to <Link onClick={() => setTab('billing')} style={{ cursor: 'pointer', textDecoration: 'underline' }}>select a plan</Link> before inviting your candidates from within {integrationName}
                </Trans>
              </Alert>
            </Box>
          }
          {
            isDisplayCompanyId(integration.integrationType) &&
            <Box mb={1}>
              <Typography variant="subtitle1" color="textSecondary">
                {getCompanyIdLabel(integration.integrationType)}
              </Typography>
              <Chip
                label={isCompanyIdVisible
                  ? companyId
                  : new Array(Math.floor(1.25 * companyId.length + 1)).join('*')}
                style={{ cursor: 'text', marginRight: '5px' }} />
              <ToggleSecretIconButton isVisible={isCompanyIdVisible} setIsVisible={setIsCompanyIdVisible} />
              <CopyTextIconButton text={companyId} />
            </Box>
          }
          {
            !!integration.integrationProperties.authToken &&
            <>
              <Typography variant="subtitle1" color="textSecondary">
                {getAuthTokenLabel(integration.integrationType)}
              </Typography>
              <Chip
                label={isAuthTokenVisible
                  ? integration.integrationProperties.authToken
                  : new Array(Math.floor(1.25 * integration.integrationProperties.authToken.length + 1)).join('*')}
                style={{ cursor: 'text', marginRight: '5px' }}
              />
              <ToggleSecretIconButton isVisible={isAuthTokenVisible} setIsVisible={setIsAuthTokenVisible} />
              <CopyTextIconButton text={integration.integrationProperties.authToken} />
            </>
          }
        </CardContent>
        <CardActions>
          <Button size="small" color="primary" startIcon={<OpenInNewIcon />} href={setupGuideHref} target="_blank" disabled={removing}>
            {t('View setup guide')}
          </Button>
          <Button size="small" color="secondary" onClick={handleClickRemoveIntegration} disabled={removing}>
            {t('Remove integration')}
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};

const CreateNewIntegration = ({ companyId, existingIntegrationTypes, onIntegrationCreated }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { integrationTypes, getIntegrationName, isMustProvideAuthToken, getAuthTokenLabel } = useIntegrations();
  const [creating, setCreating] = useState(false);
  const [integrationType, setIntegrationType] = useState('');
  const [authToken, setAuthToken] = useState('');
  const [expanded, setExpanded] = useState(true);
  const [error, setError] = useState(null);

  const handleChangeExpanded = (event, newExpanded) => {
    setExpanded(newExpanded);
  };

  const handleClickCreate = async () => {
    setError(null);
    setCreating(true);
    try {
      if (integrationType === 'lever') {
        // Redirect to Lever's OAuth flow
        const response = await api.createLeverOAuthHref(companyId);
        window.open(response.authHref, '_self');
      } else {
        const integration = await api.createIntegration(companyId, integrationType, authToken);
        if (onIntegrationCreated) {
          onIntegrationCreated(integration);
        }
        setIntegrationType('');
        setAuthToken('');
        setCreating(false);
      }
    } catch (e) {
      setCreating(false);
      const message = e?.userMessage?.() || t('Unable to add an integration at this time. Please contact us.');
      setError(message);
    }
  };

  const handleIntegrationTypeChanged = event => {
    setError(null);
    setIntegrationType(event.target.value);
    setAuthToken('');
  };

  const integrationTypeOptions = useMemo(() => {
    const options = [];

    for (const integrationType of integrationTypes) {
      options.push({
        value: integrationType,
        label: getIntegrationName(integrationType),
        disabled: _.indexOf(existingIntegrationTypes, integrationType) >= 0
      });
    }

    return options;
  }, [existingIntegrationTypes, integrationTypes, getIntegrationName]);

  const setupGuideLabel = t('View setup guide for {{integrationName}}', { integrationName: getIntegrationName(integrationType) });
  const setupGuideHref = `${process.env.REACT_APP_CANVASS_HELP_CENTER_HREF}/integrations/${integrationType}`;
  const canCreate = !!integrationType && (!isMustProvideAuthToken(integrationType) || authToken);

  return (
    <Accordion expanded={expanded} onChange={handleChangeExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle2">
          {t('Add an integration')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box width="100%">
          {error &&
            <Box mb={2}>
              <Alert severity="error">{error}</Alert>
            </Box>
          }
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <FormControl variant="filled" style={{ width: '100%' }}>
                <InputLabel id="integration-type">{t('Select integration type')}</InputLabel>
                <Select id="integration-type" fullWidth value={integrationType} onChange={handleIntegrationTypeChanged} disabled={creating}>
                  <MenuItem key="00000000-0000-0000-0000-000000000000" value={''}><em>{t('None')}</em></MenuItem>
                  {integrationTypeOptions.map(({ value, label, disabled }) =>
                    <MenuItem key={value} value={value} disabled={disabled}>
                      {label}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Grid>
            {
              isMustProvideAuthToken(integrationType) && isSmall &&
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label={getAuthTokenLabel(integrationType)}
                  value={authToken}
                  onChange={event => setAuthToken(event.target.value)}
                  disabled={creating}
                />
              </Grid>
            }
            <Grid item xs={12} md={4}>
              <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                {
                  !creating &&
                  <Button fullWidth size="large" variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleClickCreate} disabled={!canCreate}>
                    {t('Add integration')}
                  </Button>
                }
                {creating && <CircularProgress />}
              </Box>
            </Grid>
          </Grid>
          {
            isMustProvideAuthToken(integrationType) && !isSmall &&
            <Grid container spacing={2}>
              <Grid item xs={12} md={8}>
                <TextField
                  fullWidth
                  variant="outlined"
                  label={getAuthTokenLabel(integrationType)}
                  value={authToken}
                  onChange={event => setAuthToken(event.target.value)}
                  disabled={creating}
                />
              </Grid>
            </Grid>
          }
          {
            !!integrationType &&
            <Box mt={2}>
              <Button size="small" color="primary" startIcon={<OpenInNewIcon />} href={setupGuideHref} target="_blank">
                {setupGuideLabel}
              </Button>
            </Box>
          }
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

const IntegrateTab = ({ companyId, setTab }) => {
  const { t } = useTranslation();
  const [integrations, setIntegrations] = useState(null);
  const [planStatus, setPlanStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleIntegrationCreated = newIntegration =>
    setIntegrations(integrations => [newIntegration, ...integrations]);

  const handleIntegrationRemoved = removedIntegration =>
    setIntegrations(integrations => _.filter(integrations, integration => integration.integrationId !== removedIntegration.integrationId));

  const fetchData = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      const [integrationsResponse, planStatus] = await Promise.all([
        api.fetchIntegrations(companyId),
        api.fetchCompanyPlanStatus(companyId),
      ]);
      setIntegrations(integrationsResponse.integrations);
      setPlanStatus(planStatus);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      const message = e?.userMessage?.() || t('Unable to retrieve integrations at this time. Please contact us.');
      setError(message);
    }
  }, [companyId, setLoading, setIntegrations, setPlanStatus, t, setError]);

  useEffect(() => fetchData(), [fetchData]);

  const didDataLoad = !loading && integrations !== null && planStatus !== null;
  const hasIntegrations = integrations && integrations.length > 0;
  const hasAnyPlan = didDataLoad && (['complimentary', 'subscribed'].indexOf(planStatus.subscriptionStatus) >= 0 || planStatus.candidatePacksStatus.allocated > 0);

  return (
    <FetchedContent loading={loading}>
      {error && <Alert severity="error">{error}</Alert>}
      {
        didDataLoad &&
        <>
          <Box>
            <CreateNewIntegration
              companyId={companyId}
              onIntegrationCreated={handleIntegrationCreated}
              existingIntegrationTypes={_.map(integrations, integration => integration.integrationType)}
            />
          </Box>
          {
            !hasIntegrations &&
            <Box mt={1}>
              <Paper>
                <Box p={10}>
                  <EmptyTabState
                    icon={<PowerOffIcon fontSize="large" />}
                    title={t('Unintegrated')}
                    subtitle={t('No integrations added')}
                  />
                </Box>
              </Paper>
            </Box>
          }
          {
            hasIntegrations &&
            _.map(integrations, integration =>
              <IntegrationCard
                key={integration.integrationId}
                companyId={companyId}
                integration={integration}
                onIntegrationRemoved={handleIntegrationRemoved}
                hasAnyPlan={hasAnyPlan}
                setTab={setTab}
              />
            )
          }
        </>
      }
    </FetchedContent>
  );
};

const BillingTab = ({ companyId, closeDialog }) => {
  const { t } = useTranslation();
  const [planStatus, setPlanStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      const planStatus = await api.fetchCompanyPlanStatus(companyId);
      setPlanStatus(planStatus)
      setLoading(false);
    } catch (e) {
      setLoading(false);
      const message = e?.userMessage?.() || t('Unable to retrieve billing information at this time. Please contact us.');
      setError(message);
    }
  }, [companyId, setLoading, setPlanStatus, t, setError]);

  useEffect(() => fetchData(), [fetchData]);

  const didDataLoad = !loading && planStatus !== null;

  return (
    <FetchedContent loading={loading}>
      {error && <Alert severity="error">{error}</Alert>}
      {
        didDataLoad &&
        <PlanSection
          companyId={companyId}
          planStatus={planStatus}
          closeDialog={closeDialog}
        />
      }
    </FetchedContent>
  );
};

const SelectLogo = ({ theme, setTheme }) => {
  const { t } = useTranslation();
  const theme_ = useTheme();
  const inputRef = useRef();
  const hasLogo = !!theme.logoImageGroupId;
  const { mediaGroups, isProcessing } = useMediaGroups(hasLogo ? [theme.logoImageGroupId] : []);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState();
  const isLoading = hasLogo && (isProcessing || !mediaGroups[theme.logoImageGroupId]);

  const handleFileChanged = async event => {
    setError(null);

    const logoFile = event.target.files[0];
    inputRef.current.value = '';

    if (logoFile) {
      if (!logoFile.type.startsWith('image')) {
        setError(t('Must upload an image'));
        return;
      }

      setIsUploading(true);
      try {
        const logoImageGroupId = uuidv4();
        const properties = {};
        const logoDetails = await api.createMediaUpload(
          logoImageGroupId, logoFile.type, properties,
        );
        const logoResponse = await fetch(logoDetails.uploadUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': logoFile.type,
          },
          body: logoFile,
        });
        if (logoResponse.ok) {
          await api.completeMediaUploads([logoDetails.mediaId]);
          setTheme(currentTheme => ({ ...currentTheme, logoImageGroupId }));
        } else {
          setError(t('Unable to upload logo'));
        }
      } catch (error) {
        setError(t('Error uploading logo'));
      }
      setIsUploading(false);
    }
  };

  const handleRemoveLogo = () => {
    const logoImageGroupId = null;
    setTheme(currentTheme => ({ ...currentTheme, logoImageGroupId }))
  };

  return (
    <>
      <Typography variant="h6" gutterBottom>
        {t('Logo')}
      </Typography>
      <Box>
        {
          (isLoading || isUploading) &&
          <Box width="100%" height="200px" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
            <CircularProgress />
          </Box>
        }
        {
          !hasLogo && !isUploading &&
          <Box>
            {
              error &&
              <Box mb={2}>
                <Alert severity="error">{error}</Alert>
              </Box>
            }
            <input type="file" ref={inputRef} onChange={handleFileChanged} />
            <Box mt={2} color={theme_.palette.text.disabled} fontStyle="italic">
              <Typography variant="body2">
                <Trans>
                  The logo should be 400px by 400px and have a transparent background for the best results
                </Trans>
              </Typography>
            </Box>
          </Box>
        }
        {
          hasLogo && !isLoading &&
          <>
            <Box style={{ border: `1px dashed ${theme_.palette.divider}` }}>
              <img src={mediaGroups[theme.logoImageGroupId].variants[0].contentHref} width="100%" alt={t('Logo')} />
            </Box>
            <Box mt={1}>
              <Button color="primary" startIcon={<DeleteIcon />} onClick={handleRemoveLogo}>
                {t('Remove logo')}
              </Button>
            </Box>
          </>
        }
      </Box>
    </>
  );
};

const SelectColor = ({ heading, color, onChange }) => {
  return (
    <>
      <Typography variant="h6" gutterBottom>
        {heading}
      </Typography>
      <BlockPicker triangle="hide" color={color} onChange={onChange} width="100%" />
    </>
  );
};

const ThemeTab = ({ companyId }) => {
  const { t } = useTranslation();
  const theme_ = useTheme();
  const isExtraSmall = useMediaQuery(theme_.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme_.breakpoints.down('sm'));
  const [existingTheme, setExistingTheme] = useState(null);
  const [theme, setTheme] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSaving, setIsSaving] = useState(false);

  const fetchData = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      const [themeResponse] = await Promise.all([
        api.fetchCompanyTheme(companyId),
      ]);
      const existingTheme = !themeResponse.theme
        ? {
          themeEnabled: false,
          themeDefinition: {
            palette: {
              primary: {
                main: '#3f51b5',
              },
              secondary: {
                main: '#f50057',
              }
            }
          },
          logoImageGroupId: null,
        } : themeResponse.theme;
      setExistingTheme(existingTheme);
      setTheme(existingTheme);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      const message = e?.userMessage?.() || t('Unable to retrieve theme at this time. Please contact us.');
      setError(message);
    }
  }, [companyId, setLoading, setExistingTheme, setTheme, t, setError]);

  useEffect(() => fetchData(), [fetchData]);

  const didDataLoad = !loading && theme !== null;

  const handleChangeEnabled = () => {
    setTheme(currentTheme => {
      const newTheme = _.cloneDeep(currentTheme);
      newTheme.themeEnabled = !newTheme.themeEnabled;
      return newTheme;
    });
  };

  const handleSaveChanges = async () => {
    setError(null);
    setIsSaving(true);
    try {
      await api.updateTheme(companyId, theme);
      setExistingTheme(theme);
    } catch (e) {
      const newError = e?.userMessage?.() || t('Unable to update theme at this time. Please contact us.');
      setError(newError);
    }
    setIsSaving(false);
  };

  const canSaveChanges = !_.isEqual(theme, existingTheme);

  return (
    <FetchedContent loading={loading}>
      {error && <Alert severity="error">{error}</Alert>}
      {
        didDataLoad &&
        <>
          <Paper>
            <Box p={2}>
              <FormControlLabel
                control={
                  <Switch
                    checked={theme.themeEnabled}
                    onChange={handleChangeEnabled}
                    color="primary"
                  />
                }
                label={t('Custom theme')}
              />
            </Box>
          </Paper>
          {
            theme.themeEnabled &&
            <Box mt={1}>
              <Paper>
                <Box p={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={3}>
                      <SelectColor
                        heading={t('Primary')}
                        color={theme.themeDefinition.palette.primary.main}
                        onChange={color => setTheme(currentTheme => {
                          const newTheme = _.cloneDeep(currentTheme);
                          newTheme.themeDefinition.palette.primary.main = color.hex;
                          return newTheme;
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <SelectColor
                        heading={t('Secondary')}
                        color={theme.themeDefinition.palette.secondary.main}
                        onChange={color => setTheme(currentTheme => {
                          const newTheme = _.cloneDeep(currentTheme);
                          newTheme.themeDefinition.palette.secondary.main = color.hex;
                          return newTheme;
                        })}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <SelectLogo theme={theme} setTheme={setTheme} />
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Box>
          }
          <Box mt={3} width={isSmall ? (isExtraSmall ? '100%' : '50%') : '25%'}>
            <ProgressButton
              label={t('Save changes')}
              progress={isSaving}
              disabled={!canSaveChanges}
              onClick={handleSaveChanges}
            />
          </Box>
        </>
      }
    </FetchedContent>
  );
};

const EditTemplateArea = ({ companyId, messageTemplate, onSaved, onCancelled, isReadOnly }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const [templateName, setTemplateName] = useState(messageTemplate.templateName);
  const [subjectLine, setSubjectLine] = useState(messageTemplate.subjectLine);
  const [bodyContent, setBodyContent] = useState(messageTemplate.bodyContent);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState(null);

  const isEditExisting = !!messageTemplate.templateId;
  const updatedMessageTemplate = { templateName, subjectLine, bodyContent };
  const areChangesToSave = !_.isEqual(
    _.pick(updatedMessageTemplate, ['templateName', 'subjectLine', 'bodyContent']),
    _.pick(messageTemplate, ['templateName', 'subjectLine', 'bodyContent'])
  );
  const canSaveChanges = areChangesToSave && !!templateName && !!subjectLine && !!bodyContent;

  const handleClickSave = async () => {
    setError(null);
    setIsSaving(true);
    try {
      const savedMessageTemplate = isEditExisting
        ? await api.updateMessageTemplate(messageTemplate.templateId, {
          templateId: messageTemplate.templateId,
          ...updatedMessageTemplate,
        })
        : await api.createMessageTemplate(companyId, updatedMessageTemplate);
      onSaved(savedMessageTemplate);
      setIsSaving(false);
    } catch (e) {
      setIsSaving(false);
      const message = e?.userMessage?.() || t('Unable to save changes at this time. Please contact us.');
      setError(message);
    }
  };

  const handleValueChanged = (setValue, event) => {
    setError(null);
    setValue(event.target.value);
  };

  return (
    <Paper>
      <Box p={3}>
        <Box mb={2}>
          <Typography variant="subtitle2">
            {isEditExisting ? (isReadOnly ? t('View template') : t('Edit template')) : t('Create template')}
          </Typography>
        </Box>
        {
          error &&
          <Box mb={2}>
            <Alert severity="error">{error}</Alert>
          </Box>
        }
        <Box width={isExtraSmall ? '100%' : '50%'}>
          <TextField
            fullWidth
            variant="outlined"
            label={t('Template name')}
            value={templateName}
            disabled={isReadOnly || isSaving}
            onChange={_.partial(handleValueChanged, setTemplateName)}
          />
        </Box>
        <Box mt={2}>
          <TextField
            fullWidth
            variant="outlined"
            label={t('Subject line')}
            value={subjectLine}
            disabled={isReadOnly || isSaving}
            onChange={_.partial(handleValueChanged, setSubjectLine)}
          />
        </Box>
        <Box mt={2}>
          <TextField
            fullWidth
            multiline
            minRows={13}
            maxRows={13}
            variant="outlined"
            label={t('Body content')}
            value={bodyContent}
            disabled={isReadOnly || isSaving}
            onChange={_.partial(handleValueChanged, setBodyContent)}
          />
        </Box>
        {
          isReadOnly &&
          <Box mt={2}>
            <Button fullWidth={isExtraSmall} color="primary" startIcon={<CancelIcon />} onClick={onCancelled}>
              {t('Close')}
            </Button>
          </Box>
        }
        {
          !isReadOnly &&
          <Box mt={2}>
            <Grid container>
              <Grid item xs={12} sm={6} md={3}>
                <ProgressButton
                  label={t('Save changes')}
                  progress={isSaving}
                  disabled={!canSaveChanges}
                  onClick={handleClickSave}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <Box mt={isExtraSmall ? 1 : 2} ml={isExtraSmall ? 0 : 1}>
                  <Button fullWidth={isExtraSmall} color="primary" startIcon={<CancelIcon />} onClick={onCancelled} disabled={isSaving}>
                    {t('Cancel')}
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Box>
        }
      </Box>
    </Paper>
  );
};

const sortMessageTemplates = messageTemplates =>
  utils.sortObjectsBy(messageTemplates, 'templateName');

const TemplatesTab = ({ companyId }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [messageTemplates, setMessageTemplates] = useState(null);
  const [showCreate, setShowCreate] = useState(false);
  const [viewMessageTemplate, setViewMessageTemplate] = useState(null);
  const [editMessageTemplate, setEditMessageTemplate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      const [messageTemplatesResponse] = await Promise.all([
        api.fetchCompanyMessageTemplates(companyId),
      ]);
      setMessageTemplates(messageTemplatesResponse.messageTemplates)
      setLoading(false);
    } catch (e) {
      setLoading(false);
      const message = e?.userMessage?.() || t('Unable to retrieve message templates at this time. Please contact us.');
      setError(message);
    }
  }, [companyId, setLoading, setMessageTemplates, t, setError]);

  useEffect(() => fetchData(), [fetchData]);

  const didDataLoad = !loading && messageTemplates !== null;

  const rows = _.map(messageTemplates || [], messageTemplate => ({
    id: messageTemplate.templateId,
    label: messageTemplate.templateName,
    ...messageTemplate,
  }));

  const columns = [
    {
      field: 'id',
      hide: true,
    },
    {
      field: 'label',
      headerName: t('Template name'),
      flex: 0.80,
    },
    {
      field: 'edit',
      headerName: ' ',
      sortable: false,
      renderCell: params => {
        const handleClick = async event => {
          event.stopPropagation(); // don't select this row after clicking
          setError(null);
          setLoading(true);
          try {
            const messageTemplate = await api.fetchMessageTemplate(params.id);
            if (params.row.isStandard) {
              setViewMessageTemplate(messageTemplate);
            } else {
              setEditMessageTemplate(messageTemplate);
            }
            setLoading(false);
          } catch (e) {
            setLoading(false);
            const message = e?.userMessage?.() || t('Unable to retrieve message template at this time. Please contact us.');
            setError(message);
          }
        };
        return (
          <Box textAlign="center" width="100%">
            <IconButton color="secondary" onClick={handleClick}>
              {params.row.isStandard && <VisibilityIcon />}
              {!params.row.isStandard && <EditIcon />}
            </IconButton >
          </Box>
        );
      }
    },
    {
      field: 'delete',
      headerName: ' ',
      sortable: false,
      renderCell: params => {
        const handleClick = async event => {
          event.stopPropagation(); // don't select this row after clicking        
          const deletedMessageTemplate = _.find(
            messageTemplates,
            messageTemplate => messageTemplate.templateId === params.id,
          );
          setMessageTemplates(messageTemplates => _.filter(
            messageTemplates,
            messageTemplate => messageTemplate.templateId !== params.id
          ));
          try {
            await api.deleteMessageTemplate(params.id);
          } catch (e) {
            // Re-introduce to the grid on error
            setMessageTemplates(messageTemplates => sortMessageTemplates(
              [deletedMessageTemplate, ...messageTemplates]
            ));
            // TODO notify of the error with a toast alert...
          }
        };
        const isDisabled = params.row.isStandard || params.row.inUse;
        const tooltipTitle = params.row.isStandard
          ? t("Can't delete a standard template")
          : (
            params.row.inUse
              ? t("Template is used by a position")
              : t("Can't delete this template")
          );
        const deleteButton = (
          <IconButton color="secondary" onClick={handleClick} disabled={isDisabled}>
            <DeleteIcon />
          </IconButton >
        );
        return (
          <Box textAlign="center" width="100%">
            {
              isDisabled &&
              <Tooltip title={tooltipTitle}>
                <span>
                  {deleteButton}
                </span>
              </Tooltip>
            }
            {!isDisabled && deleteButton}
          </Box>
        );
      }
    }
  ];

  const handleCreateSaved = (newMessageTemplate) => {
    setShowCreate(false);
    setMessageTemplates(messageTemplates => sortMessageTemplates(
      [newMessageTemplate, ...messageTemplates]
    ));
  };

  const handleCreateCancelled = () => {
    setShowCreate(false);
  };

  const handleViewCancelled = () => {
    setViewMessageTemplate(null);
  };

  const handleEditSaved = (updatedMessageTemplate) => {
    setEditMessageTemplate(null);
    setMessageTemplates(messageTemplates => sortMessageTemplates(
      [updatedMessageTemplate, ..._.filter(
        messageTemplates,
        messageTemplate => messageTemplate.templateId !== updatedMessageTemplate.templateId
      )]
    ));
  };

  const handleEditCancelled = () => {
    setEditMessageTemplate(null);
  };

  return (
    <FetchedContent loading={loading}>
      {
        error &&
        <Box mb={2}>
          <Alert severity="error">{error}</Alert>
        </Box>
      }
      {
        didDataLoad && showCreate &&
        <EditTemplateArea
          companyId={companyId}
          messageTemplate={{}}
          onSaved={handleCreateSaved}
          onCancelled={handleCreateCancelled}
        />
      }
      {
        didDataLoad && viewMessageTemplate &&
        <EditTemplateArea
          companyId={companyId}
          messageTemplate={viewMessageTemplate}
          onSaved={() => { }}
          onCancelled={handleViewCancelled}
          isReadOnly={true}
        />
      }
      {
        didDataLoad && editMessageTemplate &&
        <EditTemplateArea
          companyId={companyId}
          messageTemplate={editMessageTemplate}
          onSaved={handleEditSaved}
          onCancelled={handleEditCancelled}
        />
      }
      {
        didDataLoad && !showCreate && !editMessageTemplate && !viewMessageTemplate &&
        <>
          <Box mb={1} display="flex" flexDirection="row-reverse">
            <Box width={isSmall ? "100%" : "33%"}>
              <Button fullWidth size="large" variant="contained" color="primary" startIcon={<AddIcon />} onClick={() => setShowCreate(true)}>
                {t('Add template')}
              </Button>
            </Box>
          </Box>
          <Paper>
            <Box width="100%" height="375px">
              <DataGrid
                disableSelectionOnClick={true}
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                disableColumnMenu
              />
            </Box>
          </Paper>
        </>
      }
    </FetchedContent>
  );
};

const InviteTeamMember = ({ companyId, onMemberInvited }) => {
  const { t } = useTranslation();
  const [inviting, setInviting] = useState(false);
  const [email, setEmail] = useState('');
  const [expanded, setExpanded] = useState(true);
  const [error, setError] = useState(null);

  const handleChangeExpanded = (event, newExpanded) => {
    setExpanded(newExpanded);
  };

  const handleClickSend = async () => {
    setError(null);
    setInviting(true);
    try {
      const member = await api.inviteMember(companyId, email);
      if (onMemberInvited) {
        onMemberInvited(member);
      }
      setEmail('');
      setInviting(false);
    } catch (e) {
      setInviting(false);
      const message = e?.userMessage?.() || t('Unable to invite team member at this time. Please contact us.');
      setError(message);
    }
  };

  const canSend = !!email;

  return (
    <Accordion expanded={expanded} onChange={handleChangeExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle2">
          {t('Invite team member')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box width="100%">
          {error &&
            <Box mb={2}>
              <Alert severity="error">{error}</Alert>
            </Box>
          }
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              <TextField
                autoFocus
                fullWidth
                variant="outlined"
                label={t('Email address')}
                value={email}
                onChange={event => setEmail(event.target.value)}
                disabled={inviting}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Box display="flex" alignItems="center" justifyContent="center" height="100%">
                {
                  !inviting &&
                  <Button fullWidth size="large" variant="contained" color="primary" startIcon={<SendIcon />} onClick={handleClickSend} disabled={!canSend}>
                    {t('Send invite')}
                  </Button>
                }
                {inviting && <CircularProgress />}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

const TeamTab = ({ companyId }) => {
  const { t } = useTranslation();
  const [members, setMembers] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const handleMemberInvited = member =>
    setMembers(members => [member, ...members]);

  const fetchData = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      const [members] = await Promise.all([
        api.fetchCompanyMembers(companyId),
      ]);
      setMembers(members)
      setLoading(false);
    } catch (e) {
      setLoading(false);
      const message = e?.userMessage?.() || t('Unable to retrieve members at this time. Please contact us.');
      setError(message);
    }
  }, [companyId, setLoading, setMembers, t, setError]);

  useEffect(() => fetchData(), [fetchData]);

  const didDataLoad = !loading && members !== null;

  const rows = _.map(members || [], member => ({
    id: member.userId,
    label: member.userRole === 'owner'
      ? t('{{email}} (Owner)', { email: member.email })
      : member.email,
    ...member
  }));

  const columns = [
    {
      field: 'id',
      hide: true,
    },
    {
      field: 'label',
      headerName: t('Email address'),
      flex: 0.80,
    },
    {
      field: 'status',
      headerName: t('Status'),
      renderCell: params => {
        switch (params.row.status) {
          case 'invited':
            return t('Invited');
          case 'active':
            return t('Active');
          default:
            return t('Unknown');
        }
      }
    },
    {
      field: 'remove',
      headerName: ' ',
      sortable: false,
      renderCell: params => {
        const handleClick = async event => {
          event.stopPropagation(); // don't select this row after clicking        
          const removedMember = _.find(
            members,
            member => member.userId === params.id,
          );
          setMembers(members => _.filter(
            members,
            member => member.userId !== params.id
          ));
          try {
            await api.removeMember(companyId, params.id);
          } catch (e) {
            // Re-introduce to the grid on error
            setMembers(members => utils.sortObjectsBy([removedMember, ...members], 'email'));
            // TODO notify of the error with a toast alert...
          }
        };
        return (
          <Box textAlign="center" width="100%">
            <IconButton color="secondary" onClick={handleClick} disabled={params.row.userRole === 'owner'}>
              <RemoveCircleIcon />
            </IconButton >
          </Box>
        );
      }
    }
  ];

  return (
    <FetchedContent loading={loading}>
      {error && <Alert severity="error">{error}</Alert>}
      {
        didDataLoad &&
        <>
          <Box mb={1}>
            <InviteTeamMember
              companyId={companyId}
              onMemberInvited={handleMemberInvited}
            />
          </Box>
          <Paper>
            <Box width="100%" height="375px">
              <DataGrid
                disableSelectionOnClick={true}
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                disableColumnMenu
              />
            </Box>
          </Paper>
        </>
      }
    </FetchedContent>
  );
};

const ManageSubscriptionCTA = ({ companyId }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [isRedirecting, setIsRedirecting] = useState(false);

  const handleClickManageSubscription = async () => {
    setIsRedirecting(true);
    try {
      const session = await api.createPaymentPortalSession({
        companyId,
        returnUrl: window.location.href,
      });
      window.location.href = session.redirectUrl;
    } catch (error) {
      setIsRedirecting(false);
      trackError(error);
    }
  };

  return (
    <Box width={isSmall ? '100%' : '70%'}>
      <Typography variant="body1">
        <Trans>
          We partner with Stripe for simplified billing where you're able to update your
          payment methods, view past invoices, and manage your subscription:
        </Trans>
      </Typography>
      <Box width={isSmall ? (isExtraSmall ? '100%' : '50%') : '36%'}>
        <ProgressButton
          label={t('Manage billing')}
          progress={isRedirecting}
          disabled={false}
          onClick={handleClickManageSubscription}
        />
      </Box>
    </Box>
  );
};

const CandidatePacksSummary = ({ candidatePacksStatus }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(true);

  const handleChangeExpanded = (event, newExpanded) => {
    setExpanded(newExpanded);
  };

  return (
    <Accordion expanded={expanded} onChange={handleChangeExpanded}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography variant="subtitle2">
          {t('Pay-as-you-go credits')}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component={Box}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell width="200px">{t("Allocated")}</TableCell>
                <TableCell align="right">{candidatePacksStatus.allocated}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell width="200px">{t("Interviewed")}</TableCell>
                <TableCell align="right">{candidatePacksStatus.invited}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell width="200px" style={{ borderBottom: "none", fontWeight: "bold" }}>{t("Remaining")}</TableCell>
                <TableCell align="right" style={{ borderBottom: "none", fontWeight: "bold" }}>{candidatePacksStatus.remaining}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </AccordionDetails>
    </Accordion>
  );
};

const PlanSection = ({ companyId, planStatus, closeDialog }) => {
  const { t } = useTranslation();
  const [isPurchaseAdditional, setIsPurchaseAdditional] = useState(false);

  switch (planStatus.subscriptionStatus) {
    case 'complimentary':
      return (
        <Paper>
          <Box p={2} fontStyle="italic" textAlign="center">
            <Typography variant="body1">
              {t("You've been granted complimentary access.")}
            </Typography>
          </Box>
        </Paper>
      );
    case 'subscribed':
      return (
        <Paper>
          <Box p={2}>
            <ManageSubscriptionCTA companyId={companyId} />
          </Box>
        </Paper>
      );
    case 'not_subscribed':
    default:
      return (
        <>
          {
            planStatus.candidatePacksStatus.allocated > 0 &&
            <>
              {
                !isPurchaseAdditional &&
                <>
                  <CandidatePacksSummary candidatePacksStatus={planStatus.candidatePacksStatus} />
                  <Box textAlign="center" mt={2}>
                    <Button color="primary" startIcon={<AddCircleIcon />} onClick={() => setIsPurchaseAdditional(true)}>
                      {t("Purchase additional candidates")}
                    </Button>
                  </Box>
                </>
              }
              {
                isPurchaseAdditional &&
                <>
                  <SelectPlanGrid companyId={companyId} initialPlanType="purchase" onClickContactUs={closeDialog} />
                  <Box textAlign="center" mt={2}>
                    <Button color="primary" startIcon={<CancelIcon />} onClick={() => setIsPurchaseAdditional(false)}>
                      {t("Cancel purchase")}
                    </Button>
                  </Box>
                </>
              }
            </>
          }
          {
            planStatus.candidatePacksStatus.allocated <= 0 &&
            <SelectPlanGrid companyId={companyId} onClickContactUs={closeDialog} />
          }
        </>
      );
  }
};

const CompanySettingsDialogContent = styled(({ children, ...props }) =>
  <DialogContent {...props}>
    <Box mb={2}>
      {children}
    </Box>
  </DialogContent>
)(props => ({
  backgroundColor: props.theme.palette.background.default
}));

const CompanySettingsDialogTitle = styled(({ title, ...props }) =>
  <DialogTitle {...props}>
    {title}
  </DialogTitle>
)(props => ({
  backgroundColor: props.theme.palette.background.default,
  color: props.theme.palette.primary.dark
}));


const DialogCloseButton = styled(({ ...props }) =>
  <IconButton {...props}>
    <CloseIcon />
  </IconButton>
)(props => ({
  position: 'absolute',
  right: props.theme.spacing(1),
  top: props.theme.spacing(1),
  color: props.theme.palette.grey[500]
}));

const CompanySettingsDialog = ({ open, closeDialog, companyId, companyName, ...props }) => {
  const { t } = useTranslation();
  const { integrationTypes } = useIntegrations();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [tab, setTab] = useState('team');

  const handleTabChange = (event, newTab) => setTab(newTab);

  const canIntegrate = integrationTypes.length > 0;

  return (
    <Dialog fullScreen={isExtraSmall} open={open} onClose={closeDialog} fullWidth maxWidth="md" {...props}>
      <DialogCloseButton onClick={closeDialog} />
      <CompanySettingsDialogTitle title={companyName} />
      <CompanySettingsDialogContent>
        <Box mb={2}>
          <Tabs variant={isSmall ? 'scrollable' : 'fullWidth'} scrollButtons="auto" value={tab} onChange={handleTabChange}>
            <Tab key="team" value="team" label={t('Team')} />
            <Tab key="templates" value="templates" label={t('Emails')} />
            <Tab key="theme" value="theme" label={t('Theme')} />
            {
              canIntegrate &&
              <Tab key="integrate" value="integrate" label={t('Integrate')} />
            }
            <Tab key="billing" value="billing" label={t('Billing')} />
          </Tabs>
          <Box mt={2}>
            {
              tab === 'team' &&
              <TabPanel key="team" active={true}>
                <TeamTab companyId={companyId} />
              </TabPanel>
            }
            {
              tab === 'templates' &&
              <TabPanel key="templates" active={true}>
                <TemplatesTab companyId={companyId} />
              </TabPanel>
            }
            {
              tab === 'theme' &&
              <TabPanel key="theme" active={true}>
                <ThemeTab companyId={companyId} />
              </TabPanel>
            }
            {
              canIntegrate && tab === 'integrate' &&
              <TabPanel key="integrate" active={true}>
                <IntegrateTab companyId={companyId} setTab={setTab} />
              </TabPanel>
            }
            {
              tab === 'billing' &&
              <TabPanel key="billing" active={true}>
                <BillingTab companyId={companyId} closeDialog={closeDialog} />
              </TabPanel>
            }
          </Box>
        </Box>
      </CompanySettingsDialogContent>
    </Dialog>
  );
};

export default CompanySettingsDialog;