import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';

import api from '../../API';
import useQuery from '../../hooks/useQuery';

const CompleteLeverIntegration = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();
  const state = query.get('state');
  const code = query.get('code');
  const error = query.get('error');
  const errorDescription = query.get('error_description');

  const completeLeverIntegration = useCallback(async () => {
    try {
      if (!!code) {
        const response = await api.completeLeverOAuth(state, code);
        localStorage.setItem('canvass-global-alert', JSON.stringify({
          'severity': 'success',
          'message': t('Lever integration has been completed!'),
        }));
        history.push(`/companies/${response.companyId}`);
      } else {
        const response = await api.abortLeverOAuth(state, error, errorDescription);
        const message = errorDescription || t('Unable to complete the integration. Please contact us.');
        localStorage.setItem('canvass-global-alert', JSON.stringify({
          'severity': 'error',
          'message': message,
        }));
        history.push(`/companies/${response.companyId}`);
      }
    } catch (e) {
      const message = e?.userMessage?.() || t('Unable to complete the integration. Please contact us.');
      localStorage.setItem('canvass-global-alert', JSON.stringify({
        'severity': 'error',
        'message': message,
      }));
      history.push('/');
    }
  }, [t, state, code, error, errorDescription, history]);

  // Fetch whenever the frontend reference ID changes
  useEffect(completeLeverIntegration, [completeLeverIntegration]);

  return (
    <Box
      position="absolute"
      top={0} right={0} bottom={0} left={0}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <CircularProgress color="primary" />
        <Box mt={2}>
          <Typography variant="h6">
            {t('Completing integration...')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CompleteLeverIntegration;