import React from 'react';
import { styled, useTheme } from '@material-ui/core/styles';
import { Box, Dialog, DialogTitle, DialogContent, IconButton, useMediaQuery } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

import SelectPlanGrid from './SelectPlanGrid';

const PlanDialogContent = styled(({ children, ...props }) =>
  <DialogContent {...props}>
    <Box mb={2}>
      {children}
    </Box>
  </DialogContent>
)(props => ({
  backgroundColor: props.theme.palette.primary.dark
}));

const PlanDialogTitle = styled(({ title, ...props }) =>
  <DialogTitle {...props}>
    {title}
  </DialogTitle>
)(props => ({
  backgroundColor: props.theme.palette.primary.dark,
  color: props.theme.palette.primary.contrastText
}));

const DialogCloseButton = styled(({ ...props }) =>
  <IconButton {...props}>
    <CloseIcon />
  </IconButton>
)(props => ({
  position: 'absolute',
  right: props.theme.spacing(1),
  top: props.theme.spacing(1),
  color: props.theme.palette.grey[500]
}));

const SelectPlanDialog = ({ companyId, initialPlanType, open, closeDialog, ...props }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const title = isExtraSmall
    ? t('Select a plan:')
    : t('Select a plan to invite additional candidates:')

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="md" {...props}>
      <DialogCloseButton onClick={closeDialog} />
      <PlanDialogTitle title={title} />
      <PlanDialogContent>
        <SelectPlanGrid
          companyId={companyId}
          initialPlanType={!!initialPlanType ? initialPlanType : 'subscription'}
          onClickContactUs={closeDialog}
        />
      </PlanDialogContent>
    </Dialog>
  );
};

export default SelectPlanDialog;