/* global Stripe */

import React, { useState } from 'react';
import { generatePath } from 'react-router';
import { styled, useTheme } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, Grid, Paper, Typography, useMediaQuery } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { Trans, useTranslation } from 'react-i18next';

import { v4 as uuidv4 } from 'uuid';

import api from '../API';
import { trackError } from '../errors';
import useAnalytics from '../hooks/useAnalytics';

const PlanTile = ({ name, description, pricing, ctaLabel, onClickCTA, ctaActive, ctaDisabled, ...props }) => {
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const isExactlySmall = useMediaQuery(theme.breakpoints.only('sm'));
  return (
    <Paper elevation={3} {...props}>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        minHeight={isExtraSmall ? '0px' : '275px'}
        p={isExactlySmall ? 2 : 3}
      >
        <Box mb={2} textAlign="center">
          <Typography variant="h5">
            {name}
          </Typography>
        </Box>
        <Box
          flexGrow={1}
          pb={isExtraSmall ? 2 : 0}
          pl={isExtraSmall ? 2 : 0}
          pr={isExtraSmall ? 2 : 0}
          textAlign="center"
          display="flex"
          alignItems="center"
        >
          <Typography variant={isExactlySmall ? 'body2' : 'body1'}>
            {description}
          </Typography>
        </Box>
        <Box mt={2} display="flex" flexDirection="column" alignItems="center">
          {pricing}
          <Box mt={1}>
            {
              ctaActive &&
              <CircularProgress size="1.5em" color="secondary" />
            }
            {
              !ctaActive &&
              <Button size="small" variant="contained" color="secondary" disabled={ctaDisabled} onClick={onClickCTA}>
                {ctaLabel}
              </Button>
            }
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

const YearlyAmount = styled(({ amount, ...props }) =>
  <Typography variant="subtitle2" {...props}>
    <Trans>{amount} / year</Trans>
  </Typography>
)({
  fontSize: '1rem'
});

const MonthlyAmount = styled(({ amount, ...props }) =>
  <Typography variant="subtitle2" {...props}>
    <Trans>{amount} / month</Trans>
  </Typography>
)({
  fontSize: '1rem'
});

const PerCandidateAmount = styled(({ amount, ...props }) =>
  <Typography variant="subtitle2" {...props}>
    <Trans>{amount} / candidate</Trans>
  </Typography>
)({
  fontSize: '1rem'
});

const PlanTypeBox = ({ label }) =>
  <Box width="145px">
    <Typography variant="subtitle1">
      {label}
    </Typography>
  </Box>;

const SelectPlanType = ({ planType, setPlanType }) => {
  const { t } = useTranslation();
  const analytics = useAnalytics();

  const handleChangePlanType = (event, newPlanType) => {
    if (newPlanType) {
      setPlanType(newPlanType);
      analytics.trackEvent('planview', { newPlanType });
    }
  };

  return (
    <ToggleButtonGroup size="small" value={planType} exclusive onChange={handleChangePlanType}>
      <ToggleButton value="purchase">
        <PlanTypeBox label={t('Pay-as-you-go')} />
      </ToggleButton>
      <ToggleButton value="subscription">
        <PlanTypeBox label={t('Subscription')} />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const FrequencyBox = ({ label }) =>
  <Box width="100px">
    <Typography variant="subtitle1">
      {label}
    </Typography>
  </Box>;

const SelectFrequency = ({ frequency, setFrequency, disabled }) => {
  const { t } = useTranslation();
  const analytics = useAnalytics();

  const handleChangeFrequency = (event, newFrequency) => {
    if (newFrequency && !disabled) {
      setFrequency(newFrequency);
      analytics.trackEvent('frequencyview', { newFrequency });
    }
  };

  return (
    <ToggleButtonGroup size="small" value={frequency} exclusive onChange={handleChangeFrequency}>
      <ToggleButton value="monthly" disabled={disabled}>
        <FrequencyBox label={t('Monthly')} />
      </ToggleButton>
      <ToggleButton value="yearly" disabled={disabled}>
        <FrequencyBox label={t('Yearly')} />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const CurrencyBox = ({ symbol }) =>
  <Box width="40px">
    <Typography variant="subtitle1">
      {symbol}
    </Typography>
  </Box>;

const SelectCurrency = ({ currency, setCurrency, disabled }) => {
  const analytics = useAnalytics();

  const handleChangeCurrency = (event, newCurrency) => {
    if (newCurrency && !disabled) {
      setCurrency(newCurrency);
      analytics.trackEvent('currencyview', { newCurrency });
    }
  };

  return (
    <ToggleButtonGroup size="small" value={currency} exclusive onChange={handleChangeCurrency}>
      <ToggleButton value="USD" disabled={disabled}>
        <CurrencyBox symbol="$" />
      </ToggleButton>
      <ToggleButton value="GBP" disabled={disabled}>
        <CurrencyBox symbol="£" />
      </ToggleButton>
      <ToggleButton value="EUR" disabled={disabled}>
        <CurrencyBox symbol="€" />
      </ToggleButton>
    </ToggleButtonGroup>
  );
};

const PURCHASE_AMOUNTS = {
  // development
  'price_1KltRELo2G9LdV5dIs2yyx7l': [5.00, 'USD'],
  'price_1KltRELo2G9LdV5dNPoOvSYY': [4.00, 'GBP'],
  'price_1KltRELo2G9LdV5dRB9Q85bh': [4.50, 'EUR'],
  'price_1KltSFLo2G9LdV5dXx6EXHKs': [4.00, 'USD'],
  'price_1KltSFLo2G9LdV5dhDnz44Oj': [3.20, 'GBP'],
  'price_1KltSFLo2G9LdV5dchDya44c': [3.60, 'EUR'],
  'price_1KltT4Lo2G9LdV5dalYUDPTc': [3.00, 'USD'],
  'price_1KltT4Lo2G9LdV5d7nS0AnCT': [2.40, 'GBP'],
  'price_1KltT4Lo2G9LdV5dBSoLBvtR': [2.70, 'EUR'],
  // production
  'price_1KltWhLo2G9LdV5dzycXgHUW': [5.00, 'USD'],
  'price_1KltWhLo2G9LdV5dk02ROj45': [4.00, 'GBP'],
  'price_1KltWhLo2G9LdV5d6Nm4SBKh': [4.50, 'EUR'],
  'price_1KltWmLo2G9LdV5ddMPkiNZV': [4.00, 'USD'],
  'price_1KltWmLo2G9LdV5dBwJS26Kb': [3.20, 'GBP'],
  'price_1KltWmLo2G9LdV5d2hyczUGa': [3.60, 'EUR'],
  'price_1KltWqLo2G9LdV5dZlQ3mj9N': [3.00, 'USD'],
  'price_1KltWqLo2G9LdV5dtHdxh0zt': [2.40, 'GBP'],
  'price_1KltWqLo2G9LdV5dsSU0EuoJ': [2.70, 'EUR'],
};

const SUBSCRIPTION_AMOUNTS = {
  // development
  'price_1JpJNuLo2G9LdV5daDlyRCpS': [90.00, 'USD'],
  'price_1JpVF6Lo2G9LdV5df85BoIbe': [72.00, 'GBP'],
  'price_1JqP5jLo2G9LdV5dUEZ3RdUg': [81.00, 'EUR'],
  'price_1JpJOQLo2G9LdV5dW6AMubB0': [180.00, 'USD'],
  'price_1JpVSILo2G9LdV5dXclBwKlL': [144.00, 'GBP'],
  'price_1JqP7fLo2G9LdV5dLazoxOmU': [162.00, 'EUR'],
  'price_1KEFCCLo2G9LdV5d5A8PlwsX': [360.00, 'USD'],
  'price_1KEFCCLo2G9LdV5do4iZsJZo': [288.00, 'GBP'],
  'price_1KEFCCLo2G9LdV5dJXLSyha8': [324.00, 'EUR'],
  'price_1JpJNuLo2G9LdV5d1aGUsoGd': [900.00, 'USD'],
  'price_1JpVFjLo2G9LdV5dERAUK1js': [720.00, 'GBP'],
  'price_1JqP5yLo2G9LdV5dC3M399VQ': [810.00, 'EUR'],
  'price_1JpJOQLo2G9LdV5dGhht3uHt': [1800.00, 'USD'],
  'price_1JpVSULo2G9LdV5dzXdLNAJO': [1440.00, 'GBP'],
  'price_1JqP8CLo2G9LdV5dvBdjQGtD': [1620.00, 'EUR'],
  'price_1KEFCCLo2G9LdV5dl0L7Pjcu': [3600.00, 'USD'],
  'price_1KEFCCLo2G9LdV5dZMqJ7LwH': [2880.00, 'GBP'],
  'price_1KEFCDLo2G9LdV5dKrCPTiuZ': [3240.00, 'EUR'],
  // production
  'price_1JpYYPLo2G9LdV5dwl27NYoS': [90.00, 'USD'],
  'price_1JpYYPLo2G9LdV5dMqFU5wyA': [72.00, 'GBP'],
  'price_1JqP0iLo2G9LdV5dC6mukqGu': [81.00, 'EUR'],
  'price_1JpYYVLo2G9LdV5dH9o4mpSA': [180.00, 'USD'],
  'price_1JpYYVLo2G9LdV5dWlIOh0Pu': [144.00, 'GBP'],
  'price_1JqP3ULo2G9LdV5dJlP6G9vp': [162.00, 'EUR'],
  'price_1KEFCMLo2G9LdV5dk7rZxdPM': [360.00, 'USD'],
  'price_1KEFCLLo2G9LdV5dJe6gtci1': [288.00, 'GBP'],
  'price_1KEFCLLo2G9LdV5dh1dk5DEU': [324.00, 'EUR'],
  'price_1JpYYPLo2G9LdV5dVxQFCn87': [900.00, 'USD'],
  'price_1JpYYPLo2G9LdV5donZQZqdx': [720.00, 'GBP'],
  'price_1JqP1OLo2G9LdV5dl81XQqy6': [810.00, 'EUR'],
  'price_1JpYYVLo2G9LdV5dp72K4NgW': [1800.00, 'USD'],
  'price_1JpYYULo2G9LdV5d68NbRznY': [1440.00, 'GBP'],
  'price_1JqP3lLo2G9LdV5d933NlNeb': [1620.00, 'EUR'],
  'price_1KEFCLLo2G9LdV5d1nhlLNIA': [3600.00, 'USD'],
  'price_1KEFCLLo2G9LdV5dmOeN7kac': [2880.00, 'GBP'],
  'price_1KEFCLLo2G9LdV5dSNcepGVP': [3240.00, 'EUR'],
};

const PRICING_TREE = {
  'development': {
    'purchase': {
      'small': {
        'USD': 'price_1KltRELo2G9LdV5dIs2yyx7l',
        'GBP': 'price_1KltRELo2G9LdV5dNPoOvSYY',
        'EUR': 'price_1KltRELo2G9LdV5dRB9Q85bh',
      },
      'medium': {
        'USD': 'price_1KltSFLo2G9LdV5dXx6EXHKs',
        'GBP': 'price_1KltSFLo2G9LdV5dhDnz44Oj',
        'EUR': 'price_1KltSFLo2G9LdV5dchDya44c',
      },
      'large': {
        'USD': 'price_1KltT4Lo2G9LdV5dalYUDPTc',
        'GBP': 'price_1KltT4Lo2G9LdV5d7nS0AnCT',
        'EUR': 'price_1KltT4Lo2G9LdV5dBSoLBvtR',
      },
    },
    'subscription': {
      'monthly': {
        'small': {
          'USD': 'price_1JpJNuLo2G9LdV5daDlyRCpS',
          'GBP': 'price_1JpVF6Lo2G9LdV5df85BoIbe',
          'EUR': 'price_1JqP5jLo2G9LdV5dUEZ3RdUg',
        },
        'medium': {
          'USD': 'price_1JpJOQLo2G9LdV5dW6AMubB0',
          'GBP': 'price_1JpVSILo2G9LdV5dXclBwKlL',
          'EUR': 'price_1JqP7fLo2G9LdV5dLazoxOmU',
        },
        'large': {
          'USD': 'price_1KEFCCLo2G9LdV5d5A8PlwsX',
          'GBP': 'price_1KEFCCLo2G9LdV5do4iZsJZo',
          'EUR': 'price_1KEFCCLo2G9LdV5dJXLSyha8',
        },
      },
      'yearly': {
        'small': {
          'USD': 'price_1JpJNuLo2G9LdV5d1aGUsoGd',
          'GBP': 'price_1JpVFjLo2G9LdV5dERAUK1js',
          'EUR': 'price_1JqP5yLo2G9LdV5dC3M399VQ',
        },
        'medium': {
          'USD': 'price_1JpJOQLo2G9LdV5dGhht3uHt',
          'GBP': 'price_1JpVSULo2G9LdV5dzXdLNAJO',
          'EUR': 'price_1JqP8CLo2G9LdV5dvBdjQGtD',
        },
        'large': {
          'USD': 'price_1KEFCCLo2G9LdV5dl0L7Pjcu',
          'GBP': 'price_1KEFCCLo2G9LdV5dZMqJ7LwH',
          'EUR': 'price_1KEFCDLo2G9LdV5dKrCPTiuZ',
        },
      }
    }
  },
  'production': {
    'purchase': {
      'small': {
        'USD': 'price_1KltWhLo2G9LdV5dzycXgHUW',
        'GBP': 'price_1KltWhLo2G9LdV5dk02ROj45',
        'EUR': 'price_1KltWhLo2G9LdV5d6Nm4SBKh',
      },
      'medium': {
        'USD': 'price_1KltWmLo2G9LdV5ddMPkiNZV',
        'GBP': 'price_1KltWmLo2G9LdV5dBwJS26Kb',
        'EUR': 'price_1KltWmLo2G9LdV5d2hyczUGa',
      },
      'large': {
        'USD': 'price_1KltWqLo2G9LdV5dZlQ3mj9N',
        'GBP': 'price_1KltWqLo2G9LdV5dtHdxh0zt',
        'EUR': 'price_1KltWqLo2G9LdV5dsSU0EuoJ',
      },
    },
    'subscription': {
      'monthly': {
        'small': {
          'USD': 'price_1JpYYPLo2G9LdV5dwl27NYoS',
          'GBP': 'price_1JpYYPLo2G9LdV5dMqFU5wyA',
          'EUR': 'price_1JqP0iLo2G9LdV5dC6mukqGu',
        },
        'medium': {
          'USD': 'price_1JpYYVLo2G9LdV5dH9o4mpSA',
          'GBP': 'price_1JpYYVLo2G9LdV5dWlIOh0Pu',
          'EUR': 'price_1JqP3ULo2G9LdV5dJlP6G9vp',
        },
        'large': {
          'USD': 'price_1KEFCMLo2G9LdV5dk7rZxdPM',
          'GBP': 'price_1KEFCLLo2G9LdV5dJe6gtci1',
          'EUR': 'price_1KEFCLLo2G9LdV5dh1dk5DEU',
        },
      },
      'yearly': {
        'small': {
          'USD': 'price_1JpYYPLo2G9LdV5dVxQFCn87',
          'GBP': 'price_1JpYYPLo2G9LdV5donZQZqdx',
          'EUR': 'price_1JqP1OLo2G9LdV5dl81XQqy6',
        },
        'medium': {
          'USD': 'price_1JpYYVLo2G9LdV5dp72K4NgW',
          'GBP': 'price_1JpYYULo2G9LdV5d68NbRznY',
          'EUR': 'price_1JqP3lLo2G9LdV5d933NlNeb',
        },
        'large': {
          'USD': 'price_1KEFCLLo2G9LdV5d1nhlLNIA',
          'GBP': 'price_1KEFCLLo2G9LdV5dmOeN7kac',
          'EUR': 'price_1KEFCLLo2G9LdV5dSNcepGVP',
        },
      }
    }
  }
};

const getSubscriptionPriceId = (frequency, plan, currency) =>
  PRICING_TREE[process.env.NODE_ENV]['subscription'][frequency][plan][currency];

const getPurchasePriceId = (plan, currency) =>
  PRICING_TREE[process.env.NODE_ENV]['purchase'][plan][currency];

const getNavigatorLanguages = () => {
  // https://github.com/supukarmin/navigator-languages
  if (typeof navigator === 'object') {
    var t = 'anguage', n = navigator, f;
    f = n['l' + t + 's'];
    return f && f.length ? f : (t = n['l' + t] ||
      n['browserL' + t] ||
      n['userL' + t]) ? [t] : t;
  }
};

const getLanguage = () => {
  const languages = getNavigatorLanguages();
  return languages ? languages[0] : 'en-US';
};

const formatPlanAmount = (amount, currency) => {
  // Display no minor units at all when there aren't any, or
  // display them to two decimal places...
  const language = getLanguage();
  const formattedAmount = new Intl.NumberFormat(language, {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
  }).format(amount);
  if (formattedAmount.indexOf('.') < 0) {
    return formattedAmount;
  }
  return new Intl.NumberFormat(language, {
    style: 'currency',
    currency,
    minimumFractionDigits: 2,
  }).format(amount);
};

const buildSubscriptionAmount = (amount, currency, frequency) => {
  switch (frequency) {
    case 'monthly':
      return <MonthlyAmount amount={formatPlanAmount(amount, currency)} />;
    case 'yearly':
      return <YearlyAmount amount={formatPlanAmount(amount, currency)} />;
    default:
      throw new Error(`Unexpected subscription frequency: ${frequency}`);
  }
};

const SelectPlanGrid = ({ companyId, initialPlanType, onClickContactUs, ...props }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [planType, setPlanType] = useState(!!initialPlanType ? initialPlanType : 'purchase');
  const [frequency, setFrequency] = useState('monthly');
  const [currency, setCurrency] = useState('USD');
  const [selectedPlan, setSelectedPlan] = useState();

  const [smallSubscriptionAmount, smallSubscriptionCurrency] = SUBSCRIPTION_AMOUNTS[
    getSubscriptionPriceId(frequency, 'small', currency)
  ];
  const formattedSmallSubscriptionAmount = buildSubscriptionAmount(smallSubscriptionAmount, smallSubscriptionCurrency, frequency);

  const [mediumSubscriptionAmount, mediumSubscriptionCurrency] = SUBSCRIPTION_AMOUNTS[
    getSubscriptionPriceId(frequency, 'medium', currency)
  ];
  const formattedMediumSubscriptionAmount = buildSubscriptionAmount(mediumSubscriptionAmount, mediumSubscriptionCurrency, frequency);

  const [largeSubscriptionAmount, largeSubscriptionCurrency] = SUBSCRIPTION_AMOUNTS[
    getSubscriptionPriceId(frequency, 'large', currency)
  ];
  const formattedLargeSubscriptionAmount = buildSubscriptionAmount(largeSubscriptionAmount, largeSubscriptionCurrency, frequency);

  const [smallPurchaseAmount, smallPurchaseCurrency] = PURCHASE_AMOUNTS[getPurchasePriceId('small', currency)];
  const formattedSmallPurchaseAmount = (
    <PerCandidateAmount amount={formatPlanAmount(smallPurchaseAmount, smallPurchaseCurrency)} />
  );

  const [mediumPurchaseAmount, mediumPurchaseCurrency] = PURCHASE_AMOUNTS[getPurchasePriceId('medium', currency)];
  const formattedMediumPurchaseAmount = (
    <PerCandidateAmount amount={formatPlanAmount(mediumPurchaseAmount, mediumPurchaseCurrency)} />
  );

  const [largePurchaseAmount, largePurchaseCurrency] = PURCHASE_AMOUNTS[getPurchasePriceId('large', currency)];
  const formattedLargePurchaseAmount = (
    <PerCandidateAmount amount={formatPlanAmount(largePurchaseAmount, largePurchaseCurrency)} />
  );

  const isSubscription = planType === 'subscription';

  const redirectToCheckout = async (stripePriceId, companyId, frontendReferenceId, successUrl) => {
    const checkoutSession = await api.createCheckoutSession({
      stripePriceId,
      companyId,
      frontendReferenceId,
      successUrl,
      cancelUrl: window.location.href,
    });
    const stripe = new Stripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
    // Ultimately return to where they started...
    localStorage.setItem('canvass-post-checkout-redirect-path', window.location.hash.slice(1));
    stripe.redirectToCheckout({
      sessionId: checkoutSession.sessionId,
    });
  };

  const buildHandleClickCTA = plan => {
    return async () => {
      const frontendReferenceId = uuidv4();
      try {
        setSelectedPlan(plan);
        const stripePriceId = planType === 'subscription'
          ? getSubscriptionPriceId(frequency, plan, currency)
          : getPurchasePriceId(plan, currency);
        const successParams = new URLSearchParams({ frontendReferenceId }).toString();
        const successUrl = window.location.href.replace(
          window.location.hash,
          '#' + generatePath(`/companies/${companyId}/confirm-checkout`) + `?${successParams}`
        );
        await redirectToCheckout(stripePriceId, companyId, frontendReferenceId, successUrl);
      } catch (error) {
        setSelectedPlan(null);
        trackError(error);
      }
    };
  };

  const handleContactUs = e => {
    if (window.$crisp) {
      window.$crisp.push(["do", "chat:show"]);
      window.$crisp.push(["do", "chat:open"]);
    } else {
      window.open(`mailto:${process.env.REACT_APP_CANVASS_SUPPORT_EMAIL}?subject=Custom plan for Canvass`)
    }
    if (onClickContactUs) {
      onClickContactUs();
    }
  };

  return (
    <Box {...props}>
      <Box
        display="flex"
        flexDirection={isSmall ? 'column' : 'row'}
        alignItems="center"
      >
        <Box flexGrow={1}>
          <Box
            display="flex"
            flexDirection={isSmall ? 'column' : 'row'}
            alignItems="center"
          >
            <Box mr={!isSmall ? 2 : 0}>
              <Paper>
                <SelectPlanType
                  planType={planType}
                  setPlanType={setPlanType}
                  disabled={!!selectedPlan}
                />
              </Paper>
            </Box>
            {
              isSubscription &&
              <Box mt={isSmall ? 2 : 0}>
                <Paper>
                  <SelectFrequency
                    frequency={frequency}
                    setFrequency={setFrequency}
                    disabled={!!selectedPlan}
                  />
                </Paper>
              </Box>
            }
          </Box>
        </Box>
        <Box mt={isSmall ? 2 : 0}>
          <Paper>
            <SelectCurrency
              currency={currency}
              setCurrency={setCurrency}
              disabled={!!selectedPlan}
            />
          </Paper>
        </Box>
      </Box>
      <Box mt={2}>
        {
          !isSubscription &&
          <Grid container spacing={2}>
            <Grid item xs={12} sm={3}>
              <PlanTile
                name={t('10-pack')}
                description={
                  <Trans>
                    Credits for interviewing<br /><b>10 candidates</b>
                  </Trans>
                }
                pricing={formattedSmallPurchaseAmount}
                ctaLabel={t('Buy now')}
                onClickCTA={buildHandleClickCTA('small')}
                ctaActive={selectedPlan === 'small'}
                ctaDisabled={!!selectedPlan}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <PlanTile
                name={t('20-pack')}
                description={
                  <Trans>
                    Credits for interviewing<br /><b>20 candidates</b>
                  </Trans>
                }
                pricing={formattedMediumPurchaseAmount}
                ctaLabel={t('Buy now')}
                onClickCTA={buildHandleClickCTA('medium')}
                ctaActive={selectedPlan === 'medium'}
                ctaDisabled={!!selectedPlan}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <PlanTile
                name={t('40-pack')}
                description={
                  <Trans>
                    Credits for interviewing<br /><b>40 candidates</b>
                  </Trans>
                }
                pricing={formattedLargePurchaseAmount}
                ctaLabel={t('Buy now')}
                onClickCTA={buildHandleClickCTA('large')}
                ctaActive={selectedPlan === 'large'}
                ctaDisabled={!!selectedPlan}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <PlanTile
                name={t('Larger packs')}
                description={
                  <Trans>
                    Discounted rates available for greater volume
                  </Trans>
                }
                ctaLabel={t('Contact us')}
                onClickCTA={handleContactUs}
                ctaActive={selectedPlan === 'custom'}
                ctaDisabled={!!selectedPlan}
              />
            </Grid>
          </Grid>
        }
        {
          isSubscription &&
          <>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <PlanTile
                  name={t('Starter')}
                  description={
                    <Trans>
                      For companies having<br /><b>under 50 employees</b><br /><br />
                      Unlimited usage
                    </Trans>
                  }
                  pricing={formattedSmallSubscriptionAmount}
                  ctaLabel={t('Subscribe')}
                  onClickCTA={buildHandleClickCTA('small')}
                  ctaActive={selectedPlan === 'small'}
                  ctaDisabled={!!selectedPlan}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <PlanTile
                  name={t('Growth')}
                  description={
                    <Trans>
                      For companies having<br /><b>50 to 250 employees</b><br /><br />
                      Unlimited usage
                    </Trans>
                  }
                  pricing={formattedMediumSubscriptionAmount}
                  ctaLabel={t('Subscribe')}
                  onClickCTA={buildHandleClickCTA('medium')}
                  ctaActive={selectedPlan === 'medium'}
                  ctaDisabled={!!selectedPlan}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <PlanTile
                  name={t('Scale')}
                  description={
                    <Trans>
                      For companies having<br /><b>251 to 500 employees</b><br /><br />
                      Unlimited usage
                    </Trans>
                  }
                  pricing={formattedLargeSubscriptionAmount}
                  ctaLabel={t('Subscribe')}
                  onClickCTA={buildHandleClickCTA('large')}
                  ctaActive={selectedPlan === 'large'}
                  ctaDisabled={!!selectedPlan}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <PlanTile
                  name={t('Custom')}
                  description={
                    <Trans>
                      For larger companies, high volume processes, recruitment agencies, and other use cases
                    </Trans>
                  }
                  ctaLabel={t('Contact us')}
                  onClickCTA={handleContactUs}
                  ctaActive={selectedPlan === 'custom'}
                  ctaDisabled={!!selectedPlan}
                />
              </Grid>
            </Grid>
            {
              frequency === 'monthly' &&
              <Box mt={2}>
                <Paper elevation={3}>
                  <Box p={1.5} textAlign="center">
                    <Typography variant="body1">
                      <Trans>
                        <span style={{ textDecoration: 'underline' }}>Cancel at any time</span> or switch to yearly billing for a discount
                      </Trans>
                    </Typography>
                  </Box>
                </Paper>
              </Box>
            }
          </>
        }
      </Box>
    </Box >
  );
};

export default SelectPlanGrid;