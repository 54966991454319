import _ from 'lodash';

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Hidden, Paper, Stepper, Step, StepLabel, StepContent, TextField, Typography, useMediaQuery } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import CancelIcon from '@material-ui/icons/Cancel';
import useQuery from '../../hooks/useQuery';
import { useHistory, useParams } from 'react-router';

import usePacingOptions from '../../hooks/usePacingOptions';
import LoadingBackdrop from '../LoadingBackdrop';
import FullWidthSelectControl from '../FullWidthSelectControl';
import SnackbarAlert from '../SnackbarAlert';
import api from '../../API';
import utils from '../../utils';
import InterviewQuestionsBuilder from '../InterviewQuestionsBuilder';
import useMediaGroups from '../../hooks/useMediaGroups';

const TransparentStepper = styled(({ children, ...props }) =>
  <Stepper {...props}>
    {children}
  </Stepper>
)(props => ({
  backgroundColor: props.theme.palette.background.default,
}));

const StepActions = ({ nextLabel, nextEnabled, handleClickNext, backLabel, handleClickBack, ...props }) =>
  <Box display="flex" mt={1} {...props}>
    {
      nextLabel &&
      <Box mr={1}>
        <Button variant="contained" color="primary" onClick={handleClickNext} disabled={!nextEnabled}>
          {nextLabel}
        </Button>
      </Box>
    }
    {
      backLabel &&
      <Button onClick={handleClickBack}>
        {backLabel}
      </Button>
    }
  </Box>;

const NameStep = ({ initialJobTitle, saveJobTitle, goNext }) => {
  const { t } = useTranslation();
  const [jobTitle, setJobTitle] = useState(initialJobTitle);

  const handleClickNext = () => {
    saveJobTitle(jobTitle);
    goNext();
  };

  return (
    <Paper>
      <Box p={2}>
        <Box mb={2}>
          <Grid container>
            <Grid item xs={12} md={6}>
              <TextField
                autoFocus
                fullWidth
                variant="outlined"
                margin="normal"
                label={t('Position name')}
                placeholder={t('e.g. Senior Engineer')}
                value={jobTitle}
                onChange={event => setJobTitle(event.target.value)}
              />
            </Grid>
          </Grid>
        </Box>
        <StepActions
          nextLabel={t('Next')} nextEnabled={!!jobTitle} handleClickNext={handleClickNext}
        />
      </Box>
    </Paper>
  );
};

const PacingStep = ({ initialPacing, createPosition, goNext, goBack }) => {
  const { t } = useTranslation();
  const {
    thinkingTimeOptions,
    answerTimeOptions,
    retakesOptions,
  } = usePacingOptions();
  const [thinkingTime, setThinkingTime] = useState(
    (_.isUndefined(initialPacing.thinkingTime) || _.isNull(initialPacing.thinkingTime))
      ? -1
      : initialPacing.thinkingTime
  );
  const [answerTime, setAnswerTime] = useState(initialPacing.answerTime);
  const [retakes, setRetakes] = useState(
    (_.isUndefined(initialPacing.retakes) || _.isNull(initialPacing.retakes))
      ? -1
      : initialPacing.retakes
  );

  const assemblePacing = () => ({
    thinkingTime: thinkingTime < 0 ? null : thinkingTime,
    answerTime,
    retakes: retakes < 0 ? null : retakes,
  });

  const handleClickNext = () => {
    goNext();
    const pacing = assemblePacing();
    createPosition(pacing);
  };

  return (
    <Paper>
      <Box p={2}>
        <Box mb={2}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6} key="thinking-time">
              <FullWidthSelectControl
                label={t('Thinking time')}
                labelId="thinking-time-label"
                value={thinkingTime}
                onChange={event => setThinkingTime(event.target.value)}
                options={thinkingTimeOptions}
              />
            </Grid>
            <Grid item xs={12} md={6} key="answer-time">
              <FullWidthSelectControl
                label={t('Max answer length')}
                labelId="answer-time-label"
                value={answerTime}
                onChange={event => setAnswerTime(event.target.value)}
                options={answerTimeOptions}
              />
            </Grid>
            <Grid item xs={12} md={6} key="retakes">
              <FullWidthSelectControl
                label={t('Number of retakes')}
                labelId="retakes-label"
                value={retakes}
                onChange={event => setRetakes(event.target.value)}
                options={retakesOptions}
              />
            </Grid>
          </Grid>
        </Box>
        <StepActions
          nextLabel={t('Finish')} handleClickNext={handleClickNext} nextEnabled={true}
          backLabel={t('Back')} handleClickBack={goBack}
        />
      </Box>
    </Paper>
  );
};

const InterviewQuestionsStep = ({ companyId, interviewQuestions, saveInterviewQuestions, goNext, goBack }) => {
  const { t } = useTranslation();
  const mediaGroupIds = utils.extractMediaGroupIdsForInterview(interviewQuestions);
  const { mediaGroups, isProcessing } = useMediaGroups(mediaGroupIds);
  const hasQuestions = interviewQuestions.questions.length > 0 && _.every(
    interviewQuestions.questions,
    question => !!question.videoGroupId || question.title.length > 0
  );
  const nextEnabled = hasQuestions && !isProcessing
  return (
    <>
      <Box mt={1} mb={2}>
        <InterviewQuestionsBuilder
          companyId={companyId}
          interviewQuestions={interviewQuestions}
          setInterviewQuestions={saveInterviewQuestions}
          mediaGroups={mediaGroups}
        />
      </Box>
      <StepActions
        nextLabel={t('Next')} handleClickNext={goNext} nextEnabled={nextEnabled}
        backLabel={t('Back')} handleClickBack={goBack}
      />
    </>
  );
};

const UnfinishedDraftDialog = ({ draft, onDiscard, onContinue, ...props }) => {
  const { t } = useTranslation();
  return (
    <Dialog {...props}>
      <DialogTitle>{t('Restore draft')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(`We've found an unfinished draft of the {{jobTitle}} position — would you like to continue from where you left off?`, draft)}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDiscard} color="primary">
          {t('No, discard draft')}
        </Button>
        <Button onClick={onContinue} color="primary">
          {t('Yes, restore draft')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const buildDraftKey = companyId => `draft-${companyId}`;

const hasDraft = (draftKey) => {
  return !!getDraft(draftKey);
};

const saveDraft = (draftKey, draft) => {
  const serialised = JSON.stringify(draft);
  localStorage.setItem(draftKey, serialised);
};

const getDraft = (draftKey) => {
  const serialised = localStorage.getItem(draftKey);
  try {
    return JSON.parse(serialised);
  } catch (e) {
    return null;
  }
};

const clearDraft = (draftKey) => {
  localStorage.removeItem(draftKey);
};

const DEFAULT_JOB_TITLE = '';
const DEFAULT_PACING = {
  thinkingTime: 30,
  answerTime: 120,
  retakes: 0,
};
const DEFAULT_INTERVIEW_QUESTIONS = {
  questions: [],
};

const assembleFromParts = (jobTitle, pacing, interviewQuestions) => ({
  jobTitle: jobTitle,
  interview: {
    pacing,
    ...interviewQuestions
  }
});

const isAllDefaultValues = (position) => {
  const emptyPosition = assembleFromParts(
    DEFAULT_JOB_TITLE,
    DEFAULT_PACING,
    DEFAULT_INTERVIEW_QUESTIONS,
  );
  return _.isEqual(position, emptyPosition);
};

const NewPositionSteps = ({ companyId, fromDraft, ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const draftKey = buildDraftKey(companyId);
  const [activeStep, setActiveStep] = useState(0);
  const [jobTitle, setJobTitle] = useState(
    fromDraft
      ? fromDraft.jobTitle
      : DEFAULT_JOB_TITLE
  );
  const [interviewQuestions, setInterviewQuestions] = useState(
    fromDraft
      ? _.pick(fromDraft.interview, ['intro', 'questions', 'outro'])
      : _.cloneDeep(DEFAULT_INTERVIEW_QUESTIONS)
  );
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  const initialPacing = fromDraft
    ? fromDraft.interview.pacing
    : DEFAULT_PACING;

  const assemblePosition = useCallback(pacing =>
    assembleFromParts(jobTitle, pacing || initialPacing, interviewQuestions),
    [jobTitle, initialPacing, interviewQuestions],
  );

  const assembleAndSaveDraft = useCallback(() => {
    const draft = assemblePosition();
    if (!isAllDefaultValues(draft)) {
      saveDraft(draftKey, draft);
    }
  }, [assemblePosition, draftKey]);

  useEffect(assembleAndSaveDraft, [assembleAndSaveDraft]);

  const saveData = async pacing => {
    try {
      const newPosition = assemblePosition(pacing);
      const position = await api.createPosition(companyId, newPosition);
      clearDraft(draftKey);
      const newPositionId = position.positionId;
      setSaving(false);
      history.push(`/companies/${companyId}/positions/${newPositionId}?celebrate=true`);
    } catch (e) {
      goBack();
      setSaving(false);
      const message = e?.userMessage?.() || t('Unable to save position at this time. Please contact us.');
      setError(message);
    }
  };

  const goNext = () => setActiveStep(prevStep => prevStep + 1);

  const goBack = () => setActiveStep(prevStep => prevStep - 1);

  const createPosition = pacing => {
    setSaving(true);
    setError(null);
    saveData(pacing);
  };

  const steps = [
    {
      label: t('Enter a position name'),
      component: props => <NameStep {...props} />,
      props: {
        goNext,
        saveJobTitle: setJobTitle,
        initialJobTitle: jobTitle,
      }
    },
    {
      label: t('Record your questions'),
      component: props => <InterviewQuestionsStep {...props} />,
      props: {
        companyId,
        goNext,
        goBack,
        saveInterviewQuestions: setInterviewQuestions,
        interviewQuestions: interviewQuestions,
      }
    },
    {
      label: t("Adjust the pacing"),
      component: props => <PacingStep {...props} />,
      props: {
        goNext,
        goBack,
        initialPacing,
        createPosition,
      }
    },
  ];

  return (
    <>
      <TransparentStepper activeStep={activeStep} orientation="vertical" {...props}>
        {steps.map(definition =>
          <Step key={definition.label}>
            <StepLabel>{definition.label}</StepLabel>
            <StepContent>
              {definition.component({ ...definition.props })}
            </StepContent>
          </Step>
        )}
      </TransparentStepper>
      <LoadingBackdrop open={saving} />
      <SnackbarAlert severity="error" message={error} setMessage={setError} />
    </>
  );
};

const NewPositionPage = ({ ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const { companyId } = useParams();
  const query = useQuery();
  const title = query.get('title');
  const cancellable = query.get('cancellable') === 'true';
  const hasTitle = !!title;
  const draftKey = buildDraftKey(companyId);
  const [addressedFoundDraft, setAddressedFoundDraft] = useState(!hasDraft(draftKey));
  const [foundDraft, setFoundDraft] = useState(getDraft(draftKey));
  const [fromDraft, setFromDraft] = useState();

  const handleClickCancel = useMemo(() => () => {
    clearDraft(draftKey);
    history.goBack();
  }, [history, draftKey]);

  const cancelButton = useMemo(() => {
    return (
      <Button color="primary" startIcon={<CancelIcon />} onClick={handleClickCancel}>
        {t('Cancel')}
      </Button>
    );
  }, [t, handleClickCancel]);

  if (!addressedFoundDraft) {
    const discardFoundDraft = () => {
      clearDraft(draftKey);
      setFoundDraft(null);
    };

    const handleDiscardFoundDraft = () => {
      discardFoundDraft();
      setAddressedFoundDraft(true);
    };

    const handleUseFoundDraft = () => {
      setFromDraft(foundDraft);
      discardFoundDraft();
      setAddressedFoundDraft(true);
    };

    return (
      <UnfinishedDraftDialog
        open={!addressedFoundDraft}
        draft={foundDraft}
        onDiscard={handleDiscardFoundDraft}
        onContinue={handleUseFoundDraft}
      />
    );
  }

  return (
    <Box pt={hasTitle ? 0 : 2} {...props}>
      {
        cancellable &&
        <Hidden only={['md', 'lg', 'xl']}>
          <Box display="flex" flexDirection="row-reverse">
            {cancelButton}
          </Box>
        </Hidden>
      }
      <Box position="relative">
        {
          title &&
          <Box p={3} pb={0}>
            <Typography variant="h6">
              {title}
            </Typography>
          </Box>
        }
        {
          cancellable &&
          <Hidden smDown>
            <Box position="absolute" top={theme.spacing(0.75)} right={theme.spacing(4)}>
              {cancelButton}
            </Box>
          </Hidden>
        }
      </Box>
      <Box mt={(isSmall && cancellable && !title) ? -3 : 0}>
        <NewPositionSteps companyId={companyId} fromDraft={fromDraft} />
      </Box>
    </Box>
  );
};

export default NewPositionPage;