import React from 'react';
import { Avatar, Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';
import { styled } from '@material-ui/core/styles';

export const IntroLabel = styled(({ ...props }) => {
    const { t } = useTranslation();
    return (
        <Avatar {...props}>
            <Typography variant="caption">
                {t('intro')}
            </Typography>
        </Avatar>
    );
})(props => ({
    color: props.theme.palette.getContrastText(props.theme.palette.secondary.main),
    backgroundColor: props.theme.palette.secondary.main,
}));

export const QuestionLabel = styled(({ label, ...props }) =>
    <Avatar {...props}>{label}</Avatar>
)(props => ({
    color: props.theme.palette.getContrastText(props.theme.palette.secondary.main),
    backgroundColor: props.theme.palette.secondary.main,
}));

export const OutroLabel = styled(({ ...props }) => {
    const { t } = useTranslation();
    return (
        <Avatar {...props}>
            <Typography variant="caption">
                {t('outro')}
            </Typography>
        </Avatar>
    );
})(props => ({
    color: props.theme.palette.getContrastText(props.theme.palette.secondary.main),
    backgroundColor: props.theme.palette.secondary.main,
}));