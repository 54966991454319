import React from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';

const PlanRequiredDialog = ({ open, closeDialog, ...props }) => {
  const { t } = useTranslation();
  return (
    <Dialog open={open} onClose={closeDialog} {...props}>
      <DialogTitle>{t('Plan required')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Trans>
            Please contact your company's account owner to purchase a plan.
          </Trans>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PlanRequiredDialog;