import _ from 'lodash';

const cyrb53 = function (str, seed = 0) {
  // https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
  let h1 = 0xdeadbeef ^ seed, h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
};

const extractCaptionsHref = video => video?.captions?.vttHref || null;

const extractVideoSources = video =>
  !_.isNull(video) && !_.isUndefined(video)
    ? video.variants.map(
      video => ({
        src: video.contentHref,
        type: video.contentType,
      })
    )
    : null;

const extractThumbnailHref = thumbnail => {
  if (_.isNull(thumbnail) || _.isUndefined(thumbnail)) {
    return null;
  }
  // Take priority order from the backend
  return thumbnail.variants[0].contentHref;
};

const hashVideoSources = videoSources => {
  if (!videoSources) {
    return '';
  }
  const parts = _.flatMap(videoSources, videoSource => [videoSource.src, videoSource.type]);
  parts.sort();
  return utils.cyrb53(parts.join(''));
};

const extractHrefsForVideoPlayer = (thumbnail, video) => {
  const thumbnailHref = extractThumbnailHref(thumbnail);
  const videoSources = extractVideoSources(video);
  const videoSourcesSignature = hashVideoSources(videoSources);
  const captionsHref = extractCaptionsHref(video);
  return { thumbnailHref, videoSources, videoSourcesSignature, captionsHref }
};

const extractMediaGroupIdsForInterview = interview => {
  if (Object.keys(interview).length <= 0) {
    return [];
  }
  const items = [interview.intro, ...interview.questions, interview.outro];
  const mediaGroupIds = _.flatMap(
    items,
    item => item ? [item.thumbnailImageGroupId, item.videoGroupId] : []
  ).filter(_.identity);
  return mediaGroupIds;
};

const sortObjectsBy = (objects, property) => {
  const sortedObjects = [...objects];
  sortedObjects.sort((entry, other) => entry[property].localeCompare(other[property]));
  return sortedObjects;
};

const formatOffsetMillis = millis => {
  const secs = Math.floor(millis / 1000);
  const mins = Math.floor(secs / 60);
  const displayMins = mins % 60;
  const displaySecs = secs % 60;
  const parts = [displayMins, displaySecs];
  return _.map(parts, function (part, index) {
    const partStr = part.toString();
    return (partStr.length === 1) ? '0' + partStr : partStr;
  }).join(':');
};

const fallbackCopyTextToClipboard = text => {
  let textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    let successful = document.execCommand('copy');
    if (!successful) {
      throw new Error("Unable to copy to clipboard");
    }
  } finally {
    document.body.removeChild(textArea);
  }
};

const copyTextToClipboard = async text => {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
  } else {
    await navigator.clipboard.writeText(text);
  }
};

const utils = { cyrb53, extractHrefsForVideoPlayer, extractMediaGroupIdsForInterview, sortObjectsBy, formatOffsetMillis, copyTextToClipboard };

export default utils;
