import React, { useCallback, useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { AppBar, Box, CircularProgress, Hidden, IconButton, Link, Menu, MenuItem, Toolbar, Tooltip, Typography, useMediaQuery } from '@material-ui/core';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Settings from '@material-ui/icons/Settings';
import { useTranslation } from 'react-i18next';
import { styled } from '@material-ui/core/styles';
import { useHistory } from 'react-router';

import api from '../API';
import StyledProductName from './StyledProductName';
import CompanySettingsDialog from './CompanySettingsDialog';
import useAnalytics from '../hooks/useAnalytics';

const ProductLogo = styled(({ ...props }) =>
  <Typography variant="h6" component="span" {...props}>
    <StyledProductName />
  </Typography>
)({
  cursor: 'pointer',
  fontSize: '1.7rem',
});

const NavbarIconLabel = ({ children, ...props }) =>
  <Typography variant="subtitle2" {...props}>
    {children}
  </Typography>;

const NavbarIconButton = function ({ children, ...props }) {
  return (
    <IconButton color="inherit" {...props}>
      {children}
    </IconButton>
  );
};

const PlanStatusDescription = ({ companyName, planStatus }) => {
  const { t } = useTranslation();
  switch (planStatus.subscriptionStatus) {
    case 'complimentary':
      return t("{{companyName}} has been granted complimentary access", { companyName })
    case 'subscribed':
      return t("{{companyName}} has a subscription plan with unlimited invites", { companyName })
    case 'not_subscribed':
    default:
      return planStatus.candidatePacksStatus.remaining === 1
        ? t("{{companyName}} has 1 pay-as-you-go credit remaining", { companyName })
        : t("{{companyName}} has {{creditsRemaining}} pay-as-you-go credits remaining", {
          companyName,
          creditsRemaining: planStatus.candidatePacksStatus.remaining
        });
  }
};

const CompanySettings = ({ company, allowedToManage, refreshData }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [planStatus, setPlanStatus] = useState(null);
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const marginRight = isExtraSmall ? 0 : 2;

  const closeDialog = () => {
    setOpen(false);
    refreshData();
  };

  const handleOpenTooltip = async () => {
    // TODO display error when can't load the plan status...
    const newPlanStatus = await api.fetchCompanyPlanStatus(company.companyId);
    setPlanStatus(newPlanStatus);
  };

  const planStatusDescription = !planStatus
    ? t("Loading...")
    : <PlanStatusDescription companyName={company.companyName} planStatus={planStatus} />;

  return (
    <>
      <Box display="flex" alignItems="center" mr={marginRight}>
        <Hidden xsDown>
          <Tooltip title={planStatusDescription} onOpen={handleOpenTooltip} arrow>
            <Box mr={0.5}>
              <NavbarIconLabel style={{ borderBottom: `1px dotted ${theme.palette.primary.contrastText}`, cursor: 'help' }}>
                {company.companyName}
              </NavbarIconLabel>
            </Box>
          </Tooltip>
        </Hidden>
        {
          allowedToManage &&
          <NavbarIconButton onClick={() => setOpen(true)}>
            <Settings />
          </NavbarIconButton>
        }
      </Box>
      <CompanySettingsDialog
        open={open}
        closeDialog={closeDialog}
        companyId={company.companyId}
        companyName={company.companyName}
      />
    </>
  );
};

const AccountIconButton = ({ user, companies }) => {
  const { t } = useTranslation();
  const analytics = useAnalytics();
  const history = useHistory();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const isMenuOpen = Boolean(anchorEl);
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

  const closeMenu = () => setAnchorEl(null);

  const menuItems = [];

  if (isExtraSmall) {
    menuItems.push(
      <MenuItem key="user-email" disabled>
        {user.email}
      </MenuItem>
    );
  }

  menuItems.push(
    <MenuItem key="help-center">
      <Link href={process.env.REACT_APP_CANVASS_SUPPORT_HREF} target="_blank">
        {t('Help center')}
      </Link>
    </MenuItem>
  );

  if (window.$crisp) {
    const handleClickChatWithUs = () => {
      window.$crisp.push(["do", "chat:show"]);
      window.$crisp.push(["do", "chat:open"]);
      closeMenu();
    };

    menuItems.push(
      <MenuItem key="chat-with-us" onClick={handleClickChatWithUs}>{t('Chat with us')}</MenuItem>
    );
  }

  if (companies.length > 1) {
    const handleClickSwitchCompany = () => {
      history.push('/companies');
      closeMenu();
    };

    menuItems.push(
      <MenuItem key="switch-company" onClick={handleClickSwitchCompany}>{t('Switch company')}</MenuItem>
    );
  }

  const handleClickSignout = () => {
    Promise.resolve(api.signout()).then(() => analytics.reset());
    closeMenu();
  };
  menuItems.push(
    <MenuItem key="sign-out" onClick={handleClickSignout}>{t('Sign out')}</MenuItem>
  );

  return (
    <>
      <NavbarIconButton onClick={event => setAnchorEl(event.currentTarget)}>
        <AccountCircle />
      </NavbarIconButton>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMenuOpen}
        onClose={closeMenu}
      >
        {menuItems}
      </Menu>
    </>
  );
};

const UserAccount = ({ user, companies }) => {
  return (
    <>
      <Hidden xsDown>
        <Box mr={0.5}>
          <NavbarIconLabel>{user.email}</NavbarIconLabel>
        </Box>
      </Hidden>
      <AccountIconButton user={user} companies={companies} />
    </>
  );
};

const Navbar = ({ companyId, ...props }) => {
  const history = useHistory();
  const [me, setMe] = useState();
  const [company, setCompany] = useState();
  const [ownershipStatus, setOwnershipStatus] = useState();
  const [loading, setLoading] = useState(true);
  const [isLoadingError, setIsLoadingError] = useState(false);

  const fetchData = useCallback(async () => {
    setIsLoadingError(false);
    setLoading(true);
    try {
      const [newMe, newCompany, ownershipStatus] = await Promise.all([
        api.fetchMe(),
        api.fetchCompany(companyId),
        api.fetchCompanyOwnershipStatus(companyId),
      ]);
      setMe(newMe);
      setCompany(newCompany);
      setOwnershipStatus(ownershipStatus);
      setLoading(false);
    } catch (error) {
      setIsLoadingError(true);
    }
  }, [setMe, setCompany, setLoading, companyId]);

  useEffect(() => fetchData(), [fetchData]);

  if (isLoadingError) {
    return null;
  }

  return (
    <AppBar position="static" {...props}>
      <Toolbar>
        <Box flexGrow={1} key="logo">
          <ProductLogo onClick={() => history.push(`/companies/${companyId}`)} />
        </Box>
        {
          loading &&
          <Box mr={2} key="progress">
            <CircularProgress color="secondary" size="1.5em" />
          </Box>
        }
        {
          !loading &&
          <CompanySettings
            company={company}
            allowedToManage={ownershipStatus.isOwner}
            refreshData={fetchData}
          />
        }
        {!loading && <UserAccount user={me.user} companies={me.companies} />}
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
