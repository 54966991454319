import React from 'react';
import { Avatar, Box } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const CandidateThumbnail = styled(({ title, thumbnailUrl, ...props }) =>
  <Avatar variant="rounded" alt={title} src={thumbnailUrl} {...props} />
)(props => ({
  width: props.theme.spacing(12),
  height: props.theme.spacing(12),
}));

const CandidateAvatar = ({ candidateName, thumbnailUrl, ...props }) => {
  return (
    <Box {...props}>
      <CandidateThumbnail
        title={candidateName}
        thumbnailUrl={thumbnailUrl}
      />
    </Box>
  );
};

export default CandidateAvatar;