import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

const FetchedContent = ({ loading, children, ...props }) => {
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" mt={10} mb={10}>
        <CircularProgress />
      </Box>
    );
  }
  return (
    <Box {...props}>
      {children}
    </Box>
  );
};

export default FetchedContent;