import React, { useRef, useEffect, useCallback } from "react";
import { useTranslation } from 'react-i18next';
import videojs from "video.js";
import "video.js/dist/video-js.css";

const VideoPlayer = ({ options, captionsHref, onReady, videoTagClassNames }) => {
  const { t } = useTranslation();
  const videoRef = useRef(null);
  const playerRef = useRef(null);

  const updateCaptionsHref = useCallback((player, captionsHref) => {
    var tracks = player.remoteTextTracks();

    for (var n = 0, len = tracks.length; n < len; n++) {
      player.removeRemoteTextTrack(tracks[n]);
    }

    if (captionsHref) {
      player.addRemoteTextTrack({
        'kind': 'captions',
        'src': captionsHref,
        'label': t('English'), // TODO address with i18n
        'default': false,
        'srclang': 'en', // TODO address with i18n
      }, false);
    }
  }, [t]);

  useEffect(() => {
    // make sure Video.js player is only initialized once
    if (!playerRef.current) {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const player = playerRef.current = videojs(
        videoElement,
        options,
        () => {
          updateCaptionsHref(player, captionsHref);
          if (onReady) {
            onReady(player);
          }
        },
      );
    } else {
      // you can update player here [update player through props]
      const player = playerRef.current;
      player.src(options.sources);
      player.poster(options.poster);
      updateCaptionsHref(player, captionsHref);
    }
  }, [options, captionsHref, onReady, updateCaptionsHref]);

  // Dispose the Video.js player when the functional component unmounts
  useEffect(() => {
    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, []);

  if (!videoTagClassNames) {
    videoTagClassNames = [];
  }
  videoTagClassNames.push('video-js', 'vjs-16-9');
  const videoClassName = videoTagClassNames.join(' ');

  return (
    <div data-vjs-player>
      <video ref={videoRef} className={videoClassName} playsInline />
    </div>
  );
}

export default VideoPlayer;