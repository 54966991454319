import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import { styled } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';

import ProgressButton from '../ProgressButton'
import AuthPage from '../AuthPage';
import api from '../../API';
import useQuery from '../../hooks/useQuery';
import useAnalytics from '../../hooks/useAnalytics';

const MagicCodeTextField = styled(({ ...props }) => {
  return (
    <TextField
      variant="outlined"
      margin="normal"
      fullWidth
      autoFocus
      inputProps={{ maxLength: 6 }}
      spellCheck={false}
      {...props}
    />
  );
}
)({
  '& .MuiInputBase-input': {
    fontSize: '2em',
    textAlign: 'center',
    letterSpacing: '0.5em',
  }
});

const MagicCodeForm = styled(({ email, returnToPath, ...props }) => {
  const { t } = useTranslation();
  const analytics = useAnalytics();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [magicCode, setMagicCode] = useState('');
  const [error, setError] = useState(null);

  const signinWithMagicCode = async tryMagicCode => {
    setLoading(true);
    try {
      const userId = await api.signin(email, tryMagicCode);
      analytics.setUser(userId, email);
      setLoading(false);
      if (returnToPath) {
        history.push(returnToPath);
      } else {
        history.push('/companies');
      }
    } catch (e) {
      setLoading(false);
      const message = e?.userMessage?.() || t('Unable to sign in at this time. Please contact us.');
      setError(message);
    }
  };

  const handleMagicCodeChange = async event => {
    const newMagicCode = event.target.value.toUpperCase();
    setError(null);
    setMagicCode(newMagicCode);

    if (newMagicCode.length >= 6) {
      await signinWithMagicCode(newMagicCode);
    }
  };

  const canContinue = !!magicCode;

  const handleContinue = async event => {
    event?.preventDefault();

    if (!canContinue) {
      return;
    }

    await signinWithMagicCode(magicCode);
  };

  return (
    <form noValidate {...props} onSubmit={handleContinue}>
      {error && <Alert severity="error">{error}</Alert>}
      <MagicCodeTextField
        disabled={loading}
        value={magicCode}
        onChange={handleMagicCodeChange}
      />
      <ProgressButton
        label={t('Continue')}
        subLabel={t("Can't find the code? Check your spam folder!")}
        progress={loading}
        disabled={!canContinue}
        onClick={handleContinue}
      />
    </form>
  );
}
)(props => ({
  marginTop: props.theme.spacing(2),
}));

export default function MagicCodePage() {
  const { t } = useTranslation();
  const query = useQuery();
  const email = query.get('email');
  const returnToPath = query.get('returnToPath');
  return (
    <AuthPage
      title={t('Check your email for a code')}
      subtitle={t("We've sent a 6-character code to {{email}}. The code expires shortly, so please enter it soon.", { email })}
    >
      <MagicCodeForm email={email} returnToPath={returnToPath} />
    </AuthPage>
  );
}