import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import React, { useMemo, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { Box, Button, Card, CardActionArea, CardContent, CircularProgress, Grid, IconButton, TextField, Typography, useMediaQuery } from '@material-ui/core';

import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ShortTextIcon from '@material-ui/icons/ShortText';
import VideoCallIcon from '@material-ui/icons/VideoCall';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { useTranslation } from 'react-i18next';
import { styled } from '@material-ui/core/styles';

import { IntroLabel, QuestionLabel, OutroLabel } from './ContentLabels';
import ContentLibraryDialog from './ContentLibraryDialog';
import RecordVideoDialog from './RecordVideoDialog';
import VideoPlayer from './VideoPlayer';
import utils from '../utils';

const arraymove = (arr, fromIndex, toIndex) => {
  var element = arr[fromIndex];
  arr.splice(fromIndex, 1);
  arr.splice(toIndex, 0, element);
};

const EditVideoButton = styled(({ ...props }) =>
  <IconButton disableFocusRipple disableRipple {...props}>
    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <EditIcon />
    </Box>
  </IconButton>
)({
  backgroundColor: 'rgba(43, 51, 63, 0.7)',
  borderRadius: '1.63332em',
  border: '0.06666em solid #fff',
  '&:hover': {
    backgroundColor: `rgba(115, 133, 159, 0.5)`,
  },
  '& *': {
    color: 'white',
  }
});

const EditableThumbnailVideo = ({ thumbnail, video, onEdit, ...props }) => {
  const { t } = useTranslation();
  const { thumbnailHref, videoSources, videoSourcesSignature, captionsHref } = utils.extractHrefsForVideoPlayer(thumbnail, video);
  const theme = useTheme();
  const videoPlayer = useMemo(() => {
    if (!thumbnailHref && !videoSources) {
      return null;
    }
    return (
      <VideoPlayer
        options={{
          controls: true,
          fluid: true,
          poster: thumbnailHref,
          sources: videoSources,
          controlBar: {
            volumePanel: false,
            pictureInPictureToggle: false,
          }
        }}
        captionsHref={captionsHref}
      />
    );
  }, [thumbnailHref, videoSourcesSignature, captionsHref]);
  const isVideoProcessing = !!video && video.uploadStatus === 'processing';
  const isVideoCaptioning = !!video && video.captions?.captionsStatus === 'preparing';
  const isBlankSpinner = !thumbnail && (!video || isVideoProcessing || isVideoCaptioning);
  const isThumbnailSpinner = !!thumbnail && (!video || isVideoProcessing || isVideoCaptioning);
  const isVideoReady = !!video && !isVideoProcessing && !isVideoCaptioning;
  return (
    <Box position="relative"  {...props}>
      {
        isBlankSpinner &&
        <Box display="flex" alignItems="center" justifyContent="center" p={8}>
          <CircularProgress />
        </Box>
      }
      {
        isThumbnailSpinner &&
        <Box position="relative" fontSize={0} minHeight="168px">
          <img src={thumbnailHref} width="100%" alt={t('Video thumbnail')} />
          <Box position="absolute" top={0} right={0} bottom={0} left={0}>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{ height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
              <CircularProgress size="1.5rem" />
              <Box mt={1}>
                <Typography variant="subtitle2" color="primary">
                  {!isVideoProcessing && isVideoCaptioning ? t('Captioning...') : t('Processing...')}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      }
      {
        isVideoReady &&
        <>
          {videoPlayer}
          <Box position="absolute" top={theme.spacing(1)} right={theme.spacing(1)}>
            <EditVideoButton onClick={onEdit} />
          </Box>
        </>
      }
    </Box>
  );
};

const AddContentTile = ({ title, icon, onClick, ...props }) =>
  <Box {...props}>
    <Card>
      <CardActionArea onClick={onClick}>
        <CardContent>
          <Box display="flex" flexDirection="column" alignItems="center">
            {icon}
            <Typography variant="overline">
              {title}
            </Typography>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  </Box>;

const useIconSize = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  return isSmall ? 'small' : 'medium';
};

const DeleteTileButton = ({ onClick, ...props }) => {
  const iconSize = useIconSize();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const margins = isSmall ? 3 : 1;
  return (
    <Box mt={margins} mb={margins} display="flex" flexDirection="column" alignItems="center" {...props}>
      <IconButton onClick={onClick} size={iconSize}>
        <DeleteIcon />
      </IconButton>
    </Box>
  );
};

const IntroTile = ({ intro, thumbnail, video, updateIntro, deleteIntro, ...props }) => {
  const { t } = useTranslation();
  const [isUpdatingIntro, setIsUpdatingIntro] = useState(false);
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up('md'));

  const handleIntroVideoUpdated = ({ thumbnailImageGroupId, videoGroupId }) => {
    updateIntro({ ...intro, thumbnailImageGroupId, videoGroupId });
    setIsUpdatingIntro(false);
  };

  return (
    <>
      <Box width={isMedium ? "50%" : "100%"} {...props}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={2} md={2}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <IntroLabel />
                  <DeleteTileButton onClick={deleteIntro} />
                </Box>
              </Grid>
              <Grid item xs={10} md={10}>
                <EditableThumbnailVideo
                  thumbnail={thumbnail}
                  video={video}
                  onEdit={() => setIsUpdatingIntro(true)}
                />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      {
        isUpdatingIntro &&
        <RecordVideoDialog
          title={t('Update intro video')}
          open={isUpdatingIntro}
          onClose={() => setIsUpdatingIntro(false)}
          onUploaded={handleIntroVideoUpdated}
        />
      }
    </>
  );
};

const QuestionTile = ({ index, question, thumbnail, video, updateQuestion, canMoveUp, moveQuestionUp, canMoveDown, moveQuestionDown, deleteQuestion, ...props }) => {
  const { t } = useTranslation();
  const iconSize = useIconSize();
  const [isUpdatingQuestion, setIsUpdatingQuestion] = useState(false);
  const [title, setTitle] = useState(question.title);
  const [content, setContent] = useState(question.content);
  const number = index + 1;
  const isVideoQuestion = !!question.videoGroupId;

  const debouncedUpdateQuestion = useMemo(() =>
    _.debounce(updateQuestion, 250),
    [updateQuestion],
  );

  const handleTitleChange = (event) => {
    const newTitle = event.target.value;
    setTitle(newTitle);
    debouncedUpdateQuestion(index, {
      ...question,
      title: newTitle,
    });
  };

  const handleContentChange = (event) => {
    const newContent = event.target.value;
    setContent(newContent);
    debouncedUpdateQuestion(index, {
      ...question,
      content: newContent,
    });
  };

  const handleQuestionVideoUpdated = ({ thumbnailImageGroupId, videoGroupId }) => {
    updateQuestion(index, { ...question, thumbnailImageGroupId, videoGroupId });
    setIsUpdatingQuestion(false);
  };

  return (
    <>
      <Box {...props}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={2} md={1}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <QuestionLabel label={'Q' + number} />
                  <DeleteTileButton onClick={() => deleteQuestion(index)} />
                  <IconButton onClick={() => moveQuestionUp(index)} disabled={!canMoveUp} size={iconSize}>
                    <ArrowUpwardIcon />
                  </IconButton>
                  <IconButton onClick={() => moveQuestionDown(index)} disabled={!canMoveDown} size={iconSize}>
                    <ArrowDownwardIcon />
                  </IconButton>
                </Box>
              </Grid>
              {
                isVideoQuestion &&
                <Grid item xs={10} md={5}>
                  <EditableThumbnailVideo
                    thumbnail={thumbnail}
                    video={video}
                    onEdit={() => setIsUpdatingQuestion(true)}
                  />
                </Grid>
              }
              <Grid item xs={isVideoQuestion ? 12 : 10} md={isVideoQuestion ? 6 : 11}>
                <Box>
                  <TextField
                    fullWidth
                    autoFocus
                    variant="outlined"
                    label={t('Question')}
                    helperText={isVideoQuestion ? t('Text version to appear beside the video (optional)') : null}
                    value={title}
                    inputProps={{ maxLength: 150 }}
                    onChange={handleTitleChange}
                  />
                </Box>
                <Box mt={2}>
                  <TextField
                    fullWidth
                    multiline
                    variant="outlined"
                    label={t('Additional details')}
                    helperText={t('Additional details to appear under the Question (optional)')}
                    rows={3}
                    value={content}
                    inputProps={{ maxLength: 300 }}
                    onChange={handleContentChange}
                  />
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      {
        isVideoQuestion && isUpdatingQuestion &&
        <RecordVideoDialog
          title={t('Update video question')}
          open={isUpdatingQuestion}
          onClose={() => setIsUpdatingQuestion(false)}
          onUploaded={handleQuestionVideoUpdated}
        />
      }
    </>
  );
};

const OutroTile = ({ outro, thumbnail, video, updateOutro, deleteOutro, ...props }) => {
  const { t } = useTranslation();
  const [isUpdatingOutro, setIsUpdatingOutro] = useState(false);
  const [title, setTitle] = useState(outro.title);
  const [content, setContent] = useState(outro.content);

  const debouncedUpdateOutro = useMemo(() =>
    _.debounce(updateOutro, 250),
    [updateOutro],
  );

  const handleTitleChange = (event) => {
    const newTitle = event.target.value;
    setTitle(newTitle);
    debouncedUpdateOutro({
      ...outro,
      title: newTitle,
    });
  };

  const handleContentChange = (event) => {
    const newContent = event.target.value;
    setContent(newContent);
    debouncedUpdateOutro({
      ...outro,
      content: newContent,
    });
  };

  const handleOutroVideoUpdated = ({ thumbnailImageGroupId, videoGroupId }) => {
    updateOutro({ ...outro, thumbnailImageGroupId, videoGroupId });
    setIsUpdatingOutro(false);
  };

  return (
    <>
      <Box {...props}>
        <Card>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={2} md={1}>
                <Box display="flex" flexDirection="column" alignItems="center">
                  <OutroLabel />
                  <DeleteTileButton onClick={deleteOutro} />
                </Box>
              </Grid>
              <Grid item xs={10} md={5}>
                <EditableThumbnailVideo
                  thumbnail={thumbnail}
                  video={video}
                  onEdit={() => setIsUpdatingOutro(true)}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Box>
                  <TextField
                    fullWidth
                    variant="outlined"
                    label={t('Title')}
                    helperText={t('Title to appear beside the video (optional)')}
                    placeholder={t('🎉 Thank you! (default)')}
                    value={title}
                    inputProps={{ maxLength: 50 }}
                    onChange={handleTitleChange}
                  />
                </Box>
                <Box mt={2}>
                  <TextField
                    multiline
                    fullWidth
                    variant="outlined"
                    label={t('Message')}
                    helperText={t('Message to appear beside the video (optional)')}
                    placeholder={t("We'll review and be in touch shortly. (default)")}
                    rows={3}
                    value={content}
                    inputProps={{ maxLength: 250 }}
                    onChange={handleContentChange}
                  />
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
      {
        isUpdatingOutro &&
        <RecordVideoDialog
          title={t('Update outro video')}
          open={isUpdatingOutro}
          onClose={() => setIsUpdatingOutro(false)}
          onUploaded={handleOutroVideoUpdated}
        />
      }
    </>
  );
};

const newVideoQuestion = (thumbnailImageGroupId, videoGroupId) => ({
  ephemeralId: uuidv4(),
  title: '',
  content: '',
  thumbnailImageGroupId,
  videoGroupId,
});

const newTextQuestion = () => newVideoQuestion(null, null);

const assignEphemeralIds = interviewQuestions => {
  if (interviewQuestions.intro && !interviewQuestions.intro.ephemeralId) {
    interviewQuestions.intro.ephemeralId = uuidv4();
  }

  interviewQuestions.questions.forEach(question => {
    if (!question.ephemeralId) {
      question.ephemeralId = uuidv4();
    }
  });

  if (interviewQuestions.outro && !interviewQuestions.outro.ephemeralId) {
    interviewQuestions.outro.ephemeralId = uuidv4();
  }
};

const InterviewQuestionsBuilder = ({ companyId, positionId, interviewQuestions, setInterviewQuestions, mediaGroups, ...props }) => {
  const { t } = useTranslation();
  const [isAddingIntro, setIsAddingIntro] = useState(false);
  const [isAddingQuestion, setIsAddingQuestion] = useState(false);
  const [isAddingOutro, setIsAddingOutro] = useState(false);
  const [isAddingExistingContent, setIsAddingExistingContent] = useState(false);

  assignEphemeralIds(interviewQuestions);

  const handleIntroVideoAdded = ({ thumbnailImageGroupId, videoGroupId }) => {
    updateIntro({ thumbnailImageGroupId, videoGroupId });
    setIsAddingIntro(false);
  };

  const updateIntro = (intro) => {
    setInterviewQuestions(interviewQuestions => ({
      ...interviewQuestions,
      intro,
    }))
  };

  const deleteIntro = () => {
    setInterviewQuestions(interviewQuestions => {
      const updatedInterviewQuestions = { ...interviewQuestions };
      delete updatedInterviewQuestions.intro;
      return updatedInterviewQuestions;
    });
  };

  const moveQuestionUp = (index) => {
    setInterviewQuestions(interviewQuestions => {
      const questions = [...interviewQuestions.questions];
      arraymove(questions, index, index - 1);
      return { ...interviewQuestions, questions };
    });
  };

  const moveQuestionDown = (index) => {
    setInterviewQuestions(interviewQuestions => {
      const questions = [...interviewQuestions.questions];
      arraymove(questions, index, index + 1);
      return { ...interviewQuestions, questions };
    });
  };

  const updateQuestion = (index, question) => {
    setInterviewQuestions(interviewQuestions => {
      const questions = [...interviewQuestions.questions];
      questions[index] = question;
      return { ...interviewQuestions, questions };
    });
  };

  const deleteQuestion = (index) => {
    setInterviewQuestions(interviewQuestions => {
      const questions = [...interviewQuestions.questions];
      questions.splice(index, 1);
      return { ...interviewQuestions, questions };
    });
  };

  const addQuestion = question => {
    setInterviewQuestions(interviewQuestions => ({
      ...interviewQuestions,
      questions: [
        ...interviewQuestions.questions,
        question,
      ]
    }));
  };

  const handleQuestionVideoAdded = ({ thumbnailImageGroupId, videoGroupId }) => {
    addQuestion(newVideoQuestion(thumbnailImageGroupId, videoGroupId));
    setIsAddingQuestion(false);
  };

  const updateOutro = (outro) => {
    setInterviewQuestions(interviewQuestions => ({
      ...interviewQuestions, outro
    }))
  };

  const deleteOutro = () => {
    setInterviewQuestions(interviewQuestions => {
      const updatedInterviewQuestions = { ...interviewQuestions };
      delete updatedInterviewQuestions.outro;
      return updatedInterviewQuestions;
    });
  };

  const handleOutroVideoAdded = ({ thumbnailImageGroupId, videoGroupId }) => {
    updateOutro({ title: '', content: '', thumbnailImageGroupId, videoGroupId });
    setIsAddingOutro(false);
  };

  const handleClickAddTextQuestion = () => {
    addQuestion(newTextQuestion());
  };

  const handleAddExistingContent = contentItems => {
    contentItems.forEach(contentItem => {
      const content = _.omit(contentItem, 'contentType');
      switch (contentItem.contentType) {
        case 'intro':
          updateIntro(content);
          break;
        case 'question':
          addQuestion(content);
          break;
        case 'outro':
          updateOutro(content);
          break;
        default:
          throw new Error("Unknown content type");
      }
    });
    setIsAddingExistingContent(false);
  };

  return (
    <>
      <Box {...props}>
        {
          interviewQuestions.intro
            ? <IntroTile
              intro={interviewQuestions.intro}
              thumbnail={mediaGroups[interviewQuestions.intro.thumbnailImageGroupId]}
              video={mediaGroups[interviewQuestions.intro.videoGroupId]}
              updateIntro={updateIntro}
              deleteIntro={deleteIntro}
            />
            : (
              <AddContentTile
                key="add-intro"
                title={t('Add intro video')}
                icon={<VideoCallIcon fontSize="large" />}
                onClick={() => setIsAddingIntro(true)}
              />
            )
        }

        {interviewQuestions.questions.map((question, index) =>
          <QuestionTile
            mt={1}
            key={`question-${question.ephemeralId}`}
            index={index}
            question={question}
            thumbnail={question.thumbnailImageGroupId ? mediaGroups[question.thumbnailImageGroupId] : null}
            video={question.videoGroupId ? mediaGroups[question.videoGroupId] : null}
            updateQuestion={updateQuestion}
            canMoveUp={index > 0}
            moveQuestionUp={moveQuestionUp}
            canMoveDown={index < interviewQuestions.questions.length - 1}
            moveQuestionDown={moveQuestionDown}
            deleteQuestion={deleteQuestion}
          />
        )}
        <AddContentTile
          key="add-video-question"
          mt={1}
          title={t('Add video question')}
          icon={<VideoCallIcon fontSize="large" />}
          onClick={() => setIsAddingQuestion(true)}
        />
        <AddContentTile
          key="add-text-question"
          mt={1}
          title={t('Add text question')}
          icon={<ShortTextIcon fontSize="large" />}
          onClick={handleClickAddTextQuestion}
        />

        {
          interviewQuestions.outro
            ? <OutroTile
              mt={1}
              outro={interviewQuestions.outro}
              thumbnail={mediaGroups[interviewQuestions.outro.thumbnailImageGroupId]}
              video={mediaGroups[interviewQuestions.outro.videoGroupId]}
              updateOutro={updateOutro}
              deleteOutro={deleteOutro}
            />
            : (
              <AddContentTile
                key="add-outro"
                mt={1}
                title={t('Add outro video')}
                icon={<VideoCallIcon fontSize="large" />}
                onClick={() => setIsAddingOutro(true)}
              />
            )
        }

        <Box p={2} textAlign="center">
          <Button color="primary" startIcon={<AddIcon />} onClick={() => setIsAddingExistingContent(true)}>
            {t('Add existing content')}
          </Button>
        </Box>
      </Box>
      {
        isAddingIntro &&
        <RecordVideoDialog
          title={t('Add intro video')}
          open={isAddingIntro}
          onClose={() => setIsAddingIntro(false)}
          onUploaded={handleIntroVideoAdded}
        />
      }
      {
        isAddingQuestion &&
        <RecordVideoDialog
          title={t('Add video question')}
          open={isAddingQuestion}
          onClose={() => setIsAddingQuestion(false)}
          onUploaded={handleQuestionVideoAdded}
        />
      }
      {
        isAddingOutro &&
        <RecordVideoDialog
          title={t('Add outro video')}
          open={isAddingOutro}
          onClose={() => setIsAddingOutro(false)}
          onUploaded={handleOutroVideoAdded}
        />
      }
      {
        isAddingExistingContent &&
        <ContentLibraryDialog
          companyId={companyId}
          positionId={positionId}
          open={isAddingExistingContent}
          onClose={() => setIsAddingExistingContent(false)}
          onAddContent={handleAddExistingContent}
        />
      }
    </>
  );
};

export default InterviewQuestionsBuilder;