import _ from 'lodash';
import { useEffect, useState } from 'react';
import api from '../API';
import utils from '../utils';

const isAnyProcessing = mediaGroups =>
  _.some(
    Object.values(mediaGroups),
    mediaGroup => mediaGroup.uploadStatus === 'processing',
  );

const isAnyPreparingCaptions = mediaGroups =>
  _.some(
    Object.values(mediaGroups),
    mediaGroup => mediaGroup.captions?.captionsStatus === 'preparing',
  );

const shouldKeepPolling = mediaGroups => isAnyProcessing(mediaGroups) || isAnyPreparingCaptions(mediaGroups);

const hashMediaGroupIds = mediaGroupIds => {
  const copyMediaGroupIds = [...mediaGroupIds];
  copyMediaGroupIds.sort();
  return utils.cyrb53(copyMediaGroupIds.join(''));
};

const PROCESSING_REFRESH_MILLIS = 5000;

const useMediaGroups = mediaGroupIds => {
  const [mediaGroups, setMediaGroups] = useState({});

  const mediaGroupIdsSignature = hashMediaGroupIds(mediaGroupIds);

  useEffect(() => {
    let intervalId;

    const fetchMediaGroups = async () => {
      if (mediaGroupIds.length > 0) {
        // Attempt to fetch upfront
        const mediaGroups = await api.fetchMediaGroups(mediaGroupIds);
        setMediaGroups(mediaGroups);

        // Fetch in background while any are processing
        if (shouldKeepPolling(mediaGroups)) {
          intervalId = setInterval(async () => {
            const mediaGroups = await api.fetchMediaGroups(mediaGroupIds);
            setMediaGroups(mediaGroups);
            if (!shouldKeepPolling(mediaGroups)) {
              clearInterval(intervalId);
            }
          }, PROCESSING_REFRESH_MILLIS);
        }
      }
    };

    fetchMediaGroups();

    return () => clearInterval(intervalId);
  }, [mediaGroupIdsSignature, setMediaGroups]);

  const isProcessing = isAnyProcessing(mediaGroups);

  return { mediaGroups, isProcessing };
};

export default useMediaGroups;