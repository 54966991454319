import React from 'react';
import { AppBar, Box, Button, Toolbar, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import StyledProductName from './StyledProductName';
import api from '../API';

const ProductLogo = styled(({ ...props }) =>
  <Typography variant="h6" component="span" {...props}>
    <StyledProductName />
  </Typography>
)({
  cursor: 'default',
  fontSize: '1.7rem',
});

const PublicNavbar = ({ ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const isAuthenticated = api.hasSession();
  const handleClickSignin = () =>
    history.push(`/signin?returnToPath=${encodeURIComponent(window.location.hash.slice(1))}`);

  return (
    <AppBar position="static" {...props}>
      <Toolbar>
        <Box flexGrow={1} key="logo">
          <ProductLogo />
        </Box>
        {
          !isAuthenticated &&
          <Box>
            <Button color="inherit" onClick={handleClickSignin}>
              {t('Sign in')}
            </Button>
          </Box>
        }
      </Toolbar>
    </AppBar>
  );
}

export default PublicNavbar;
