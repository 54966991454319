import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Breadcrumbs, Button, CircularProgress, FormControl, FormHelperText, Grid, Hidden, Link, MenuItem, Paper, Select, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useHistory, useParams } from "react-router";

import api from '../../API';
import AspectRatio from '../AspectRatio';
import FetchedContent from '../FetchedContent';
import VideoPlayer from '../VideoPlayer';
import utils from '../../utils';

const AnswerVideo = ({ thumbnail, video, onReady }) => {
  const { t } = useTranslation();
  const { thumbnailHref, videoSources, videoSourcesSignature, captionsHref } = utils.extractHrefsForVideoPlayer(thumbnail, video);
  const videoPlayer = useMemo(() => {
    if (!thumbnailHref && !videoSources) {
      return null;
    }
    return (
      <VideoPlayer
        options={{
          controls: true,
          fluid: true,
          poster: thumbnailHref,
          sources: videoSources,
          controlBar: {
            volumePanel: false,
            pictureInPictureToggle: false,
          },
          playbackRates: [1, 1.5, 2],
        }}
        captionsHref={captionsHref}
        videoTagClassNames={['vjs-big-play-centered']}
        onReady={onReady}
      />
    );
  }, [thumbnailHref, videoSourcesSignature, captionsHref, onReady]);
  const isVideoProcessing = video.uploadStatus === 'processing';
  const isVideoCaptioning = video.captions?.captionsStatus === 'preparing';
  if (isVideoProcessing || isVideoCaptioning) {
    return (
      <AspectRatio horizontal={16} vertical={9}>
        <img src={thumbnailHref} height="100%" alt={t('Video thumbnail')} />
        <Box position="absolute" top={0} right={0} bottom={0} left={0}>
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{ height: '100%', backgroundColor: 'rgba(255, 255, 255, 0.5)' }}>
            <CircularProgress size="1.5rem" />
            <Box mt={1}>
              <Typography variant="subtitle2" color="primary">
                {!isVideoProcessing && isVideoCaptioning ? t('Captioning...') : t('Processing...')}
              </Typography>
            </Box>
          </Box>
        </Box>
      </AspectRatio>
    );
  }
  return videoPlayer;
};

const VIDEO_PROCESSING_REFRESH_MILLIS = 5000;
const TRANSCRIPT_PROCESSING_REFRESH_MILLIS = 5000;

const PublicCandidateInterview = ({ publicCandidateId, company, position, candidate, setCandidateDosette, answerTranscripts, setAnswerTranscripts }) => {
  const { t } = useTranslation();
  const [answerIndex, setAnswerIndex] = useState(0);
  const [player, setPlayer] = useState(null);

  const question = candidate.questions[answerIndex];
  const answer = candidate.answers[answerIndex];
  const hasNextAnswer = answerIndex < candidate.answers.length - 1;
  const thumbnail = answer.thumbnail;
  const video = answer.video;
  const isVideoProcessing = video.uploadStatus === 'processing';
  const isCaptionsPreparing = video.captions?.captionsStatus === 'preparing';
  const shouldKeepRefreshingCandidateData = isVideoProcessing || isCaptionsPreparing;
  const answerTranscript = answerTranscripts[answer.answerId];
  const hasTranscript = !!answerTranscript;
  const isTranscriptProcessing = hasTranscript && ['created', 'submitted'].includes(answerTranscript.transcriptStatus);

  const refreshCandidateData = useCallback(async () => {
    try {
      const candidateDosette = await api.fetchPublicCandidate(publicCandidateId);
      setCandidateDosette(candidateDosette);
    } catch (error) {
      /* Don't need to track as error recorded on backend */
      console.log(error);
    }
  }, [publicCandidateId, setCandidateDosette]);

  useEffect(() => {
    let intervalId;
    if (shouldKeepRefreshingCandidateData) {
      intervalId = setInterval(refreshCandidateData, VIDEO_PROCESSING_REFRESH_MILLIS);
    }
    return () => clearInterval(intervalId);
  }, [shouldKeepRefreshingCandidateData, refreshCandidateData]);

  const refreshAnswerTranscriptsData = useCallback(async () => {
    try {
      const answerTranscripts = await api.fetchPublicCandidateAnswerTranscripts(publicCandidateId);
      setAnswerTranscripts(answerTranscripts);
    } catch (error) {
      /* Don't need to track as error recorded on backend */
      console.log(error);
    }
  }, [publicCandidateId, setAnswerTranscripts]);

  useEffect(() => {
    let intervalId;
    if (isTranscriptProcessing) {
      intervalId = setInterval(refreshAnswerTranscriptsData, TRANSCRIPT_PROCESSING_REFRESH_MILLIS);
    }
    return () => clearInterval(intervalId);
  }, [isTranscriptProcessing, refreshAnswerTranscriptsData]);

  const goNextAnswer = () =>
    setAnswerIndex(answerIndex => Math.min(answerIndex + 1, candidate.answers.length - 1));

  const answerVideo = useMemo(() =>
    <AnswerVideo thumbnail={thumbnail} video={video} onReady={setPlayer} />,
    [thumbnail, video, setPlayer]
  );

  const jumpToMillis = millis => {
    if (player) {
      player.play();
      player.currentTime(Math.floor(millis / 1000));
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center" mt={3.5} mb={3.5}>
        <Breadcrumbs>
          <Typography color="textSecondary">
            {company.companyName}
          </Typography>
          <Typography color="textSecondary">
            {position.jobTitle}
          </Typography>
          <Typography color="textSecondary" style={{ fontWeight: 'bold' }}>
            {candidate.candidateName}
          </Typography>
        </Breadcrumbs>
      </Box>
      <Grid container>
        <Grid item xs={12} md={9}>
          <Paper>
            <Box p={3}>
              <FormControl fullWidth>
                <FormHelperText>
                  {t('Answer {{num}} of {{total}}', { num: answerIndex + 1, total: candidate.answers.length })}
                </FormHelperText>
                <Select value={answerIndex} onChange={event => setAnswerIndex(event.target.value)}>
                  {candidate.questions.map((question, answerIndex) =>
                    <MenuItem key={answerIndex} value={answerIndex}>
                      {question.title || t('Question {{num}}', { num: answerIndex + 1 })}
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
            </Box>
          </Paper>
        </Grid>
        <Hidden smDown>
          <Grid item md={3}>
            <Box height="100%" display="flex" alignItems="center" justifyItems="center" alignContent="center" justifyContent="center">
              <Button color="primary" size="large" endIcon={<ArrowForwardIcon />} disabled={!hasNextAnswer} onClick={goNextAnswer}>
                {t('Next answer')}
              </Button>
            </Box>
          </Grid>
        </Hidden>
      </Grid>
      <Box mt={2}>
        <Paper style={{ overflow: 'hidden' }}>
          {
            question.content &&
            <Box p={3}>
              <Typography key="content" variant="body2" color="textSecondary" component="p">
                {question.content}
              </Typography>
            </Box>
          }
          {answerVideo}
          {
            hasTranscript && !isVideoProcessing &&
            <>
              {
                isTranscriptProcessing &&
                <Box p={3} display="flex" alignItems="center">
                  <CircularProgress size="1.5rem" />
                  <Box ml={2}>
                    <Typography variant="subtitle1">
                      {t('Transcribing answer...')}
                    </Typography>
                  </Box>
                </Box>
              }
              {
                !isTranscriptProcessing &&
                <Box p={3}>
                  <Typography key="title" variant="subtitle2" gutterBottom>
                    {t('Transcript')}
                  </Typography>
                  {
                    (!answerTranscript.paragraphs || answerTranscript.paragraphs.length <= 0) &&
                    <Box fontStyle="italic">
                      <Typography key="empty-transcript" variant="body2" color="textSecondary" component="p">
                        {t("No spoken words were detected")}
                      </Typography>
                    </Box>
                  }
                  {
                    answerTranscript.paragraphs &&
                    answerTranscript.paragraphs.map((paragraph, index) =>
                      <Typography key={`paragraph-${index}`} variant="body2" color="textSecondary" component="p" gutterBottom>
                        <Link onClick={() => jumpToMillis(paragraph.startMillis)} style={{ cursor: 'pointer' }}>[{utils.formatOffsetMillis(paragraph.startMillis)}]</Link>&nbsp;{paragraph.content}
                      </Typography>
                    )
                  }
                </Box>
              }
            </>
          }
        </Paper>
      </Box>
    </>
  );
};

const PublicCandidatePage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { publicCandidateId } = useParams();
  const [candidateDosette, setCandidateDosette] = useState(null);
  const [answerTranscripts, setAnswerTranscripts] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      const [candidateDosette, answerTranscripts] = await Promise.all([
        api.fetchPublicCandidate(publicCandidateId),
        api.fetchPublicCandidateAnswerTranscripts(publicCandidateId),
      ]);

      if (api.hasSession()) {
        // Check if authenticated session has access to the candidate
        const candidateId = candidateDosette.candidate.candidateId;
        const candidateAccessStatus = await api.fetchCandidateAccessStatus(candidateId);

        if (candidateAccessStatus.canAccess) {
          // Authenticated and allowed to access the candidate so redirect them
          // to the full candidate page...
          const companyId = candidateDosette.company.companyId;
          const positionId = candidateDosette.position.positionId;
          history.push(`/companies/${companyId}/positions/${positionId}/candidates/${candidateId}`);
        } else {
          // Authenticated but without access to this candidate, so remain on the public page
          setCandidateDosette(candidateDosette);
          setAnswerTranscripts(answerTranscripts);
        }
      } else {
        // Not authenticated
        setCandidateDosette(candidateDosette);
        setAnswerTranscripts(answerTranscripts);
      }
    } catch (e) {
      const message = e?.userMessage?.() || t('Unable to retrieve data at this time. Please contact us.');
      setError(message);
    }
    setLoading(false);
  }, [publicCandidateId, setCandidateDosette, setAnswerTranscripts, setError, t, setLoading, history]);

  useEffect(() => fetchData(), [fetchData]);

  const didDataLoad = !loading && candidateDosette !== null && answerTranscripts !== null;

  return (
    <FetchedContent loading={loading} mt={2}>
      {error && <Alert severity="error">{error}</Alert>}
      {
        didDataLoad &&
        <PublicCandidateInterview
          publicCandidateId={publicCandidateId}
          company={candidateDosette.company}
          position={candidateDosette.position}
          candidate={candidateDosette.candidate}
          setCandidateDosette={setCandidateDosette}
          answerTranscripts={answerTranscripts}
          setAnswerTranscripts={setAnswerTranscripts}
        />
      }
    </FetchedContent>
  );
};

export default PublicCandidatePage;