import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { styled } from '@material-ui/core/styles';
import { Box, CircularProgress, Container, Grid, Link, Typography } from '@material-ui/core';
import { Trans } from 'react-i18next';

const CopyrightFooter = styled(({ ...props }) => {
  return (
    <Box position="fixed" bottom={0} left={0} right={0} p={2} pt={1} {...props}>
      <Typography variant="body2" color="textSecondary" align="center">
        <Trans>
          {'Copyright © '}
          <Link color="inherit" href="https://www.stateofthings.xyz">
            State of Things
          </Link>{' '}
          {{ year: new Date().getFullYear().toString() }}{'.'}
        </Trans>
      </Typography>
    </Box>
  );
})(props => ({
  backgroundColor: props.theme.palette.background.default,
}));

const AuthPage = ({ title, subtitle, loading, children }) => {
  const theme = useTheme();
  const gridContent = loading
    ? (
      <Container maxWidth="xs">
        <Box align="center">
          <CircularProgress />
        </Box>
      </Container>
    )
    : (
      <>
        <Container maxWidth="sm">
          <Typography variant="h4" align="center">
            {title}
          </Typography>
          {
            subtitle &&
            <Box mt={2}>
              <Typography variant="subtitle1" align="center">
                {subtitle}
              </Typography>
            </Box>
          }
        </Container>
        <Container maxWidth="xs">
          {children}
        </Container>
      </>
    );
  return (
    <>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: `calc(100vh - ${theme.spacing(5.5)}px)` }}
      >
        {gridContent}
      </Grid>
      <Box p={2.75}></Box>
      <CopyrightFooter />
    </>
  );
};

export default AuthPage;