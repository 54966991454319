import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

const integrationTypes = process.env.REACT_APP_CANVASS_INTEGRATION_TYPES_ENABLED
  ? process.env.REACT_APP_CANVASS_INTEGRATION_TYPES_ENABLED.split(',')
  : [];

const useIntegrations = () => {
  const { t } = useTranslation();

  const getIntegrationName = useCallback(integrationType => {
    switch (integrationType) {
      case 'ashby':
        return 'Ashby';
      case 'comeet':
        return 'Comeet';
      case 'greenhouse':
        return 'Greenhouse';
      case 'hiringthing':
        return 'Applicant Tracking';
      case 'hrpartner':
        return 'HR Partner';
      case 'lacrm':
        return 'Less Annoying CRM';
      case 'lever':
        return 'Lever';
      case 'recruitee':
        return 'Recruitee';
      default:
        return t('Integration');
    }
  }, [t]);

  const getAuthTokenLabel = useCallback(integrationType => {
    switch (integrationType) {
      case 'ashby':
        return t('API key');
      case 'comeet':
        return t('API key');
      case 'greenhouse':
        return t('API key');
      case 'hiringthing':
        return t('Password');
      case 'hrpartner':
        return t('API key');
      case 'lacrm':
        return t('API key');
      case 'recruitee':
        return t('Token');
      default:
        return t('Auth token');
    }
  }, [t]);

  const isDisplayCompanyId = integrationType => {
    switch (integrationType) {
      case 'hiringthing':
        return true;
      default:
        return false;
    }
  };

  const isMustProvideAuthToken = integrationType => {
    switch (integrationType) {
      case 'ashby':
        return true;
      case 'comeet':
        return true;
      case 'lacrm':
        return true;
      default:
        return false;
    }
  };

  const getCompanyIdLabel = useCallback(integrationType => {
    switch (integrationType) {
      case 'hiringthing':
        return t('Username');
      default:
        return t('Company ID');
    }
  }, [t]);

  return { integrationTypes, getIntegrationName, getAuthTokenLabel, isDisplayCompanyId, isMustProvideAuthToken, getCompanyIdLabel };
};

export default useIntegrations;