import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Card, CardActionArea, CardContent, Typography } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import { useHistory } from "react-router";

import AuthPage from '../AuthPage';
import api from '../../API';

const CompanyCard = ({ company, ...props }) => {
  const history = useHistory();
  return (
    <Box mb={1} {...props}>
      <Card>
        <CardActionArea onClick={() => history.push(`/companies/${company.companyId}`)}>
          <CardContent align="center">
            <Typography variant="subtitle2">
              {company.companyName}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
};

export default function CompaniesPage() {
  const { t } = useTranslation();
  const history = useHistory();
  const [me, setMe] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      const newMe = await api.fetchMe();
      setLoading(false);
      if (newMe.companies.length === 1) {
        const onlyCompanyId = newMe.companies[0].companyId;
        history.replace(`/companies/${onlyCompanyId}`);
      } else {
        setMe(newMe);
      }
    } catch (e) {
      setLoading(false);
      const message = e?.userMessage?.() || t('Unable to retrieve companies at this time. Please contact us.');
      setError(message);
    }
  }, [setLoading, history, setMe, t, setError]);

  useEffect(() => fetchData(), [fetchData]);

  return (
    <AuthPage title={t('Select a Company')} loading={loading}>
      <Box mt={2}>
        {error && <Alert severity="error">{error}</Alert>}
        {me?.companies?.map(company =>
          <CompanyCard key={company.companyId} company={company} />
        )}
      </Box>
    </AuthPage>
  );
}