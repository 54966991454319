import _ from 'lodash';
import confetti from 'canvas-confetti';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import { Accordion, AccordionDetails, AccordionSummary, Box, Breadcrumbs, Button, ButtonGroup, Card, CardActionArea, CardActions, CardContent, Chip, CircularProgress, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, FormControlLabel, FormGroup, Grid, Grow, Hidden, IconButton, Link, MenuItem, MenuList, Paper, Popper, Switch, Tabs, Tab, TextField, Typography, useMediaQuery, styled, InputAdornment } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import GetAppIcon from '@material-ui/icons/GetApp';
import LinkIcon from '@material-ui/icons/Link';
import SendIcon from '@material-ui/icons/Send';
import SendOutlinedIcon from '@material-ui/icons/SendOutlined';
import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ListIcon from '@material-ui/icons/List';
import UndoIcon from '@material-ui/icons/Undo';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SearchIcon from "@material-ui/icons/Search";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Tooltip from '@material-ui/core/Tooltip';
import { Alert } from '@material-ui/lab';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { useHistory, useParams, generatePath } from "react-router";
import Papa from 'papaparse';

import api from '../../API';
import utils from '../../utils';
import InterviewQuestionsBuilder from '../InterviewQuestionsBuilder';
import EmptyTabState from '../EmptyTabState';
import BoldLink from '../BoldLink';
import FetchedContent from '../FetchedContent';
import SnackbarAlert from '../SnackbarAlert';
import TabPanel from '../TabPanel';
import useQuery from '../../hooks/useQuery';
import usePacingOptions from '../../hooks/usePacingOptions';
import FullWidthSelectControl from '../FullWidthSelectControl';
import LoadingBackdrop from '../LoadingBackdrop';
import CandidateAvatar from '../CandidateAvatar';
import SelectPlanDialog from '../SelectPlanDialog';
import PlanRequiredDialog from '../PlanRequiredDialog';
import useMediaGroups from '../../hooks/useMediaGroups';
import useIntegrations from '../../hooks/useIntegrations';
import { CopyTextIconButton } from '../CopyTextIconButton';

const SelectMembersArea = ({ members, selectedUserIds, setSelectedUserIds, getAccordionSummary, ...props }) => {
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);

  const handleChangeExpanded = (event, newExpanded) => {
    setExpanded(newExpanded);
  };

  const columns = [
    { field: 'id', hide: true },
    {
      field: 'email',
      headerName: t('Email address'),
      flex: 1,
    }
  ];

  const rows = members.map(member => ({
    id: member.userId,
    email: member.email,
  }));

  return (
    <Box {...props}>
      <Accordion expanded={expanded} onChange={handleChangeExpanded}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="subtitle2">
            {getAccordionSummary()}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box width="100%" height="375px">
            <DataGrid
              rows={rows}
              columns={columns}
              selectionModel={selectedUserIds}
              onSelectionModelChange={setSelectedUserIds}
              pageSize={5}
              rowsPerPageOptions={[5]}
              checkboxSelection
              disableSelectionOnClick
              disableColumnMenu
              hideFooterSelectedRowCount
            />
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};


const BulkCandidatesInput = ({ candidates, setCandidates }) => {
  const { t } = useTranslation();
  const inputRef = useRef();

  const handleFileChanged = event => {
    const csvFile = event.target.files[0];
    if (csvFile) {
      Papa.parse(csvFile, {
        complete: csvCandidates => {
          const newCandidates = _.filter(_.map(csvCandidates.data, csvCandidate => {
            const candidateName = csvCandidate[0].trim();
            const email = csvCandidate[1].trim();
            if (candidateName === '' && email === '') {
              return null;
            }
            return { candidateName, email: email !== '' ? email : null };
          }), _.identity);
          setCandidates(newCandidates);
        }
      });
      inputRef.current.value = '';
    }
  };

  if (candidates.length <= 0) {
    return (
      <>
        <Box>
          <Typography variant="body1">
            <Trans>
              Upload a CSV with columns for: 1) name, and, 2) email:
            </Trans>
          </Typography>
        </Box>
        <Box mt={4} mb={2} display="flex" flexDirection="column" alignItems="center">
          <input type="file" ref={inputRef} onChange={handleFileChanged} />
        </Box>
      </>
    );
  } else {
    const rows = _.map(candidates, candidate => ({
      id: uuidv4(),
      ...candidate,
    }));
    const columns = [
      {
        field: 'id',
        hide: true,
      },
      {
        field: 'candidateName',
        headerName: t('Candidate name'),
        flex: 0.40,
      },
      {
        field: 'email',
        headerName: t('Email address'),
        flex: 0.60,
        renderCell: params => !_.isNull(params.row.email)
          ? params.row.email
          : (
            <Box fontStyle="italic">
              <Typography variant="caption">
                {t("Not available")}
              </Typography>
            </Box>
          ),
      },
    ];
    return (
      <Box width="100%" height="375px">
        <DataGrid
          disableSelectionOnClick={true}
          rows={rows}
          columns={columns}
          pageSize={5}
          rowsPerPageOptions={[5]}
          disableColumnMenu
        />
      </Box>
    );
  }
};

const BulkInviteDialog = ({ positionId, open, closeDialog, refreshData, onCandidatesInvited, ...props }) => {
  const { t } = useTranslation();
  const [candidates, setCandidates] = useState([]);
  const [inviting, setInviting] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (open) {
      setError(null);
      setCandidates([]);
    }
  }, [open, setError]);

  const handleClickClose = () => {
    closeDialog();
  };

  const handleClickSend = async event => {
    event?.preventDefault();
    setInviting(true);
    closeDialog();
    let newError = null;
    try {
      await api.inviteCandidates(positionId, candidates);
    } catch (e) {
      newError = e?.userMessage?.() || t('Unable to send invites at this time. Please contact us.');
    }
    await refreshData();
    if (newError !== null) {
      setError(newError);
    }
    setInviting(false);
    onCandidatesInvited();
  };

  const canSend = candidates.length > 0;

  return (
    <>
      <Dialog open={open} onClose={handleClickClose} fullWidth maxWidth="sm" {...props}>
        <DialogTitle>{t('Invite candidates')}</DialogTitle>
        <DialogContent>
          <BulkCandidatesInput candidates={candidates} setCandidates={setCandidates} />
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClickClose} key="cancel">
            {t('Cancel')}
          </Button>
          <Button variant="contained" color="primary" startIcon={<SendIcon />} onClick={handleClickSend} disabled={!canSend} key="send-invites">
            {t('Send invites')}
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingBackdrop open={inviting} />
      <SnackbarAlert severity="error" message={error} setMessage={setError} />
    </>
  );
};

const InviteDialog = ({ positionId, open, closeDialog, refreshData, onCandidateInvited, ...props }) => {
  const { t } = useTranslation();
  const [isSendEmail, setIsSendEmail] = useState(true);
  const [email, setEmail] = useState('');
  const [candidateName, setCandidateName] = useState('');
  const [inviting, setInviting] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (open) {
      setError(null);
    }
  }, [open, setError]);

  const resetFields = () => {
    setIsSendEmail(true);
    setEmail('');
    setCandidateName('');
  };

  const handleClickClose = () => {
    resetFields();
    closeDialog();
  };

  const handleClickSend = async event => {
    event?.preventDefault();
    setInviting(true);
    closeDialog();
    let newError = null;
    try {
      await api.inviteCandidate(positionId, candidateName, isSendEmail ? email : null);
    } catch (e) {
      newError = e?.userMessage?.() || t('Unable to send invite at this time. Please contact us.');
    }
    await refreshData();
    if (newError !== null) {
      setError(newError);
    }
    resetFields();
    setInviting(false);
    onCandidateInvited();
  };

  const canSend = !!candidateName && (!isSendEmail || !!email);

  return (
    <>
      <Dialog open={open} onClose={handleClickClose} {...props}>
        <DialogTitle>{t('Invite candidate')}</DialogTitle>
        <DialogContent>
          <Box minWidth="390px">
            {/* N.B. needed the minWidth otherwise dialog collapses in width when hide the email input
            for some reason */}
            <FormGroup>
              <FormControlLabel
                control={<Switch checked={isSendEmail} onChange={() => setIsSendEmail(prev => !prev)} />}
                label={t("Send email invite")}
              />
            </FormGroup>
            <TextField
              autoFocus
              variant="outlined"
              margin="normal"
              fullWidth
              label={t('Candidate name')}
              value={candidateName}
              onChange={event => setCandidateName(event.target.value)}
            />
            {
              isSendEmail &&
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                label={t('Email address')}
                value={email}
                onChange={event => setEmail(event.target.value)}
              />
            }
          </Box>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={handleClickClose} key="cancel">
            {t('Cancel')}
          </Button>
          <Button variant="contained" color="primary" startIcon={isSendEmail ? <SendIcon /> : <LinkIcon />} onClick={handleClickSend} disabled={!canSend} key="send-invite">
            {isSendEmail ? t("Send invite") : t("Create link")}
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingBackdrop open={inviting} />
      <SnackbarAlert severity="error" message={error} setMessage={setError} />
    </>
  );
};

const ExpandGroupButton = styled(({ ...props }) => {
  return (
    <Button {...props}>
      <ArrowDropDownIcon />
    </Button>
  );
})({
  width: 'auto !important'
});

const InviteCandidateButton = ({ hasCandidates, onClickInvite, onClickBulkInvite, onClickSendPreview, onClickDownloadCandidates, ...props }) => {
  const { t } = useTranslation();

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleClickBulkInvite = () => {
    setOpen(false);
    onClickBulkInvite();
  };

  const handleClickSendPreview = () => {
    setOpen(false);
    onClickSendPreview();
  };

  const handleClickDownloadCandidates = async () => {
    setOpen(false);
    onClickDownloadCandidates();
  };

  const handleToggle = event => {
    event.stopPropagation();
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <ButtonGroup ref={anchorRef} variant="contained" color="secondary" {...props}>
        <Button startIcon={<SendIcon />} onClick={onClickInvite}>
          {t('Invite candidate')}
        </Button>
        <ExpandGroupButton size="small" onClick={handleToggle} />
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} placement="bottom-end" role={undefined} transition disablePortal style={{ zIndex: 3000 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="invite-candidate-menu">
                  <MenuItem key="invite-candidates-bulk" onClick={handleClickBulkInvite}>
                    <ListIcon />&nbsp;<span>{t('Invite candidates in bulk')}</span>
                  </MenuItem>
                  <MenuItem key="send-preview-invite" onClick={handleClickSendPreview}>
                    <SendOutlinedIcon fontSize="small" />&nbsp;<span>{t('Send yourself a preview')}</span>
                  </MenuItem>
                  <MenuItem key="download-candidates" onClick={handleClickDownloadCandidates} disabled={!hasCandidates}>
                    <GetAppIcon fontSize="small" />&nbsp;<span>{t('Download candidates')}</span>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

const getCandidateCardActions = (t, candidate, moveCandidate, reinviteCandidate, deleteCandidate, isEvaluationEnabled) => {
  if (candidate.currentStatus === 'invited') {
    return (
      <CardActions>
        <Button
          key="reinvite" size="small" color="primary"
          onClick={() => reinviteCandidate(candidate)}
        >
          {t('Resend invite')}
        </Button>
        <Button
          key="delete" size="small" color="primary"
          onClick={() => deleteCandidate(candidate)}
        >
          {t('Delete invite')}
        </Button>
      </CardActions>
    );
  }

  if (!isEvaluationEnabled) {
    return null;
  }

  switch (candidate.currentStatus) {
    case 'responded':
      return (
        <CardActions>
          <Button
            key="shortlist" size="small" color="primary"
            startIcon={<ThumbUpIcon />}
            onClick={() => moveCandidate(candidate, 'shortlist')}
          >
            {t('Shortlist')}
          </Button>
          <Button
            key="reject" size="small" color="primary"
            startIcon={<ThumbDownIcon />}
            onClick={() => moveCandidate(candidate, 'reject')}
          >
            {t('Reject')}
          </Button>
        </CardActions>
      );
    case 'shortlisted':
      return (
        <CardActions>
          <Button
            key="undo" size="small" color="primary"
            startIcon={<UndoIcon />}
            onClick={() => moveCandidate(candidate, 'undo')}
          >
            {t('Undo evaluation')}
          </Button>
          <Button
            key="reject" size="small" color="primary"
            startIcon={<ThumbDownIcon />}
            onClick={() => moveCandidate(candidate, 'reject')}
          >
            {t('Reject')}
          </Button>
        </CardActions>
      );
    case 'rejected':
      return (
        <CardActions>
          <Button
            key="undo" size="small" color="primary"
            startIcon={<UndoIcon />}
            onClick={() => moveCandidate(candidate, 'undo')}
          >
            {t('Undo evaluation')}
          </Button>
          <Button
            key="shortlist" size="small" color="primary"
            startIcon={<ThumbUpIcon />}
            onClick={() => moveCandidate(candidate, 'shortlist')}
          >
            {t('Shortlist')}
          </Button>
        </CardActions>
      );
    default:
      return null;
  }
};

const CandidateCard = ({ companyId, positionId, candidate, moveCandidate, reinviteCandidate, deleteCandidate, isEvaluationEnabled }) => {
  const { t } = useTranslation();
  const { getIntegrationName } = useIntegrations();
  const history = useHistory();

  const cardActions = getCandidateCardActions(t, candidate, moveCandidate, reinviteCandidate, deleteCandidate, isEvaluationEnabled);

  const isInvited = candidate.currentStatus === 'invited';
  const hasCompleted = !isInvited;
  const clickable = hasCompleted;
  const isLinkOnlyCandidate = _.isUndefined(candidate.email) || _.isNull(candidate.email);

  const cardContent = (
    <CardContent>
      <Box display="flex">
        {
          candidate.thumbnail &&
          <CandidateAvatar
            candidateName={candidate.candidateName}
            thumbnailUrl={candidate.thumbnail.variants[0].contentHref}
            ml={1} mr={1} mt={1}
          />
        }
        <Box key="info" ml={1} pt={0.7}>
          <Typography variant="h6">
            {candidate.candidateName}
          </Typography>
          <Box key="email" mt={.7}>
            {
              isLinkOnlyCandidate && hasCompleted &&
              <Box fontStyle="italic">
                <Typography variant="caption">
                  {t("No email available")}
                </Typography>
              </Box>
            }
            {
              !isLinkOnlyCandidate &&
              <Link href={`mailto:${candidate.email}`}>
                {candidate.email}
              </Link>
            }
          </Box>
          {
            isInvited &&
            <Box key="interview-href" mt={.7}>
              <Link href={candidate.interviewHref} target="_blank">
                {candidate.interviewHref}
              </Link>
            </Box>
          }
          <Box mt={1}>
            {
              isInvited &&
              <>
                <Box key="created-at">
                  <Typography variant="caption">
                    {
                      !!candidate.integrationType
                        ? t('Invited {{ago}} via {{integrationName}}', {
                          integrationName: getIntegrationName(candidate.integrationType),
                          ...candidate.createdAt,
                        })
                        : t('Invited {{ago}}', candidate.createdAt)
                    }
                  </Typography>
                </Box>
                {
                  candidate.expiresAt &&
                  <Box key="expires-at">
                    <Typography variant="caption">
                      {t('Expires {{ago}}', candidate.expiresAt)}
                    </Typography>
                  </Box>
                }
              </>
            }
            {
              hasCompleted && !!candidate.integrationType &&
              <Box key="invited-via">
                <Typography variant="caption">
                  {t('Invited via {{integrationName}}', {
                    integrationName: getIntegrationName(candidate.integrationType),
                  })}
                </Typography>
              </Box>
            }
            {
              hasCompleted &&
              <Box key="completed-at">
                <Typography variant="caption">
                  {t('Interviewed {{ago}}', candidate.completedAt)}
                </Typography>
              </Box>
            }
          </Box>
        </Box>
      </Box>
    </CardContent>
  );

  return (
    <Box mb={1} p={0}>
      <Card>
        {
          clickable
            ? (
              <CardActionArea onClick={() => history.push(`/companies/${companyId}/positions/${positionId}/candidates/${candidate.candidateId}`)}>
                {cardContent}
              </CardActionArea>
            ) : cardContent
        }
        {
          cardActions &&
          <Box display="flex" flexDirection="row-reverse">
            {cardActions}
          </Box>
        }
      </Card>
    </Box>
  );
};

const PAGE_SIZE = 12;
const MIN_SEARCH_TEXT_LENGTH = 3;

const CandidateCards = ({ candidates, cardBuilder, lastCandidateId, setLastCandidateId, ...props }) => {
  const { t } = useTranslation();

  const findCandidateIndex = candidateId => _.findIndex(candidates, candidate => candidate.candidateId === candidateId);

  const candidateId = lastCandidateId && findCandidateIndex(lastCandidateId) >= 0
    ? lastCandidateId
    : (
      candidates.length > 0
        ? candidates[Math.min(candidates.length, PAGE_SIZE) - 1].candidateId
        : null
    );
  const candidateIndex = findCandidateIndex(candidateId);
  const partialCandidates = candidateIndex >= 0
    ? candidates.slice(0, candidateIndex + 1)
    : [];
  const isMoreToLoad = partialCandidates.length < candidates.length;

  const handleClickLoadMore = () => {
    const newCandidateIndex = Math.min(candidateIndex + PAGE_SIZE, candidates.length - 1);
    setLastCandidateId(candidates[newCandidateIndex].candidateId);
  };

  return (
    <>
      {partialCandidates.map(cardBuilder)}
      {!candidates.length && props.children}
      {
        isMoreToLoad &&
        <Box textAlign="center" mt={5}>
          <Link onClick={handleClickLoadMore} color="primary" style={{ fontSize: '1.5rem', cursor: 'pointer' }}>
            {t('Load more')}
          </Link>
        </Box>
      }
    </>
  );
};

const filterStatus = (candidates, statuses) =>
  candidates.filter(candidate => statuses.includes(candidate.currentStatus));

const sortedCandidates = candidates =>
  candidates.sort((candidate, other) => {
    const isInvited = candidate.currentStatus === 'invited';
    const isOtherInvited = other.currentStatus === 'invited';
    if (isInvited && isOtherInvited) {
      return candidate['createdAt'].time > other['createdAt'].time ? -1 : 1;
    } else if (!isInvited && !isOtherInvited) {
      return candidate['completedAt'].time > other['completedAt'].time ? -1 : 1;
    } else if (isInvited) {
      return 1;
    } else {
      return -1;
    }
  });

const ACTION_TO_STATUS = {
  shortlist: 'shortlisted',
  reject: 'rejected',
  undo: 'responded',
};

const STATUS_REQUIRES_ACTION = _.invert(ACTION_TO_STATUS);

const moveMatching = (candidates, candidateId, action) =>
  candidates.map(candidate => ({
    ...candidate,
    currentStatus: candidate.candidateId === candidateId
      ? ACTION_TO_STATUS[action]
      : candidate.currentStatus,
  }));

const isNeedsPlanToInvite = planStatus => {
  if (planStatus.subscriptionStatus === 'not_subscribed') {
    // No subscription so check if has remaining allocation
    return planStatus.candidatePacksStatus.remaining <= 0;
  }

  // Has a subscription
  return false;
};

const CandidateCount = styled(({ children, ...props }) => {
  return (
    <span {...props}>
      {children}
    </span>
  );
})({
  fontSize: '1.2rem',
});

const SearchTextField = ({ searchText, setSearchText, ...props }) => {
  const { t } = useTranslation();
  return (
    <Tooltip open={!!searchText.length && searchText.length < MIN_SEARCH_TEXT_LENGTH} title={t("Enter at least {{MIN_SEARCH_TEXT_LENGTH}} characters to search...", { MIN_SEARCH_TEXT_LENGTH })} arrow>
      <TextField
        variant="outlined"
        placeholder={t("Search all candidates...")}
        InputProps={{
          startAdornment: (
            <InputAdornment>
              <IconButton>
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          )
        }}
        value={searchText}
        onChange={event => setSearchText(event.target.value)}
        {...props}
      />
    </Tooltip>
  );
};

const CandidatesPanel = ({ tab, setTab, searchText, setSearchText, companyId, positionId, position, candidates, setCandidates, planStatus, ownershipStatus, companyConfig, refreshData }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const query = useQuery();
  const lastCandidateId = query.get('lastCandidateId');
  const [inviteOpen, setInviteOpen] = useState(false);
  const [bulkInviteOpen, setBulkInviteOpen] = useState(false);
  const [selectPlanOpen, setSelectPlanOpen] = useState(false);
  const [planRequiredOpen, setPlanRequiredOpen] = useState(false);
  const [success, setSuccess] = useState(null);
  const [error, setError] = useState(null);

  const isReachedQuotaOnPurchase = useMemo(() => {
    if (planStatus.subscriptionStatus === 'not_subscribed') {
      // Consider reached quota if had allocation that's been consumed
      return planStatus.candidatePacksStatus.allocated > 0 && planStatus.candidatePacksStatus.remaining <= 0;
    }

    // Has a subscription
    return false;
  }, [planStatus]);

  const moveCandidate = async (candidate, action) => {
    const candidateId = candidate.candidateId;
    const startingStatus = candidate.currentStatus;
    setCandidates(candidates => moveMatching(candidates, candidateId, action));
    try {
      await api.updateCandidateStatus(candidateId, action);
    } catch (e) {
      const undoAction = STATUS_REQUIRES_ACTION[startingStatus];
      setCandidates(candidates => moveMatching(candidates, candidateId, undoAction));
      setError(t('Unable to move {{candidateName}}. Please contact us.', candidate));
    }
  };

  const reinviteCandidate = async candidate => {
    const candidateId = candidate.candidateId;
    setCandidates((candidates) => candidates.filter(
      candidate => candidate.candidateId !== candidateId
    ));
    let isReinviteError = false;
    try {
      await api.reinviteCandidate(candidateId)
    } catch (e) {
      setCandidates((candidates) => [...candidates, candidate]);
      setError(t('Unable to reinvite {{candidateName}}. Please contact us.', candidate));
      isReinviteError = true;
    }
    if (!isReinviteError) {
      await refreshData();
    }
  };

  const deleteCandidate = async candidate => {
    const candidateId = candidate.candidateId;
    setCandidates((candidates) => candidates.filter(
      candidate => candidate.candidateId !== candidateId
    ));
    try {
      await api.updateCandidateStatus(candidateId, 'delete');
    } catch (e) {
      setCandidates((candidates) => [...candidates, candidate]);
      setError(t('Unable to delete {{candidateName}}. Please contact us.', candidate));
    }
  };

  const buildCandidateCard = candidate =>
    <CandidateCard
      key={candidate.candidateId}
      companyId={companyId}
      positionId={positionId}
      candidate={candidate}
      moveCandidate={moveCandidate}
      reinviteCandidate={reinviteCandidate}
      deleteCandidate={deleteCandidate}
      isEvaluationEnabled={companyConfig.isEvaluationEnabled}
    />;

  const invitedCandidates = sortedCandidates(filterStatus(candidates, ['invited']));
  const respondedCandidates = companyConfig.isEvaluationEnabled
    ? sortedCandidates(filterStatus(candidates, ['responded']))
    : sortedCandidates(filterStatus(candidates, ['responded', 'shortlisted', 'rejected']));
  const shortlistedCandidates = companyConfig.isEvaluationEnabled
    ? sortedCandidates(filterStatus(candidates, ['shortlisted']))
    : [];
  const rejectedCandidates = companyConfig.isEvaluationEnabled
    ? sortedCandidates(filterStatus(candidates, ['rejected']))
    : [];

  const includesSearchText = checkString =>
    !!checkString && checkString.toLocaleLowerCase().includes(searchText.toLocaleLowerCase());

  const hasSearchText = !!searchText.length;
  const searchedCandidates = hasSearchText && searchText.length >= MIN_SEARCH_TEXT_LENGTH
    ? sortedCandidates(candidates.filter(candidate => includesSearchText(candidate.candidateName) || includesSearchText(candidate.email)))
    : [];

  const invitedLabel = (
    <Trans>
      <Box display="flex" alignItems="center">
        <CandidateCount>{{ invited: invitedCandidates.length }}</CandidateCount>&nbsp;&nbsp;invited
      </Box>
    </Trans>
  );
  const respondedLabel = (
    <Trans>
      <Box display="flex" alignItems="center">
        <CandidateCount>{{ responded: respondedCandidates.length }}</CandidateCount>&nbsp;&nbsp;responded
      </Box>
    </Trans>
  );
  const shortlistedLabel = (
    <Trans>
      <Box display="flex" alignItems="center">
        <CandidateCount>{{ shortlisted: shortlistedCandidates.length }}</CandidateCount>&nbsp;&nbsp;shortlisted
      </Box>
    </Trans>
  );
  const rejectedLabel = (
    <Trans>
      <Box display="flex" alignItems="center">
        <CandidateCount>{{ rejected: rejectedCandidates.length }}</CandidateCount>&nbsp;&nbsp;rejected
      </Box>
    </Trans>
  );

  const showPlanDialog = () => {
    if (ownershipStatus.isOwner) {
      setSelectPlanOpen(true);
    } else {
      setPlanRequiredOpen(true);
    }
  };

  const areInvitesDisabled = position.currentStatus !== 'open';

  const handleClickInvite = () => {
    if (areInvitesDisabled) {
      return;
    }

    if (isNeedsPlanToInvite(planStatus)) {
      showPlanDialog();
    } else {
      setInviteOpen(true)
    }
  };

  const handleClickBulkInvite = () => {
    if (areInvitesDisabled) {
      return;
    }

    if (isNeedsPlanToInvite(planStatus)) {
      showPlanDialog();
    } else {
      setBulkInviteOpen(true)
    }
  };

  const handleClickSendPreview = async () => {
    if (areInvitesDisabled) {
      return;
    }

    setError(null);
    setSuccess(null);
    try {
      await api.sendPreviewInvite(positionId);
      setSuccess(t("We've emailed you a preview invite"));
    } catch (e) {
      const message = e?.userMessage?.() || t('Unable to send a preview at this time. Please contact us.');
      setError(message);
    }
  };

  const handleClickDownloadCandidates = async () => {
    setError(null);
    setSuccess(null);
    try {
      const currentDate = new Date();
      const offset = currentDate.getTimezoneOffset();
      const normalisedDate = new Date(currentDate.getTime() - (offset * 60 * 1000));
      const dateStr = normalisedDate.toISOString().split('T')[0];
      const normalisedJobTitle = position.jobTitle.replace(' ', '_');
      const downloadFilename = `${dateStr}_${normalisedJobTitle}_Candidates.csv`;

      const candidatesExport = await api.fetchCandidatesExport(positionId);
      const csvData = Papa.unparse(candidatesExport);
      const csvBlob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const csvURL = navigator.msSaveBlob
        ? navigator.msSaveBlob(csvBlob, downloadFilename)
        : window.URL.createObjectURL(csvBlob);

      const tempLink = document.createElement('a');
      tempLink.href = csvURL;
      tempLink.setAttribute('download', downloadFilename);
      tempLink.click();

      setSuccess(t("Check your downloads folder"));
    } catch (e) {
      const message = e?.userMessage?.() || t('Unable to download candidates at this time. Please contact us.');
      setError(message);
    }
  };

  const setTabLastCandidateId = candidateId => {
    setTab(tab, candidateId);
  };

  const setSearchLastCandidateId = candidateId => {
    setSearchText(searchText, candidateId);
  };

  return (
    <>
      {
        position.currentStatus === 'closed' &&
        <Box mb={1}>
          <Alert severity="info">
            <Trans>
              The position is currently closed and needs to be re-opened before additional
              candidates can be invited.
            </Trans>
          </Alert>
        </Box>
      }
      {
        isReachedQuotaOnPurchase &&
        <Box mb={1}>
          <Alert severity="warning">
            <Trans>
              You'll need to <Link onClick={() => setSelectPlanOpen(true)} style={{ cursor: 'pointer' }}>select a plan</Link> to invite additional candidates
            </Trans>
          </Alert>
        </Box>
      }
      <Hidden only={['md', 'lg', 'xl']}>
        <Box mb={2}>
          <InviteCandidateButton
            fullWidth
            hasCandidates={candidates.length > 0}
            onClickInvite={handleClickInvite}
            onClickBulkInvite={handleClickBulkInvite}
            onClickSendPreview={handleClickSendPreview}
            onClickDownloadCandidates={handleClickDownloadCandidates}
            disabled={areInvitesDisabled}
          />
        </Box>
        <Box mb={2}>
          <SearchTextField searchText={searchText} setSearchText={setSearchText} fullWidth />
        </Box>
      </Hidden>
      <Hidden smDown>
        <Box mt={2.5} display="flex" flexDirection="row" alignItems="center">
          <Box flexGrow={1}>
            <SearchTextField searchText={searchText} setSearchText={setSearchText} style={{ minWidth: '400px' }} />
          </Box>
          <InviteCandidateButton
            hasCandidates={candidates.length > 0}
            onClick={handleClickInvite}
            onClickBulkInvite={handleClickBulkInvite}
            onClickSendPreview={handleClickSendPreview}
            onClickDownloadCandidates={handleClickDownloadCandidates}
            disabled={areInvitesDisabled}
          />
        </Box>
      </Hidden>
      {
        hasSearchText &&
        <>
          {
            searchText.length < MIN_SEARCH_TEXT_LENGTH &&
            <Box display="flex" justifyContent="center" mt={10} mb={10}>
              <CircularProgress />
            </Box>
          }
          {
            searchText.length >= MIN_SEARCH_TEXT_LENGTH &&
            <Box mt={2}>
              <CandidateCards
                candidates={searchedCandidates}
                cardBuilder={buildCandidateCard}
                lastCandidateId={lastCandidateId}
                setLastCandidateId={setSearchLastCandidateId}
              >
                <EmptyTabState mt={10}
                  title={t('Empty results')}
                  subtitle={t('No matching candidates were found')}
                />
              </CandidateCards>
            </Box>
          }
        </>
      }
      {
        !hasSearchText &&
        <>
          <Box mt={2}>
            <Tabs
              variant={isSmall ? 'scrollable' : 'fullWidth'}
              scrollButtons={isSmall ? 'on' : 'auto'}
              value={tab}
              onChange={(event, newTab) => setTab(newTab)}
            >
              <Tab wrapped={isExtraSmall} key="invited" value="invited" label={invitedLabel} />
              <Tab wrapped={isExtraSmall} key="responded" value="responded" label={respondedLabel} />
              {
                companyConfig.isEvaluationEnabled &&
                <Tab wrapped={isExtraSmall} key="shortlisted" value="shortlisted" label={shortlistedLabel} />
              }
              {
                companyConfig.isEvaluationEnabled &&
                <Tab wrapped={isExtraSmall} key="rejected" value="rejected" label={rejectedLabel} />
              }
            </Tabs>
          </Box>
          <Box mt={2}>
            <TabPanel active={tab === 'invited'} key="invited">
              <CandidateCards
                candidates={invitedCandidates}
                cardBuilder={buildCandidateCard}
                lastCandidateId={lastCandidateId}
                setLastCandidateId={setTabLastCandidateId}
              >
                <EmptyTabState mt={10}
                  icon={<SendIcon fontSize="large" />}
                  title={t('Invited')}
                  subtitle={t('Sent an interview to complete')}
                />
              </CandidateCards>
            </TabPanel>
            <TabPanel active={tab === 'responded'} key="responded">
              <CandidateCards
                candidates={respondedCandidates}
                cardBuilder={buildCandidateCard}
                lastCandidateId={lastCandidateId}
                setLastCandidateId={setTabLastCandidateId}
              >
                <EmptyTabState mt={10}
                  icon={<ThumbsUpDownIcon fontSize="large" />}
                  title={t('Responded')}
                  subtitle={t('Completed the interview')}
                />
              </CandidateCards>
            </TabPanel>
            {
              companyConfig.isEvaluationEnabled &&
              <TabPanel active={tab === 'shortlisted'} key="shortlisted">
                <CandidateCards
                  candidates={shortlistedCandidates}
                  cardBuilder={buildCandidateCard}
                  lastCandidateId={lastCandidateId}
                  setLastCandidateId={setTabLastCandidateId}
                >
                  <EmptyTabState mt={10}
                    icon={<ThumbUpIcon fontSize="large" />}
                    title={t('Shortlisted')}
                    subtitle={t('Promising to move forward')}
                  />
                </CandidateCards>
              </TabPanel>
            }
            {
              companyConfig.isEvaluationEnabled &&
              <TabPanel active={tab === 'rejected'} key="rejected">
                <CandidateCards
                  candidates={rejectedCandidates}
                  cardBuilder={buildCandidateCard}
                  lastCandidateId={lastCandidateId}
                  setLastCandidateId={setTabLastCandidateId}
                >
                  <EmptyTabState mt={10}
                    icon={<ThumbDownIcon fontSize="large" />}
                    title={t('Rejected')}
                    subtitle={t('Not a good fit')}
                  />
                </CandidateCards>
              </TabPanel>
            }
          </Box>
        </>
      }
      <InviteDialog
        positionId={positionId}
        open={inviteOpen}
        closeDialog={() => setInviteOpen(false)}
        refreshData={refreshData}
        onCandidateInvited={() => {
          setSearchText('');
          setTab('invited');
        }}
      />
      <BulkInviteDialog
        positionId={positionId}
        open={bulkInviteOpen}
        closeDialog={() => setBulkInviteOpen(false)}
        refreshData={refreshData}
        onCandidatesInvited={() => {
          setSearchText('');
          setTab('invited');
        }}
      />
      <SelectPlanDialog
        fullScreen={isExtraSmall}
        companyId={companyId}
        initialPlanType={isReachedQuotaOnPurchase ? 'purchase' : 'subscription'}
        open={selectPlanOpen}
        closeDialog={() => setSelectPlanOpen(false)}
      />
      <PlanRequiredDialog
        open={planRequiredOpen}
        closeDialog={() => setPlanRequiredOpen(false)}
      />
      <SnackbarAlert severity="success" message={success} setMessage={setSuccess} />
      <SnackbarAlert severity="error" message={error} setMessage={setError} />
    </>
  );
};

const QuestionsPanel = ({ companyId, position, setPosition, onPositionSaved }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const [interviewQuestions, setInterviewQuestions] = useState(_.omit(position.interview, ['pacing']));
  const mediaGroupIds = utils.extractMediaGroupIdsForInterview(interviewQuestions);
  const { mediaGroups, isProcessing } = useMediaGroups(mediaGroupIds);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  const updatedPosition = {
    ...position,
    interview: {
      ...position.interview,
      ...interviewQuestions,
    },
  };

  const areChangesToSave = !_.isEqual(updatedPosition.interview, position.interview);
  const hasQuestions = interviewQuestions.questions.length > 0 && _.every(
    interviewQuestions.questions,
    question => !!question.videoGroupId || question.title.length > 0
  );
  const canSaveChanges = areChangesToSave && hasQuestions && !isProcessing;

  const handleClickSave = async event => {
    event.preventDefault();

    setSaving(true);
    try {
      const savedPosition = await api.updatePosition(position.positionId, updatedPosition);
      setSaving(false);
      setPosition(savedPosition);
      onPositionSaved();
    } catch (e) {
      setSaving(false);
      const message = e?.userMessage?.() || t('Unable to save at this time. Please contact us.');
      setError(message);
    }
  };

  const interviewQuestionsBuilder = useMemo(() =>
    <InterviewQuestionsBuilder
      companyId={companyId}
      positionId={position.positionId}
      interviewQuestions={interviewQuestions}
      setInterviewQuestions={setInterviewQuestions}
      mediaGroups={mediaGroups}
    />,
    [companyId, position, interviewQuestions, setInterviewQuestions, mediaGroups]
  );

  return (
    <Box mt={2.5}>
      {interviewQuestionsBuilder}
      {
        isSmall &&
        <Box p={5}>
          {/* Additional scroll spacer for the fixed bottom bar... */}
        </Box>
      }
      <Box sx={{ position: 'fixed', 'left': 0, 'bottom': 0, 'right': 0 }} style={{ zIndex: 1299 }}>
        <Container maxWidth="md">
          <Paper elevation={5}>
            <Box p={2} display="flex" flexDirection="row-reverse">
              <Box>
                <Button variant="contained" color="primary" disabled={!canSaveChanges} onClick={handleClickSave}>
                  {t('Save changes')}
                </Button>
              </Box>
            </Box>
          </Paper>
        </Container>
      </Box>
      <LoadingBackdrop open={saving} />
      <SnackbarAlert severity="error" message={error} setMessage={setError} />
    </Box>
  );
};

const AddNotificationDialog = ({ open, closeDialog, messageTemplates, messageTriggers, onAdded, ...props }) => {
  const { t } = useTranslation();
  const [templateId, setTemplateId] = useState('');
  const [sendTrigger, setSendTrigger] = useState('');

  const resetFields = () => {
    setTemplateId('');
    setSendTrigger('');
  };

  const handleClickClose = () => {
    resetFields();
    closeDialog();
  };

  const handleClickAdd = async event => {
    event?.preventDefault();
    closeDialog();
    onAdded({ templateId, sendTrigger });
    resetFields();
  };

  const canAdd = !!templateId && !!sendTrigger;

  return (
    <Dialog open={open} onClose={handleClickClose} {...props}>
      <DialogTitle>{t('New notification')}</DialogTitle>
      <DialogContent>
        <Box minWidth="390px">
          <Box mb={2}>
            <FullWidthSelectControl
              label={t('Email template')}
              labelId="candidate-notification-template-id"
              value={templateId}
              onChange={event => setTemplateId(event.target.value)}
              options={messageTemplates.map(messageTemplate => [messageTemplate.templateName, messageTemplate.templateId])}
            />
          </Box>
          <Box mb={2}>
            <FullWidthSelectControl
              label={t('Email trigger')}
              labelId="candidate-notification-trigger-value"
              value={sendTrigger}
              onChange={event => setSendTrigger(event.target.value)}
              options={messageTriggers.map(messageTrigger => [messageTrigger.label, messageTrigger.value])}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleClickClose} key="cancel">
          {t('Cancel')}
        </Button>
        <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleClickAdd} disabled={!canAdd} key="add-notification">
          {t("Add notification")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CandidateNotificationRow = ({ plannedMessage, messageTemplates, messageTriggers, notificationNumber, isLastItem, onChanged, onDeleted }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <>
      <Box display="flex" flexDirection={isSmall ? 'column' : 'row'} alignItems={isSmall ? 'flex-start' : 'center'} mt={2} mb={2} width="100%">
        <Box p={2} mb={isSmall ? -2 : 0}>
          <Typography variant="subtitle2">
            {t('Notification #{{notificationNumber}}', { notificationNumber })}
          </Typography>
        </Box>
        <Box p={2} flexGrow={1} width={isSmall ? '100%' : 'auto'} style={{ boxSizing: 'border-box' }}>
          <FullWidthSelectControl
            label={t('Email template')}
            labelId="candidate-notification-template-id"
            value={plannedMessage.templateId}
            onChange={event => onChanged({ ...plannedMessage, templateId: event.target.value })}
            options={messageTemplates.map(messageTemplate => [messageTemplate.templateName, messageTemplate.templateId])}
          />
        </Box>
        <Box display="flex" alignItems="center" width={isSmall ? '100%' : 'auto'} style={{ boxSizing: 'border-box' }}>
          <Box p={2} flexGrow={1}>
            <Box width={isSmall ? 'auto' : '150px'}>
              <FullWidthSelectControl
                label={t('Email trigger')}
                labelId="candidate-notification-trigger-value"
                value={plannedMessage.sendTrigger}
                onChange={event => onChanged({ ...plannedMessage, sendTrigger: event.target.value })}
                options={messageTriggers.map(messageTrigger => [messageTrigger.label, messageTrigger.value])}
              />
            </Box>
          </Box>
          <Box p={2}>
            <IconButton onClick={() => { onDeleted(plannedMessage) }}>
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>
      {!isLastItem && <Divider variant="middle" />}
    </>
  );
};

const sortedPlannedMessages = plannedMessages =>
  [...plannedMessages].sort((message, other) => {
    if (message.sendTrigger === 'invite') {
      return other.sendTrigger === 'invite' ? 0 : -1;
    } else if (other.sendTrigger === 'invite') {
      return 1;
    } else if (message.sendTrigger === 'response') {
      return other.sendTrigger === 'response' ? 0 : 1;
    } else if (other.sendTrigger === 'response') {
      return -1;
    } else {
      return moment.duration(message.sendTrigger) - moment.duration(other.sendTrigger);
    }
  });

const assignEphemeralIds = plannedMessages => {
  (plannedMessages || []).forEach(plannedMessage => {
    if (!plannedMessage.ephemeralId) {
      plannedMessage.ephemeralId = uuidv4();
    }
  });
};

const CandidateNotifications = ({ messageTemplates, messageTriggers, plannedMessages, setPlannedMessages }) => {
  const { t } = useTranslation();
  const [isAddingNotification, setIsAddingNotification] = useState(false);

  assignEphemeralIds(plannedMessages);
  plannedMessages = sortedPlannedMessages(plannedMessages);

  const handlePlannedMessageChanged = updatedMessage =>
    setPlannedMessages(plannedMessages => _.map(
      plannedMessages,
      plannedMessage => plannedMessage.ephemeralId === updatedMessage.ephemeralId
        ? updatedMessage
        : plannedMessage
    ));

  const handlePlannedMessageDeleted = deletedMessage =>
    setPlannedMessages(plannedMessages => _.filter(
      plannedMessages,
      plannedMessage => plannedMessage.ephemeralId !== deletedMessage.ephemeralId
    ));

  return (
    <>
      <Box display="flex" flexDirection="column" mt={-2} mb={-2} width="100%">
        {
          !plannedMessages?.length &&
          <Box mt={6} mb={6} p={2} textAlign="center" fontStyle="italic">
            <Typography variant='body1'>
              {t("Candidates will not be sent any notifications")}
            </Typography>
          </Box>
        }
        {
          !!plannedMessages?.length &&
          _.map(plannedMessages, (plannedMessage, index) => <CandidateNotificationRow
            key={plannedMessage.ephemeralId}
            plannedMessage={plannedMessage}
            messageTemplates={messageTemplates}
            messageTriggers={messageTriggers}
            notificationNumber={index + 1}
            isLastItem={index === plannedMessages.length - 1}
            onChanged={handlePlannedMessageChanged}
            onDeleted={handlePlannedMessageDeleted}
          />)
        }
      </Box>
      <Box mt={2}>
        <Divider variant="middle" />
        <Box mt={2} textAlign="center">
          <Button color="primary" startIcon={<AddIcon />} onClick={() => setIsAddingNotification(true)}>
            {t('Add notification')}
          </Button>
        </Box>
      </Box>
      <AddNotificationDialog
        open={isAddingNotification}
        closeDialog={() => setIsAddingNotification(false)}
        messageTemplates={messageTemplates}
        messageTriggers={messageTriggers}
        onAdded={plannedMessage => setPlannedMessages(plannedMessages => [...plannedMessages, plannedMessage])}
      />
    </>
  );
};

const CandidateDeadline = ({ inviteExpiryOffsets, invitesExpireAfter, setInvitesExpireAfter }) => {
  const { t } = useTranslation();

  const handleChanged = event => {
    const newValue = typeof event.target.value === 'number' && event.target.value < 0
      ? null
      : event.target.value;
    setInvitesExpireAfter(newValue);
  };

  const isValueEmpty = _.isNull(invitesExpireAfter) || _.isUndefined(invitesExpireAfter);

  return (
    <>
      <FullWidthSelectControl
        value={isValueEmpty ? -1 : invitesExpireAfter}
        onChange={handleChanged}
        options={[
          [t('Invitations never expire'), -1],
          ...inviteExpiryOffsets.map(inviteExpiryOffset => [inviteExpiryOffset.label, inviteExpiryOffset.value])
        ]}
      />
      {
        !isValueEmpty &&
        <Box mt={1} color="text.disabled">
          <Typography variant="body2">
            {t("Be aware that candidates are automatically deleted when they don't respond within the deadline")}
          </Typography>
        </Box>
      }
    </>
  );
};

const ApplyLink = ({ positionId, applyLink, setApplyLink }) => {
  const { t } = useTranslation();
  const [creating, setCreating] = useState(false);
  const [error, setError] = useState(null);

  const hasApplyLink = !_.isNull(applyLink) && !_.isUndefined(applyLink);

  const handleChangeApplyLink = async event => {
    setError(null);
    setCreating(true);
    try {
      if (event.target.checked) {
        const newApplyLink = await api.createApplyLink(positionId);
        setApplyLink(newApplyLink);
      } else {
        setApplyLink(null);
      }
    } catch (e) {
      const message = e?.userMessage?.() || t('Unable to create public link at this time. Please contact us.');
      setError(message);
    }
    setCreating(false);
  };

  return (
    <>
      {
        error &&
        <Box mb={2}>
          <Alert severity="error">{error}</Alert>
        </Box>
      }
      <Box>
        <FormControlLabel
          control={
            <Switch
              checked={hasApplyLink}
              onChange={handleChangeApplyLink}
              color="primary"
            />
          }
          label={t('Allow applications through a public link')}
        />
      </Box>
      {
        creating &&
        <Box p={4} display="flex" alignItems="center" justifyItems="center" alignContent="center" justifyContent="center">
          <CircularProgress color="primary" />
        </Box>
      }
      {
        !creating && hasApplyLink &&
        <>
          <Box mt={2}>
            <Chip label={applyLink.linkHref} style={{ cursor: 'text', maxWidth: '100%', marginRight: '5px' }} />
            <CopyTextIconButton text={applyLink.linkHref} />
          </Box>
          <Box mt={2}>
            <Typography variant="caption">
              <Trans>
                Anyone can respond to your questions through this link. They'll be required to provide their full name and email address.
              </Trans>
            </Typography>
          </Box>
        </>
      }
    </>
  )
};

const SettingsArea = ({ header, children, ...props }) =>
  <Paper {...props}>
    <Box p={2}>
      <Typography variant="h6" gutterBottom>
        {header}
      </Typography>
      {children}
    </Box>
  </Paper>;

const SettingsPanel = ({ companyId, companyConfig, me, position, setPosition, members, messageTriggers, messageTemplates, inviteExpiryOffsets, onPositionSaved }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const history = useHistory();
  const {
    thinkingTimeOptions,
    answerTimeOptions,
    retakesOptions,
  } = usePacingOptions();
  const [jobTitle, setJobTitle] = useState(position.jobTitle);
  const initialSharing = position.sharing;
  const [sharing, setSharing] = useState(initialSharing);
  const initialNotifying = position.notifying;
  const [notifying, setNotifying] = useState(initialNotifying);
  const initialPacing = position.interview.pacing;
  const [thinkingTime, setThinkingTime] = useState(
    (_.isUndefined(initialPacing.thinkingTime) || _.isNull(initialPacing.thinkingTime))
      ? -1
      : initialPacing.thinkingTime
  );
  const [answerTime, setAnswerTime] = useState(initialPacing.answerTime);
  const [retakes, setRetakes] = useState(
    (_.isUndefined(initialPacing.retakes) || _.isNull(initialPacing.retakes))
      ? -1
      : initialPacing.retakes
  );
  const [plannedMessages, setPlannedMessages] = useState(position.plannedMessages);
  const [invitesExpireAfter, setInvitesExpireAfter] = useState(position.invitesExpireAfter);
  const [applyLink, setApplyLink] = useState(position.applyLink);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  const updatedPosition = {
    jobTitle,
    interview: {
      ...position.interview,
      pacing: {
        thinkingTime: thinkingTime < 0 ? null : thinkingTime,
        answerTime,
        retakes: retakes < 0 ? null : retakes,
      },
    },
    sharing,
    notifying,
    plannedMessages,
    invitesExpireAfter,
    applyLink,
  };

  const areChangesToSave = !_.isEqual(
    _.pick(updatedPosition, ['jobTitle', 'interview', 'sharing', 'notifying', 'plannedMessages', 'invitesExpireAfter', 'applyLink']),
    _.pick(position, ['jobTitle', 'interview', 'sharing', 'notifying', 'plannedMessages', 'invitesExpireAfter', 'applyLink'])
  );
  const someMembersCanAccessPosition = sharing === null || sharing.userIds.length > 0;
  const hasJobTitle = !!jobTitle;
  const canSaveChanges = hasJobTitle && areChangesToSave && someMembersCanAccessPosition;
  const hasAccessToPosition = sharing === null || _.indexOf(sharing.userIds, me.user.userId) >= 0;
  const isMeNotified = notifying === null || _.indexOf(notifying.userIds, me.user.userId) >= 0;
  const notifiableMembers = sharing !== null
    ? members.filter(member => _.indexOf(sharing.userIds, member.userId) >= 0)
    : members;

  const handleClickSave = async event => {
    event.preventDefault();

    setSaving(true);
    try {
      const savedPosition = await api.updatePosition(position.positionId, updatedPosition);
      setSaving(false);
      setPosition(savedPosition);
      onPositionSaved(!hasAccessToPosition);
    } catch (e) {
      setSaving(false);
      const message = e?.userMessage?.() || t('Unable to save at this time. Please contact us.');
      setError(message);
    }
  };

  const handleClickClose = async () => {
    setSaving(true);
    try {
      await api.updatePositionStatus(position.positionId, 'close');
      setSaving(false);
      setPosition(position => ({ ...position, currentStatus: 'closed' }));
      onPositionSaved(!hasAccessToPosition);
    } catch (e) {
      setSaving(false);
      const message = e?.userMessage?.() || t('Unable to close at this time. Please contact us.');
      setError(message);
    }
  };

  const handleClickReopen = async () => {
    setSaving(true);
    try {
      await api.updatePositionStatus(position.positionId, 'open');
      setSaving(false);
      setPosition(position => ({ ...position, currentStatus: 'open' }));
      onPositionSaved(!hasAccessToPosition);
    } catch (e) {
      setSaving(false);
      const message = e?.userMessage?.() || t('Unable to re-open at this time. Please contact us.');
      setError(message);
    }
  };

  const handleClickDelete = async () => {
    setSaving(true);
    try {
      await api.updatePositionStatus(position.positionId, 'delete');
      setSaving(false);
      history.push(`/companies/${companyId}/positions`);
    } catch (e) {
      setSaving(false);
      const message = e?.userMessage?.() || t('Unable to delete at this time. Please contact us.');
      setError(message);
    }
  };

  const handleChangeAccessibleByEntireTeam = event => {
    if (event.target.checked) {
      setSharing(null);
    } else {
      setSharing(initialSharing !== null ? _.cloneDeep(initialSharing) : { userIds: [me.user.userId] })
    }
  };

  const handleChangeNotifyEveryoneWithAccess = event => {
    if (event.target.checked) {
      setNotifying(null);
    } else {
      const notifiableMemberIds = notifiableMembers.map(member => member.userId);
      setNotifying(initialNotifying !== null ? _.cloneDeep(initialNotifying) : { userIds: notifiableMemberIds })
    }
  };

  const leverTag = `Canvass:Config:Position:${position.positionId}`;
  const lacrmTag = `${jobTitle} (${position.positionId})`;

  let statusActions;
  switch (position.currentStatus) {
    case 'open':
      statusActions = (
        <Button color="primary" onClick={handleClickClose}>
          {t('Close position')}
        </Button>
      );
      break;
    case 'closed':
      statusActions = (
        <>
          <Button color="primary" onClick={handleClickReopen} key="reopen">
            {isExtraSmall ? t('Re-open') : t('Re-open position')}
          </Button>
          <Button color="secondary" onClick={handleClickDelete} key="delete">
            {isExtraSmall ? t('Delete') : t('Delete position')}
          </Button>
        </>
      );
      break;
    default:
      statusActions = null;
      break;
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <SettingsArea header={t('Position name')} key="basics">
            <TextField
              fullWidth
              variant="outlined"
              placeholder={t('e.g. Senior Engineer')}
              value={jobTitle}
              onChange={event => setJobTitle(event.target.value)}
            />
          </SettingsArea>
        </Grid>
      </Grid>
      {
        _.includes(companyConfig.integrationTypes, 'lever') &&
        <Box mt={2}>
          <SettingsArea header={t('Lever tag')}>
            <Box mt={2}>
              <Chip label={leverTag} style={{ cursor: 'text', maxWidth: '100%', marginRight: '5px' }} />
              <CopyTextIconButton text={leverTag} />
            </Box>
            <Box mt={2}>
              <Typography variant="caption">
                <Trans>
                  Add this tag to jobs in Lever and candidates will be invited to interview when moved into the appropriate stage.
                </Trans>
              </Typography>
            </Box>
          </SettingsArea>
        </Box>
      }
      {
        _.includes(companyConfig.integrationTypes, 'lacrm') &&
        <Box mt={2}>
          <SettingsArea header={t('Less Annoying CRM tag')}>
            <Box mt={2}>
              <Chip label={lacrmTag} style={{ cursor: 'text', maxWidth: '100%', marginRight: '5px' }} />
              <CopyTextIconButton text={lacrmTag} />
            </Box>
            <Box mt={2}>
              <Typography variant="caption">
                <Trans>
                  Add this as an option to your pipeline in Less Annoying CRM for sending invites.
                </Trans>
              </Typography>
            </Box>
          </SettingsArea>
        </Box>
      }
      <Box mt={2}>
        <SettingsArea header={t('Pacing')} key="pacing">
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <FullWidthSelectControl
                label={t('Thinking time')}
                labelId="thinking-time-label"
                value={thinkingTime}
                onChange={event => setThinkingTime(event.target.value)}
                options={thinkingTimeOptions}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FullWidthSelectControl
                label={t('Max answer length')}
                labelId="answer-time-label"
                value={answerTime}
                onChange={event => setAnswerTime(event.target.value)}
                options={answerTimeOptions}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FullWidthSelectControl
                label={t('Number of retakes')}
                labelId="retakes-label"
                value={retakes}
                onChange={event => setRetakes(event.target.value)}
                options={retakesOptions}
              />
            </Grid>
          </Grid>
        </SettingsArea>
      </Box>
      <Box mt={2}>
        <SettingsArea header={t('Candidate notifications')}>
          <CandidateNotifications
            messageTemplates={messageTemplates}
            messageTriggers={messageTriggers}
            plannedMessages={plannedMessages}
            setPlannedMessages={setPlannedMessages}
          />
        </SettingsArea>
      </Box>
      <Box mt={2}>
        <SettingsArea header={t('Candidate deadline')}>
          <CandidateDeadline
            inviteExpiryOffsets={inviteExpiryOffsets}
            invitesExpireAfter={invitesExpireAfter}
            setInvitesExpireAfter={setInvitesExpireAfter}
          />
        </SettingsArea>
      </Box>
      <Box mt={2}>
        <SettingsArea header={t('Public link')}>
          <ApplyLink
            positionId={position.positionId}
            applyLink={applyLink}
            setApplyLink={setApplyLink}
          />
        </SettingsArea>
      </Box>
      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <SettingsArea header={t('Team access')}>
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={!sharing}
                      onChange={handleChangeAccessibleByEntireTeam}
                      color="primary"
                    />
                  }
                  label={t('Accessible by the entire team')}
                />
              </Box>
              {
                !!sharing &&
                <SelectMembersArea
                  mt={1}
                  members={members}
                  selectedUserIds={sharing.userIds}
                  setSelectedUserIds={userIds => setSharing({ userIds })}
                  getAccordionSummary={() => sharing.userIds.length === 0
                    ? t("Nobody would have access. Changes can't be saved.")
                    : (
                      sharing.userIds.length === 1
                        ? (
                          hasAccessToPosition
                            ? t('Only you have access')
                            : t("1 member has access. You'll lose access")
                        ) : (
                          hasAccessToPosition
                            ? (
                              sharing.userIds.length === 2
                                ? t('You and 1 other member has access')
                                : t('You and {{memberCount}} other members have access', { memberCount: sharing.userIds.length - 1 })
                            )
                            : t("{{memberCount}} members have access. You'll lose access", { memberCount: sharing.userIds.length })
                        )
                    )
                  }
                />
              }
            </SettingsArea>
          </Grid>
          <Grid item xs={12} sm={6}>
            <SettingsArea header={t('Team notifications')}>
              <Box>
                <FormControlLabel
                  control={
                    <Switch
                      checked={!notifying}
                      onChange={handleChangeNotifyEveryoneWithAccess}
                      color="primary"
                    />
                  }
                  label={t('Notify everyone with access')}
                />
              </Box>
              {
                !!notifying &&
                <SelectMembersArea
                  mt={1}
                  members={notifiableMembers}
                  selectedUserIds={notifying.userIds}
                  setSelectedUserIds={userIds => setNotifying({ userIds })}
                  getAccordionSummary={() => notifying.userIds.length === 0
                    ? t("Notifications are not sent to anyone")
                    : (
                      notifying.userIds.length === 1
                        ? (
                          isMeNotified
                            ? t('Only you will receive notifications')
                            : t("1 member will receive notifications")
                        ) : (
                          isMeNotified
                            ? (
                              notifying.userIds.length === 2
                                ? t('You and 1 other member will receive notifications')
                                : t('You and {{memberCount}} other members will receive notifications', { memberCount: notifying.userIds.length - 1 })
                            )
                            : t("{{memberCount}} members will receive notifications", { memberCount: notifying.userIds.length })
                        )
                    )
                  }
                />
              }
            </SettingsArea>
          </Grid>
        </Grid>
      </Box>
      {
        isSmall &&
        <Box p={5}>
          {/* Additional scroll spacer for the fixed bottom bar... */}
        </Box>
      }
      <Box sx={{ position: 'fixed', 'left': 0, 'bottom': 0, 'right': 0 }} style={{ zIndex: 1299 }}>
        <Container maxWidth="md">
          <Paper elevation={5}>
            <Box p={2} display="flex">
              <Box flexGrow={1}>
                {statusActions}
              </Box>
              <Box>
                <Button variant="contained" color="primary" disabled={!canSaveChanges} onClick={handleClickSave}>
                  {t('Save changes')}
                </Button>
              </Box>
            </Box>
          </Paper>
        </Container>
      </Box>
      <LoadingBackdrop open={saving} />
      <SnackbarAlert severity="error" message={error} setMessage={setError} />
    </>
  );
};

const fireConfetti = colors => {
  var count = 200;
  var defaults = {
    origin: { y: 0.6 }
  };

  function fire(particleRatio, opts) {
    confetti(Object.assign({}, defaults, opts, {
      particleCount: Math.floor(count * particleRatio),
      colors
    }));
  }

  fire(0.25, {
    spread: 26,
    startVelocity: 55,
  });
  fire(0.2, {
    spread: 60,
  });
  fire(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 45,
  });
};

const PositionPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const { companyId, positionId } = useParams();
  const query = useQuery();
  const celebrate = query.get('celebrate');
  const initialView = query.get('view') || 'candidates';
  const [view, setView] = useState(initialView);
  const initialCandidatesTab = query.get('tab') || 'invited';
  const [candidatesTab, setCandidatesTab] = useState(initialCandidatesTab);
  const initialSearchText = query.get('searchText') || '';
  const [searchText, setSearchText] = useState(initialSearchText);
  const [me, setMe] = useState(null);
  const [position, setPosition] = useState(null);
  const [candidates, setCandidates] = useState(null);
  const [ownershipStatus, setOwnershipStatus] = useState(null);
  const [planStatus, setPlanStatus] = useState(null);
  const [members, setMembers] = useState(null);
  const [messageTriggers, setMessageTriggers] = useState(null);
  const [messageTemplates, setMessageTemplates] = useState(null);
  const [inviteExpiryOffsets, setInviteExpiryOffsets] = useState(null);
  const [companyConfig, setCompanyConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const history = useHistory();

  const celebrateIfFirstView = useCallback(() => {
    const dedupKey = `celebrate-${positionId}`;
    const firstTime = !localStorage.getItem(dedupKey);
    if (celebrate && firstTime) {
      fireConfetti([
        theme.palette.primary.light,
        theme.palette.primary.main,
        theme.palette.primary.dark,
        theme.palette.secondary.light,
        theme.palette.secondary.main,
        theme.palette.secondary.dark,
      ]);
      localStorage.setItem(dedupKey, 'true');
    }
  }, [positionId, celebrate, theme]);

  const fetchData = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      const [me, position, candidatesResponse, planStatus, ownershipStatus, members, messageTriggers, messageTemplatesResponse, inviteExpiryOffsets, companyConfig] = await Promise.all([
        api.fetchMe(),
        api.fetchPosition(positionId),
        api.fetchCandidates(positionId),
        api.fetchCompanyPlanStatus(companyId),
        api.fetchCompanyOwnershipStatus(companyId),
        api.fetchCompanyMembers(companyId),
        api.fetchAvailableMessageTriggers(),
        api.fetchCompanyMessageTemplates(companyId),
        api.fetchAvailableInviteExpiryOffsets(),
        api.fetchCompanyConfig(companyId),
      ]);
      setMe(me);
      setPosition(position);
      setCandidates(candidatesResponse.candidates)
      setPlanStatus(planStatus)
      setOwnershipStatus(ownershipStatus);
      setMembers(members);
      setMessageTriggers(messageTriggers);
      setMessageTemplates(messageTemplatesResponse.messageTemplates);
      setInviteExpiryOffsets(inviteExpiryOffsets);
      setCompanyConfig(companyConfig);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      const message = e?.userMessage?.() || t('Unable to retrieve data at this time. Please contact us.');
      setError(message);
    }
  }, [positionId, companyId, setLoading, setMe, setPosition, setCandidates, setPlanStatus, setOwnershipStatus, setMembers, setMessageTriggers, setMessageTemplates, setInviteExpiryOffsets, setCompanyConfig, t, setError]);

  useEffect(() => {
    celebrateIfFirstView();
    fetchData();
  }, [celebrateIfFirstView, fetchData]);

  const handleMemberAdded = useCallback(async data => {
    if (companyId === data.companyId) {
      setMembers(members => {
        const newMembers = [data.member, ...members];
        return utils.sortObjectsBy(newMembers, 'email');
      });
    }
  }, [companyId, setMembers]);

  const handleMemberRemoved = useCallback(async data => {
    if (companyId === data.companyId) {
      setMembers(members => _.filter(members, member => member.userId !== data.userId));
    }
  }, [companyId, setMembers]);

  const sortMessageTemplates = messageTemplates =>
    utils.sortObjectsBy(messageTemplates, 'templateName');

  const handleMessageTemplateCreated = useCallback(async data => {
    if (companyId === data.companyId) {
      setMessageTemplates(messageTemplates => sortMessageTemplates(
        [data.messageTemplate, ...messageTemplates]
      ));
    }
  }, [companyId, setMessageTemplates]);

  const handleMessageTemplateUpdated = useCallback(async data => {
    setMessageTemplates(messageTemplates => sortMessageTemplates(
      _.map(
        messageTemplates,
        messageTemplate => messageTemplate.templateId === data.messageTemplate.templateId
          ? data.messageTemplate
          : messageTemplate
      )
    ));
  }, [setMessageTemplates]);

  const handleMessageTemplateDeleted = useCallback(async data => {
    setMessageTemplates(messageTemplates => _.filter(
      messageTemplates,
      messageTemplate => messageTemplate.templateId !== data.templateId
    ));
  }, [setMessageTemplates]);

  useEffect(() => {
    api.on('member.invited', handleMemberAdded);
    api.on('member.removed', handleMemberRemoved);
    api.on('messageTemplate.created', handleMessageTemplateCreated);
    api.on('messageTemplate.updated', handleMessageTemplateUpdated);
    api.on('messageTemplate.deleted', handleMessageTemplateDeleted);
    return () => {
      api.off('member.invited', handleMemberAdded);
      api.off('member.removed', handleMemberRemoved);
      api.off('messageTemplate.created', handleMessageTemplateCreated);
      api.off('messageTemplate.updated', handleMessageTemplateUpdated);
      api.off('messageTemplate.deleted', handleMessageTemplateDeleted);
    };
  }, [handleMemberAdded, handleMemberRemoved, handleMessageTemplateCreated, handleMessageTemplateUpdated, handleMessageTemplateDeleted]);

  const setViewWithHistory = newView => {
    history.replace(`/companies/${companyId}/positions/${positionId}?view=${newView}&tab=${candidatesTab}&searchText=${encodeURIComponent(searchText)}`);
    setView(newView);
  };

  const handleChangeView = (event, newView) => {
    if (newView !== null) {
      setViewWithHistory(newView);
    }
  };

  const positionsHref = generatePath('/companies/:companyId/positions', { companyId });
  const positionHref = generatePath('/companies/:companyId/positions/:positionId', {
    companyId,
    positionId,
  });

  const setCandidatesTabWithHistory = (newTab, lastCandidateId) => {
    let newPath = `/companies/${companyId}/positions/${positionId}?view=${view}&tab=${newTab}`;
    if (lastCandidateId) {
      newPath += `&lastCandidateId=${lastCandidateId}`
    }
    history.replace(newPath);
    setCandidatesTab(newTab);
  };

  const setCandidatesSearchTextWithHistory = (searchText, lastCandidateId) => {
    let newPath = `/companies/${companyId}/positions/${positionId}?view=${view}&tab=${candidatesTab}&searchText=${encodeURIComponent(searchText)}`;
    if (lastCandidateId) {
      newPath += `&lastCandidateId=${lastCandidateId}`
    }
    history.replace(newPath);
    setSearchText(searchText);
  };

  const didDataLoad = !loading && me !== null && position !== null && candidates !== null && planStatus !== null && ownershipStatus !== null && members !== null && messageTriggers !== null && messageTemplates !== null && inviteExpiryOffsets !== null && companyConfig !== null;

  return (
    <FetchedContent loading={loading} mt={2}>
      {error && <Alert severity="error">{error}</Alert>}
      {
        didDataLoad &&
        <>
          <Box
            display="flex"
            flexDirection={isExtraSmall ? 'column' : 'row'}
            alignItems={isExtraSmall ? 'flex-start' : 'center'}
            mb={1.5}
          >
            <Box flexGrow={1}>
              <Breadcrumbs>
                <Link color="inherit" href={'#' + positionsHref}>
                  {t('Positions')}
                </Link>
                <BoldLink color="inherit" href={'#' + positionHref}>
                  {position.jobTitle}
                </BoldLink>
              </Breadcrumbs>
            </Box>
            <Box mt={isExtraSmall ? 1 : 0}>
              <ToggleButtonGroup value={view} onChange={handleChangeView} exclusive>
                <ToggleButton key="candidates" value="candidates">{t('Candidates')}</ToggleButton>
                <ToggleButton key="questions" value="questions">{t('Questions')}</ToggleButton>
                <ToggleButton key="settings" value="settings">{t('Settings')}</ToggleButton>
              </ToggleButtonGroup>
            </Box>
          </Box>
          {
            view === 'candidates' &&
            <CandidatesPanel
              tab={candidatesTab}
              setTab={setCandidatesTabWithHistory}
              searchText={searchText}
              setSearchText={setCandidatesSearchTextWithHistory}
              companyId={companyId}
              positionId={positionId}
              position={position}
              candidates={candidates}
              setCandidates={setCandidates}
              planStatus={planStatus}
              ownershipStatus={ownershipStatus}
              companyConfig={companyConfig}
              refreshData={fetchData}
            />
          }
          {
            view === 'questions' &&
            <QuestionsPanel
              companyId={companyId}
              position={position}
              setPosition={setPosition}
              onPositionSaved={() => setView('candidates')}
            />
          }
          {
            view === 'settings' &&
            <SettingsPanel
              companyId={companyId}
              companyConfig={companyConfig}
              me={me}
              position={position}
              setPosition={setPosition}
              members={members}
              messageTriggers={messageTriggers}
              messageTemplates={messageTemplates}
              inviteExpiryOffsets={inviteExpiryOffsets}
              onPositionSaved={
                hasLostAccess => hasLostAccess
                  ? history.push(`/companies/${companyId}/positions`)
                  : setView('candidates')
              }
            />
          }
        </>
      }
    </FetchedContent >
  );
};

export default PositionPage;