import React from 'react';
import { Box } from '@material-ui/core';

const TabPanel = ({ children, active, ...props }) => {
  return (
    <Box hidden={!active} {...props}>
      {children}
    </Box>
  );
};

export default TabPanel;