import _ from 'lodash';

import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@material-ui/core/styles';
import { Box, Button, Card, Chip, CardActionArea, CardContent, Grid, Hidden, Tabs, Tab, Typography, useMediaQuery, Container } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import WorkIcon from '@material-ui/icons/Work';
import WorkOffIcon from '@material-ui/icons/WorkOff';
import CreatePositionIllustration from './positions/CreatePosition.svg';
import Alert from '@material-ui/lab/Alert';
import { useHistory, useParams } from "react-router";

import api from '../../API';
import EmptyTabState from '../EmptyTabState';
import FetchedContent from '../FetchedContent';
import TabPanel from '../TabPanel';
import useQuery from '../../hooks/useQuery';

const CandidateCountChip = styled(({ label, pathHref }) => {
  const history = useHistory();
  const handleClick = event => {
    event.stopPropagation();
    history.push(pathHref);
  };
  return <Chip label={label} onClick={handleClick} />;
})({
  '& .MuiChip-root': {
    cursor: 'pointer',
  }
});

const PositionCard = ({ companyId, position, companyConfig, ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const hasCandidates = _.sum(_.values(position.candidates)) > 0;
  const positionHref = `/companies/${companyId}/positions/${position.positionId}`;
  const respondedCount = companyConfig.isEvaluationEnabled
    ? position.candidates.responded
    : (
      (position.candidates.responded || 0) +
      (position.candidates.shortlisted || 0) +
      (position.candidates.rejected || 0)
    );
  const shortlistedCount = companyConfig.isEvaluationEnabled
    ? position.candidates.shortlisted
    : 0;
  const rejectedCount = companyConfig.isEvaluationEnabled
    ? position.candidates.rejected
    : 0;
  const candidatesSummary = hasCandidates
    ? (
      <Box mt={2}>
        <Grid
          container
          alignItems="center"
          spacing={1}
        >
          <Grid item key="invited">
            <CandidateCountChip
              label={t('{{count}} invited', { count: position.candidates.invited || 0 })}
              pathHref={`${positionHref}?tab=invited`}
            />
          </Grid>
          <Grid item key="responded">
            <CandidateCountChip
              label={t('{{count}} responded', { count: respondedCount || 0 })}
              pathHref={`${positionHref}?tab=responded`}
            />
          </Grid>
          {
            companyConfig.isEvaluationEnabled &&
            <Grid item key="shortlisted">
              <CandidateCountChip
                label={t('{{count}} shortlisted', { count: shortlistedCount || 0 })}
                pathHref={`${positionHref}?tab=shortlisted`}
              />
            </Grid>
          }
          {
            companyConfig.isEvaluationEnabled &&
            <Grid item key="rejected">
              <CandidateCountChip
                label={t('{{count}} rejected', { count: rejectedCount || 0 })}
                pathHref={`${positionHref}?tab=rejected`}
              />
            </Grid>
          }
        </Grid>
      </Box>
    ) : (
      <Box mt={1} fontStyle="italic">
        <Typography variant="subtitle1">
          {t('Awaiting candidates to be invited')}
        </Typography>
      </Box>
    );
  return (
    <Box key={position.positionId} mb={1} {...props}>
      <Card>
        <CardActionArea onClick={() => history.push(positionHref)}>
          <CardContent>
            <Typography variant="h6">
              {position.jobTitle}
            </Typography>
            {candidatesSummary}
          </CardContent>
        </CardActionArea>
      </Card>
    </Box>
  );
};

const AddPositionButton = ({ companyId, ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleAddPosition = () =>
    history.push(`/companies/${companyId}/new-position-wizard?cancellable=true`);

  return (
    <Button variant="contained" color="secondary" startIcon={<AddIcon />} onClick={handleAddPosition} {...props}>
      {t('Add position')}
    </Button>
  );
};

const filterStatus = (positions, status) =>
  positions.filter(position => position.currentStatus === status);

const PositionsTabs = ({ tab, setTab, companyId, positions, companyConfig }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const openPositions = filterStatus(positions, 'open');
  const closedPositions = filterStatus(positions, 'closed');

  const handleTabChange = (event, newTab) => setTab(newTab);

  const buildPositionCard = position =>
    <PositionCard
      key={position.positionId}
      companyId={companyId}
      position={position}
      companyConfig={companyConfig}
    />;

  return (
    <>
      <Hidden only={['md', 'lg', 'xl']}>
        <Box mb={1}>
          <AddPositionButton fullWidth companyId={companyId} />
        </Box>
      </Hidden>
      <Box
        display="flex"
        flexDirection="row-reverse"
        alignItems="center"
      >
        <Hidden smDown>
          <AddPositionButton companyId={companyId} />
        </Hidden>
        <Box flexGrow={1}>
          <Tabs variant={isSmall ? 'fullWidth' : 'standard'} value={tab} onChange={handleTabChange}>
            <Tab key="open" value="open" label={t('Open')} />
            <Tab key="closed" value="closed" label={t('Closed')} />
          </Tabs>
        </Box>
      </Box>
      <Box mt={2}>
        <TabPanel key="open" active={tab === 'open'}>
          {openPositions.map(buildPositionCard)}
          {
            !openPositions.length &&
            <EmptyTabState mt={10}
              icon={<WorkIcon fontSize="large" />}
              title={t('Open positions')}
              subtitle={t('Interviews are ongoing')}
            />
          }
        </TabPanel>
        <TabPanel key="closed" active={tab === 'closed'}>
          {closedPositions.map(buildPositionCard)}
          {
            !closedPositions.length &&
            <EmptyTabState mt={10}
              icon={<WorkOffIcon fontSize="large" />}
              title={t('Closed positions')}
              subtitle={t('Interviews have concluded')}
            />
          }
        </TabPanel>
      </Box>
    </>
  );
};

const CreatePositionArea = ({ companyId }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleCreatePosition = () =>
    history.push(`/companies/${companyId}/new-position-wizard?cancellable=true`);

  return (
    <Container maxWidth="xs">
      <Box mt={10} position="relative">
        <img src={CreatePositionIllustration} alt={t('Illustration of creating a position')} width="100%" height="100%" />
        <Box
          position="absolute"
          top={0} right={0} bottom={0} left={0}
          display="flex"
          alignItems="center"
          justifyContent="center"
          alignContent="content"
          justifyItems="center"
          style={{ backgroundColor: 'rgba(251, 241, 240, 0.70)' }}
        >
          <Box style={{ boxShadow: '0px 0px 30px 20px rgba(251, 241, 240, 0.9)' }}>
            <Button variant="contained" color="secondary" size="large" startIcon={<AddIcon />} onClick={handleCreatePosition}>
              {t('Create a position')}
            </Button>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

const PositionsPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { companyId } = useParams();
  const query = useQuery();
  const initialTab = query.get('tab') || 'open';
  const [tab, setTab] = useState(initialTab);
  const [positions, setPositions] = useState(null);
  const [companyConfig, setCompanyConfig] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const setTabWithHistory = (newTab) => {
    history.replace(`/companies/${companyId}/positions?tab=${newTab}`);
    setTab(newTab);
  };

  const fetchData = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      const [positionsResponse, companyConfig] = await Promise.all([
        await api.fetchPositions(companyId),
        await api.fetchCompanyConfig(companyId),
      ]);
      setPositions(positionsResponse.positions);
      setCompanyConfig(companyConfig);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      const message = e?.userMessage?.() || t('Unable to retrieve positions at this time. Please contact us.');
      setError(message);
    }
  }, [companyId, setLoading, setPositions, setCompanyConfig, t, setError]);

  useEffect(() => fetchData(), [fetchData]);

  const didDataLoad = !loading && positions !== null && companyConfig !== null;
  const hasPositions = positions && positions.length > 0;

  return (
    <FetchedContent loading={loading} mt={2}>
      {error && <Alert severity="error">{error}</Alert>}
      {
        didDataLoad && !hasPositions &&
        <CreatePositionArea companyId={companyId} />
      }
      {
        didDataLoad && hasPositions &&
        <PositionsTabs
          tab={tab}
          setTab={setTabWithHistory}
          companyId={companyId}
          positions={positions}
          companyConfig={companyConfig}
        />
      }
    </FetchedContent >
  );
};

export default PositionsPage;