import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const EmptyTabState = styled(({ icon, title, subtitle, ...props }) =>
  <Box display="flex" flexDirection="column" alignItems="center" {...props}>
    {icon}
    <Box mt={2}>
      <Typography variant="h6">
        {title}
      </Typography>
    </Box>
    <Typography variant="subtitle1">
      {subtitle}
    </Typography>
  </Box>
)(props => ({
  color: props.theme.palette.text.disabled,
}));

export default EmptyTabState;