import React from 'react';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';

export default function ProgressButton({ label, subLabel, progress, ...props }) {
  if (progress) {
    return (
      <Box mt={2} mb={subLabel ? 2 : 0} align="center">
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Box mt={2} mb={subLabel ? 1 : 0}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          {...props}
        >
          {label}
        </Button>
      </Box>
      {
        subLabel &&
        <Box color="text.disabled">
          <Typography variant="caption" display="block" align="center">
            {subLabel}
          </Typography>
        </Box>
      }
    </>
  );
};