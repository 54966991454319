import React from 'react';
import { Link } from '@material-ui/core';
import { styled } from '@material-ui/core/styles';

const BoldLink = styled(({ children, ...props }) =>
  <Link {...props}>{children}</Link>
)({
  fontWeight: 'bold',
});

export default BoldLink;