import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { trackError } from '../errors';
import utils from '../utils'
import { Button, Tooltip } from '@material-ui/core';

export const CopyTextLink = ({ linkLabel, text }) => {
  const { t } = useTranslation();
  const [copyStatus, setCopyStatus] = useState(null);

  const handleClickCopyText = event => {
    let hideAfterMillis = 750;
    try {
      utils.copyTextToClipboard(text);

      setCopyStatus({
        title: t("Copied to clipboard!")
      });
    } catch (error) {
      setCopyStatus({
        title: t("Unable to copy to clipboard...")
      });
      trackError(error);
      hideAfterMillis = 2000;
    } finally {
      setTimeout(() => {
        setCopyStatus(null);
      }, hideAfterMillis);
    }
  };

  return (
    <Tooltip open={!!copyStatus} title={copyStatus?.title}>
      <span>
        <Button color="primary" size="small" onClick={handleClickCopyText} style={{ cursor: 'pointer' }} startIcon={<FileCopyIcon />}>
          {linkLabel}
        </Button>
      </span>
    </Tooltip>
  );
};
