import * as Sentry from "@sentry/browser";

export const trackError = error => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.captureException(error);
  } else {
    console.log(error);
  }
};

export const trackErrorMessage = message => {
  if (process.env.NODE_ENV === 'production') {
    Sentry.captureMessage(message);
  } else {
    console.log(message);
  }
};