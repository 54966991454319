import React, { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Trans, useTranslation } from 'react-i18next';
import { Box, Link } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';

import api from '../../API';
import AuthPage from '../AuthPage';
import FetchedContent from '../FetchedContent';
import ProgressButton from '../ProgressButton';
import StyledProductName from '../StyledProductName';
import useAnalytics from '../../hooks/useAnalytics';

export default function ViewInvitePage() {
  const { t } = useTranslation();
  const analytics = useAnalytics();
  const { nonce } = useParams();
  const history = useHistory();
  const [invite, setInvite] = useState(null);
  const [loading, setLoading] = useState(true);
  const [accepting, setAccepting] = useState(false);
  const [error, setError] = useState(null);

  const handleClickAccept = async () => {
    setError(null);
    setAccepting(true);
    try {
      await api.confirmInvite(nonce);
      const me = await api.fetchMe();
      setAccepting(false);
      analytics.setUser(me.user.userId, me.user.email);
      history.push('/');
    } catch (e) {
      setAccepting(false);
      const message = e?.userMessage?.() || t('Unable to accept at this time. Please contact us.');
      setError(message);
    }
  };

  const fetchData = useCallback(async () => {
    setError(null);
    setLoading(true);
    try {
      const [invite] = await Promise.all([
        api.fetchInvite(nonce),
      ]);
      setLoading(false);
      setInvite(invite);
    } catch (e) {
      setLoading(false);
      const message = e?.userMessage?.() || t('Unable to retrieve invite at this time. Please contact us.');
      setError(message);
    }
  }, [nonce, setLoading, setInvite, t, setError]);

  useEffect(() => fetchData(), [fetchData]);

  const didDataLoad = !loading && invite !== null;

  const styledTitle = didDataLoad
    ? (
      <>
        <span>{t('Welcome to')}</span>&nbsp;
        <StyledProductName />
      </>
    ) : null;

  const styledSubtitle = didDataLoad
    ? (
      <Trans>
        <b>{invite.companyName}</b> has invited you to join their team on <Link href={process.env.REACT_APP_CANVASS_WEBSITE_HREF} target="_blank">Canvass</Link>. Our asynchronous video interviews help you hire the right people — faster.
      </Trans>
    ) : null;

  const styledSubLabel = didDataLoad && !invite.hasExistingAccount
    ? (
      <Trans>
        By accepting you agree to our <Link href={process.env.REACT_APP_CANVASS_TOS_HREF} target="_blank">Terms of Service</Link> and <Link href={process.env.REACT_APP_CANVASS_PRIVACY_POLICY_HREF} target="_blank">Privacy Policy</Link>
      </Trans>
    ) : null;

  if (!didDataLoad && error) {
    return (
      <AuthPage>
        <Alert severity="error">{error}</Alert>
      </AuthPage>
    );
  }

  return (
    <AuthPage title={styledTitle} subtitle={styledSubtitle}>
      <FetchedContent loading={loading}>
        {
          didDataLoad &&
          <Box mt={3}>
            {
              error &&
              <Box mb={2}>
                <Alert severity="error">{error}</Alert>
              </Box>
            }
            <ProgressButton
              label={t('Accept invitation')}
              subLabel={styledSubLabel}
              progress={accepting}
              onClick={handleClickAccept}
            />
          </Box>
        }
      </FetchedContent>
    </AuthPage>
  );
}