import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import React, { useCallback, useEffect, useState } from 'react';
import { styled, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Dialog, DialogActions, DialogTitle, DialogContent, IconButton, useMediaQuery, Typography, Checkbox, Divider } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Alert } from '@material-ui/lab';

import { IntroLabel, QuestionLabel, OutroLabel } from './ContentLabels';
import api from '../API';
import FetchedContent from './FetchedContent';
import useMediaGroups from '../hooks/useMediaGroups';

const ContentItemRow = ({ contentItem, mediaGroups, questionNumber, isLastItem, selectedContentTypes, selectedEphemeralIds, setSelectedEphemeralIds }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));

    const contentLabels = {
        intro: <IntroLabel />,
        question: <QuestionLabel label={'Q' + questionNumber} />,
        outro: <OutroLabel />,
    };

    const thumbnailAlts = {
        intro: t('Intro thumbnail'),
        question: t('Question {{questionNumber}} thumbnail', { questionNumber }),
        outro: t('Outro thumbnail'),
    }

    const hasThumbnail = !!contentItem.thumbnailImageGroupId;
    const imageHref = mediaGroups[contentItem.thumbnailImageGroupId]?.variants[0].contentHref
    const hasTextContent = !!contentItem.title || !!contentItem.content;

    const isSelected = selectedEphemeralIds.includes(contentItem.ephemeralId);
    const isDisabled = !isSelected
        && ['intro', 'outro'].includes(contentItem.contentType)
        && selectedContentTypes.includes(contentItem.contentType);

    const handleChange = (event) => {
        setSelectedEphemeralIds(ephemeralIds => {
            const newIds = _.without(ephemeralIds, contentItem.ephemeralId);
            if (event.target.checked) {
                newIds.push(contentItem.ephemeralId);
            }
            return newIds;
        });
    };

    return (
        <>
            <Box display="flex" alignItems="center" mt={2} mb={2} width="100%">
                <Checkbox isChecked={isSelected} onChange={handleChange} disabled={isDisabled} />
                <Box ml={1} mr={2}>
                    {contentLabels[contentItem.contentType]}
                </Box>
                <Box display="flex" flexDirection={isExtraSmall ? 'column' : 'row'} alignItems={isExtraSmall ? 'start' : 'center'}>
                    {hasThumbnail && !!imageHref && <img src={imageHref} width="150px" alt={thumbnailAlts[contentItem.contentType]} />}
                    {
                        hasTextContent &&
                        <Box ml={hasThumbnail && !!imageHref && !isExtraSmall ? 2 : 0} mt={isExtraSmall ? 1 : 0}>
                            {
                                !!contentItem.title &&
                                <Typography variant="subtitle2">
                                    {contentItem.title}
                                </Typography>
                            }
                            {
                                !!contentItem.content &&
                                <Typography variant="caption">
                                    {contentItem.content}
                                </Typography>
                            }
                        </Box>
                    }
                </Box>
            </Box>
            {!isLastItem && <Divider variant="inset" />}
        </>
    );
};

const PositionContentArea = ({ positionContent, mediaGroups, selectedContentTypes, selectedEphemeralIds, setSelectedEphemeralIds }) => {
    const [expanded, setExpanded] = useState(false);

    const hasIntro = positionContent.contentItems[0].contentType === 'intro';

    const handleChangeExpanded = (event, newExpanded) => {
        setExpanded(newExpanded);
    };

    return (
        <Accordion expanded={expanded} onChange={handleChangeExpanded}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography variant="subtitle2">
                    {positionContent.jobTitle}
                </Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Box display="flex" flexDirection="column" mt={-2} mb={-2} width="100%">
                    {_.map(positionContent.contentItems, (contentItem, index) => <ContentItemRow
                        key={contentItem.ephemeralId}
                        contentItem={contentItem}
                        mediaGroups={mediaGroups}
                        questionNumber={hasIntro ? index : index + 1} // little hacky...
                        isLastItem={index === positionContent.contentItems.length - 1}
                        selectedContentTypes={selectedContentTypes}
                        selectedEphemeralIds={selectedEphemeralIds}
                        setSelectedEphemeralIds={setSelectedEphemeralIds}
                    />)}
                </Box>
            </AccordionDetails>
        </Accordion>
    );
};

const ContentLibrary = ({ contentLibrary, mediaGroups, selectedEphemeralIds, setSelectedEphemeralIds }) => {
    const { t } = useTranslation();
    const theme = useTheme();

    if (!contentLibrary.positionsContent.length) {
        return (
            <Box p={8} textAlign="center" color={theme.palette.text.disabled}>
                <Typography variant="h6">
                    {t('No content available')}
                </Typography>
                <Typography variant="subtitle1">
                    {t("You're able to use content across positions")}
                </Typography>
            </Box>
        );
    }

    const selectedContentItems = extractSelectedContentItems(contentLibrary, selectedEphemeralIds);
    const selectedContentTypes = _.uniq(_.map(selectedContentItems, contentItem => contentItem.contentType));

    return _.map(
        contentLibrary.positionsContent,
        positionContent => <PositionContentArea
            key={positionContent.positionId}
            positionContent={positionContent}
            mediaGroups={mediaGroups}
            selectedContentTypes={selectedContentTypes}
            selectedEphemeralIds={selectedEphemeralIds}
            setSelectedEphemeralIds={setSelectedEphemeralIds}
        />
    );
};

const ContentLibraryDialogActions = styled(({ onCancel, onAddContent, isContentSelected, ...props }) => {
    const { t } = useTranslation();
    return (
        <DialogActions {...props}>
            <Button color="primary" onClick={onCancel} key="cancel">
                {t('Cancel')}
            </Button>
            <Button variant="contained" color="primary" onClick={onAddContent} disabled={!isContentSelected} key="add-content">
                {t('Add content')}
            </Button>
        </DialogActions>
    );
})(props => ({
    backgroundColor: props.theme.palette.background.default
}));

const ContentLibraryDialogContent = styled(({ children, ...props }) =>
    <DialogContent {...props}>
        {children}
    </DialogContent>
)(props => ({
    backgroundColor: props.theme.palette.background.default
}));

const ContentLibraryDialogTitle = styled(({ title, ...props }) =>
    <DialogTitle {...props}>
        {title}
    </DialogTitle>
)(props => ({
    backgroundColor: props.theme.palette.background.default,
    color: props.theme.palette.primary.dark
}));

const DialogCloseButton = styled(({ ...props }) =>
    <IconButton {...props}>
        <CloseIcon />
    </IconButton>
)(props => ({
    position: 'absolute',
    right: props.theme.spacing(1),
    top: props.theme.spacing(1),
    color: props.theme.palette.grey[500]
}));

const extractSelectedContentItems = (contentLibrary, selectedEphemeralIds) => {
    const allContentItems = _.flatMap(
        contentLibrary.positionsContent,
        positionContent => positionContent.contentItems,
    );
    const idToContentItem = _.fromPairs(_.map(
        allContentItems,
        contentItem => [contentItem.ephemeralId, contentItem],
    ));
    return _.filter(_.map(
        selectedEphemeralIds,
        ephemeralId => idToContentItem[ephemeralId],
    ));
};

const assignEphemeralIds = contentLibrary => {
    (contentLibrary?.positionsContent || []).forEach(positionContent => {
        positionContent.contentItems.forEach(contentItem => {
            if (!contentItem.ephemeralId) {
                contentItem.ephemeralId = uuidv4();
            }
        });
    });
};

const extractMediaGroupIdsForContentLibrary = contentLibrary => _.filter(_.flatMap(
    contentLibrary?.positionsContent || [],
    positionContent => _.map(positionContent.contentItems, contentItem => contentItem.thumbnailImageGroupId)
));

const ContentLibraryDialog = ({ companyId, positionId, open, onClose, onAddContent, ...props }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [contentLibrary, setContentLibrary] = useState(null);
    const mediaGroupIds = extractMediaGroupIdsForContentLibrary(contentLibrary);
    const { mediaGroups } = useMediaGroups(mediaGroupIds);
    const [selectedEphemeralIds, setSelectedEphemeralIds] = useState([]);

    assignEphemeralIds(contentLibrary);

    const handleAddContent = () => {
        const selectedContentItems = extractSelectedContentItems(contentLibrary, selectedEphemeralIds);
        onAddContent(selectedContentItems);
    };

    const fetchData = useCallback(async () => {
        setError(null);
        setLoading(true);
        try {
            const [contentLibrary] = await Promise.all([
                api.fetchContentLibrary(companyId),
            ]);
            setContentLibrary({
                ...contentLibrary,
                positionsContent: _.filter(
                    contentLibrary.positionsContent,
                    positionContent => positionContent.positionId !== positionId
                ),
            });
            setLoading(false);
        } catch (e) {
            setLoading(false);
            const message = e?.userMessage?.() || t('Unable to retrieve content at this time. Please contact us.');
            setError(message);
        }
    }, [companyId, positionId, setLoading, setContentLibrary, t, setError]);

    useEffect(() => fetchData(), [fetchData]);

    const didDataLoad = !loading && contentLibrary !== null;

    return (
        <Dialog fullScreen={isExtraSmall} open={open} onClose={onClose} fullWidth maxWidth="sm" {...props}>
            <DialogCloseButton onClick={onClose} />
            <ContentLibraryDialogTitle title={t("Content library")} />
            <ContentLibraryDialogContent>
                <FetchedContent loading={loading}>
                    {error && <Alert severity="error">{error}</Alert>}
                    {
                        didDataLoad &&
                        <ContentLibrary
                            contentLibrary={contentLibrary}
                            mediaGroups={mediaGroups}
                            selectedEphemeralIds={selectedEphemeralIds}
                            setSelectedEphemeralIds={setSelectedEphemeralIds}
                        />
                    }
                </FetchedContent>
            </ContentLibraryDialogContent>
            {
                didDataLoad && !!contentLibrary.positionsContent.length &&
                <ContentLibraryDialogActions
                    onCancel={onClose}
                    onAddContent={handleAddContent}
                    isContentSelected={selectedEphemeralIds.length > 0}
                />
            }
        </Dialog >
    );
};

export default ContentLibraryDialog;