import _ from 'lodash';
import { trackError } from './errors';
import utils from './utils';

const transformKeysRecursive = (obj, transformFunc) => _.transform(obj, (acc, value, key, target) => {
  const camelKey = _.isArray(target) ? key : transformFunc(key);
  acc[camelKey] = _.isObject(value) ? transformKeysRecursive(value, transformFunc) : value;
});

const toSnakeCase = _.partial(transformKeysRecursive, _, _.snakeCase);
const toCamelCase = _.partial(transformKeysRecursive, _, _.camelCase);

export class APIError extends Error {
  constructor(response, responseData, ...args) {
    super(`HTTP Error Response: ${response.status} ${response.statusText}`, ...args);
    this.response = response;
    this.responseData = responseData;
  }

  userMessage() {
    const message = this.responseData && this.responseData.detail;
    return !_.isArray(message) ? message : null;
  }

  statusCode() {
    return parseInt(this.response.status, 10);
  }

  applicationCode() {
    if (this.responseData && this.responseData.code) {
      return this.responseData.code;
    }
    return null;
  }

}

const SESSION_ID_KEY = 'canvass-session-id';

const hasSession = () => {
  return !!getSession();
};

const getSession = () => {
  return localStorage.getItem(SESSION_ID_KEY);
};

const setSession = (sessionId) => {
  localStorage.setItem(SESSION_ID_KEY, sessionId);
  notify('signin');
};

const clearSession = () => {
  localStorage.removeItem(SESSION_ID_KEY);
  notify('signout');
};

const parseData = (data) => {
  try {
    return JSON.parse(data);
  } catch (e) {
    return null;
  }
};

const makeRequest = async ({ method, path, headers, body, authenticated, skipToCamelCase } = {}) => {
  const args = { method };

  args.headers = Object.assign(
    { 'Content-Type': 'application/json' },
    headers || {},
  );
  if (authenticated && hasSession()) {
    args.headers['x-session-id'] = getSession();
  }

  if (typeof body !== 'undefined') {
    args.body = JSON.stringify(toSnakeCase(body));
  }

  const host = process.env.REACT_APP_CANVASS_API_HOSTNAME;
  const url = `${host}${path}`;
  const response = await fetch(url, args);
  let data = await response.text();
  const parsed = parseData(data);

  if (!response.ok) {
    if ([401, 403].includes(response.status)) {
      clearSession();
    }

    throw new APIError(response, parsed);
  }

  if (!parsed) {
    return;
  }
  return !skipToCamelCase ? toCamelCase(parsed) : parsed;
};

const checkEmail = async (email) => {
  return await makeRequest({
    method: 'post',
    path: '/api/v1/email-check',
    body: { email },
    authenticated: false,
  });
};

const createAccount = async (email, companyName, tags) => {
  await makeRequest({
    method: 'post',
    path: '/api/v1/accounts',
    body: { email, companyName, tags },
    authenticated: false,
  });
};

const sendMagicLink = async (email) => {
  await makeRequest({
    method: 'post',
    path: '/api/v1/magic-codes',
    body: { email },
    authenticated: false,
  });
};

const signin = async (email, shortnonce) => {
  const data = await makeRequest({
    method: 'post',
    path: '/api/v1/magic-code-sessions',
    body: { email, shortnonce },
    authenticated: false,
  });
  setSession(data.sessionId);
  return data.userId;
};

const signout = async () => {
  if (!hasSession()) {
    notify('signout');
    return;
  }

  try {
    const sessionId = getSession();
    await makeRequest({
      method: 'delete',
      path: `/api/v1/magic-link-sessions/${sessionId}`,
      authenticated: true,
    });
  } catch (error) {
    trackError(error);
  }
  clearSession();
}

const fetchMe = async () => {
  return await makeRequest({
    method: 'get',
    path: '/api/v1/me',
    authenticated: true,
  });
};

const fetchContentLibrary = async (companyId) => {
  return await makeRequest({
    method: 'get',
    path: `/api/v1/companies/${companyId}/content-library`,
    authenticated: true,
  });
};

const createPosition = async (companyId, newPosition) => {
  return await makeRequest({
    method: 'post',
    path: `/api/v1/companies/${companyId}/positions`,
    body: newPosition,
    authenticated: true,
  });
};

const fetchPosition = async (positionId) => {
  return await makeRequest({
    method: 'get',
    path: `/api/v1/positions/${positionId}`,
    authenticated: true,
  });
};

const fetchPositions = async (companyId) => {
  return await makeRequest({
    method: 'get',
    path: `/api/v1/companies/${companyId}/positions`,
    authenticated: true,
  });
};

const updatePosition = async (positionId, position) => {
  return await makeRequest({
    method: 'put',
    path: `/api/v1/positions/${positionId}`,
    body: position,
    authenticated: true,
  });
};

const updatePositionStatus = async (positionId, action) => {
  await makeRequest({
    method: 'put',
    path: `/api/v1/positions/${positionId}/${action}`,
    authenticated: true,
  });
};

const createApplyLink = async (positionId) => {
  return await makeRequest({
    method: 'post',
    path: `/api/v1/positions/${positionId}/apply-links`,
    authenticated: true,
  });
};

const sendPreviewInvite = async (positionId) => {
  await makeRequest({
    method: 'post',
    path: `/api/v1/positions/${positionId}/preview-invite`,
    authenticated: true,
  });
};

const fetchCandidates = async (positionId) => {
  return await makeRequest({
    method: 'get',
    path: `/api/v1/positions/${positionId}/candidates`,
    authenticated: true,
  });
};

const fetchCandidatesExport = async (positionId) => {
  return await makeRequest({
    method: 'get',
    path: `/api/v1/positions/${positionId}/candidates-export`,
    authenticated: true,
  });
};

const inviteCandidate = async (positionId, candidateName, email) => {
  await makeRequest({
    method: 'post',
    path: `/api/v1/positions/${positionId}/candidates`,
    body: { candidateName, email },
    authenticated: true,
  });
};

const inviteCandidates = async (positionId, newCandidates) => {
  await makeRequest({
    method: 'post',
    path: `/api/v1/positions/${positionId}/candidates/batch`,
    body: { newCandidates },
    authenticated: true,
  });
};

const fetchCandidate = async (candidateId) => {
  return await makeRequest({
    method: 'get',
    path: `/api/v1/candidates/${candidateId}`,
    authenticated: true,
  });
};

const fetchCandidateAnswerTranscripts = async (candidateId) => {
  const answerTranscripts = await makeRequest({
    method: 'get',
    path: `/api/v1/candidates/${candidateId}/answer-transcripts`,
    authenticated: true,
    skipToCamelCase: true,
  });
  return _.mapValues(answerTranscripts, toCamelCase);
};

const fetchCandidateAccessStatus = async (candidateId) => {
  return await makeRequest({
    method: 'get',
    path: `/api/v1/candidates/${candidateId}/access-status`,
    authenticated: true,
  });
};

const generateAnswerPackage = async (candidateId) => {
  return await makeRequest({
    method: 'post',
    path: `/api/v1/candidates/${candidateId}/answer-package`,
    authenticated: true,
  });
};

const createCandidateSharingDetails = async (candidateId) => {
  return await makeRequest({
    method: 'post',
    path: `/api/v1/candidates/${candidateId}/sharing-details`,
    authenticated: true,
  });
};

const fetchCandidateSharingDetails = async (candidateId) => {
  return await makeRequest({
    method: 'get',
    path: `/api/v1/candidates/${candidateId}/my-sharing-details`,
    authenticated: true,
  });
};

const deleteSharingPublicCandidateId = async (publicCandidateId) => {
  return await makeRequest({
    method: 'delete',
    path: `/api/v1/sharing-public-candidate-ids/${publicCandidateId}`,
    authenticated: true,
  });
};

const fetchPublicCandidate = async (publicCandidateId) => {
  return await makeRequest({
    method: 'get',
    path: `/api/v1/public-candidates/${publicCandidateId}`,
    authenticated: false,
  });
};

const fetchPublicCandidateAnswerTranscripts = async (publicCandidateId) => {
  const answerTranscripts = await makeRequest({
    method: 'get',
    path: `/api/v1/public-candidates/${publicCandidateId}/answer-transcripts`,
    authenticated: false,
    skipToCamelCase: true,
  });
  return _.mapValues(answerTranscripts, toCamelCase);
};

const reinviteCandidate = async (candidateId) => {
  await makeRequest({
    method: 'put',
    path: `/api/v1/candidates/${candidateId}/reinvite`,
    authenticated: true,
  });
};

const updateCandidateStatus = async (candidateId, action) => {
  await makeRequest({
    method: 'put',
    path: `/api/v1/candidates/${candidateId}/${action}`,
    authenticated: true,
  });
};

const rateAnswer = async (answerId, newRating) => {
  await makeRequest({
    method: 'put',
    path: `/api/v1/answers/${answerId}/rating`,
    body: { newRating },
    authenticated: true,
  });
};

const addMembersStatus = (members, status) => _.map(members, member => {
  member.status = status;
  return member;
});

const fetchCompanyConfig = async (companyId) => {
  return await makeRequest({
    method: 'get',
    path: `/api/v1/companies/${companyId}/config`,
    authenticated: true,
  });
};

const fetchCompanyMembers = async (companyId) => {
  const membersResponse = await makeRequest({
    method: 'get',
    path: `/api/v1/companies/${companyId}/members`,
    authenticated: true,
  });
  const activeMembers = addMembersStatus(membersResponse.members, 'active');
  const invitedMembers = addMembersStatus(membersResponse.invitedMembers, 'invited');
  const members = [...activeMembers, ...invitedMembers];
  return utils.sortObjectsBy(members, 'email');
};

const fetchAvailableMessageTriggers = async () => {
  return await makeRequest({
    method: 'get',
    path: `/api/v1/available-message-triggers`,
    authenticated: true,
  });
};

const fetchAvailableInviteExpiryOffsets = async () => {
  return await makeRequest({
    method: 'get',
    path: `/api/v1/available-invite-expiry-offsets`,
    authenticated: true,
  });
};

const fetchCompanyMessageTemplates = async (companyId) => {
  return await makeRequest({
    method: 'get',
    path: `/api/v1/companies/${companyId}/message-templates`,
    authenticated: true,
  });
};

const fetchMessageTemplate = async (templateId) => {
  return await makeRequest({
    method: 'get',
    path: `/api/v1/message-templates/${templateId}`,
    authenticated: true,
  });
};

const createMessageTemplate = async (companyId, newMessageTemplate) => {
  const messageTemplate = await makeRequest({
    method: 'post',
    path: `/api/v1/companies/${companyId}/message-templates`,
    body: newMessageTemplate,
    authenticated: true,
  });
  notify('messageTemplate.created', { companyId, messageTemplate });
  return messageTemplate;
};

const updateMessageTemplate = async (templateId, updatedMessageTemplate) => {
  const messageTemplate = await makeRequest({
    method: 'put',
    path: `/api/v1/message-templates/${templateId}`,
    body: updatedMessageTemplate,
    authenticated: true,
  });
  notify('messageTemplate.updated', { messageTemplate });
  return messageTemplate;
};

const deleteMessageTemplate = async (templateId) => {
  await makeRequest({
    method: 'delete',
    path: `/api/v1/message-templates/${templateId}`,
    authenticated: true,
  });
  notify('messageTemplate.deleted', { templateId });
};

const fetchCompanyTheme = async (companyId) => {
  return await makeRequest({
    method: 'get',
    path: `/api/v1/companies/${companyId}/theme`,
    authenticated: true,
  });
};

const fetchCompany = async (companyId) => {
  return await makeRequest({
    method: 'get',
    path: `/api/v1/companies/${companyId}`,
    authenticated: true,
  });
};

const updateCompany = async (companyId, company) => {
  return await makeRequest({
    method: 'put',
    path: `/api/v1/companies/${companyId}`,
    body: company,
    authenticated: true,
  });
};

const deleteCompany = async (companyId) => {
  await makeRequest({
    method: 'delete',
    path: `/api/v1/companies/${companyId}`,
    authenticated: true,
  });
};

const fetchCompanyOwnershipStatus = async companyId =>
  await makeRequest({
    method: 'get',
    path: `/api/v1/companies/${companyId}/ownership-status`,
    authenticated: true,
  });

const fetchCompanyPlanStatus = async companyId =>
  await makeRequest({
    method: 'get',
    path: `/api/v1/companies/${companyId}/plan-status`,
    authenticated: true,
  });

const updateTheme = async (companyId, theme) => {
  return await makeRequest({
    method: 'put',
    path: `/api/v1/companies/${companyId}/theme`,
    body: theme,
    authenticated: true,
  });
};

const inviteMember = async (companyId, email) => {
  const member = await makeRequest({
    method: 'post',
    path: `/api/v1/companies/${companyId}/members`,
    body: { email },
    authenticated: true,
  });
  member.status = 'invited';
  notify('member.invited', { companyId, member });
  return member;
};

const fetchInvite = async (nonce) => {
  return await makeRequest({
    method: 'get',
    path: `/api/v1/member-invites/${nonce}`,
    authenticated: false,
  });
};

const confirmInvite = async (nonce) => {
  const data = await makeRequest({
    method: 'post',
    path: `/api/v1/member-invites/confirmed`,
    body: { nonce },
    authenticated: false,
  });
  setSession(data.sessionId);
};

const removeMember = async (companyId, userId) => {
  await makeRequest({
    method: 'delete',
    path: `/api/v1/companies/${companyId}/members/${userId}`,
    authenticated: true,
  });
  notify('member.removed', { companyId, userId });
};

const createLiveRecording = async () => {
  return await makeRequest({
    method: 'post',
    path: `/api/v1/mux-live/user-recordings`,
    authenticated: true,
  });
};

const startLiveRecording = async recordingId => {
  await makeRequest({
    method: 'put',
    path: `/api/v1/mux-live/recordings/${recordingId}/start`,
  });
};

const stopLiveRecording = async recordingId => {
  await makeRequest({
    method: 'put',
    path: `/api/v1/mux-live/recordings/${recordingId}/stop`,
  });
};

const createMediaUpload = async (mediaGroupId, contentType, properties) => {
  return await makeRequest({
    method: 'post',
    path: `/api/v1/user-media-uploads`,
    body: { mediaGroupId, contentType, properties },
    authenticated: true,
  });
};

const completeMediaUploads = async mediaIds => {
  const preferTranscoder = process.env.REACT_APP_CANVASS_PREFER_TRANSCODER;
  await makeRequest({
    method: 'put',
    path: `/api/v1/media-uploads/complete?prefer_transcoder=${preferTranscoder}`,
    body: { mediaIds },
    authenticated: false,
  });
};

const fetchMediaGroups = async mediaGroupIds => {
  const mediaGroupIdsCsv = mediaGroupIds.join(',');
  const mediaGroups = await makeRequest({
    method: 'get',
    path: `/api/v1/media-upload-groups/${mediaGroupIdsCsv}`,
    authenticated: false,
    skipToCamelCase: true,
  });
  return _.mapValues(mediaGroups, toCamelCase);
};

const createCheckoutSession = async newCheckoutSession =>
  await makeRequest({
    method: 'post',
    path: '/api/v1/stripe/checkout-session',
    body: newCheckoutSession,
    authenticated: true,
  });

const fetchCheckoutSessionStatus = async frontendReferenceId =>
  await makeRequest({
    method: 'get',
    path: `/api/v1/stripe/checkout-session-status/${frontendReferenceId}`,
    authenticated: true,
  });

const createPaymentPortalSession = async newPaymentPortalSession =>
  await makeRequest({
    method: 'post',
    path: '/api/v1/stripe/portal-session',
    body: newPaymentPortalSession,
    authenticated: true,
  });

const createLeverOAuthHref = async (companyId) =>
  await makeRequest({
    method: 'post',
    path: `/api/v1/lever/oauth-href`,
    body: { companyId },
    authenticated: true,
  });

const completeLeverOAuth = async (state, code) =>
  await makeRequest({
    method: 'post',
    path: `/api/v1/lever/oauth-completion`,
    body: { state, code },
    authenticated: true,
  });

const abortLeverOAuth = async (state, error, errorDescription) =>
  await makeRequest({
    method: 'delete',
    path: `/api/v1/lever/oauth-completion`,
    body: { state, error, errorDescription },
    authenticated: true,
  });

const createIntegration = async (companyId, integrationType, authToken) =>
  await makeRequest({
    method: 'post',
    path: `/api/v1/companies/${companyId}/integrations`,
    body: { integrationType, authToken },
    authenticated: true,
  });

const fetchIntegrations = async companyId =>
  await makeRequest({
    method: 'get',
    path: `/api/v1/companies/${companyId}/integrations`,
    authenticated: true,
  });

const deleteIntegration = async integrationId =>
  await makeRequest({
    method: 'delete',
    path: `/api/v1/integrations/${integrationId}`,
    authenticated: true,
  });

const listenersForEvents = new Map([
  ['signin', []],
  ['signout', []],
  ['member.invited', []],
  ['member.removed', []],
  ['messageTemplate.created', []],
  ['messageTemplate.updated', []],
  ['messageTemplate.deleted', []],
]);

const on = (eventType, listener) => {
  off(eventType, listener);
  listenersForEvents.get(eventType).push(listener);
};

const off = (eventType, listener) => {
  const listeners = listenersForEvents.get(eventType);
  listenersForEvents.set(eventType, _.without(listeners, listener));
};

const handleListenerError = error => {
  trackError(error);
}

const notify = (eventType, eventData) => {
  const listeners = listenersForEvents.get(eventType);
  listeners.forEach(listener => {
    try {
      Promise.resolve(listener(eventData)).catch(handleListenerError);
    } catch (error) {
      handleListenerError(error)
    }
  });
};

const api = {
  hasSession,
  checkEmail,
  createAccount,
  sendMagicLink,
  signin,
  signout,
  fetchMe,
  fetchContentLibrary,
  createPosition,
  fetchPosition,
  fetchPositions,
  updatePosition,
  updatePositionStatus,
  createApplyLink,
  sendPreviewInvite,
  fetchCandidates,
  fetchCandidatesExport,
  inviteCandidate,
  inviteCandidates,
  fetchCandidate,
  fetchCandidateAnswerTranscripts,
  fetchCandidateAccessStatus,
  generateAnswerPackage,
  createCandidateSharingDetails,
  fetchCandidateSharingDetails,
  deleteSharingPublicCandidateId,
  fetchPublicCandidate,
  fetchPublicCandidateAnswerTranscripts,
  reinviteCandidate,
  updateCandidateStatus,
  rateAnswer,
  fetchCompanyConfig,
  fetchCompanyMembers,
  fetchAvailableMessageTriggers,
  fetchAvailableInviteExpiryOffsets,
  fetchCompanyMessageTemplates,
  fetchMessageTemplate,
  createMessageTemplate,
  updateMessageTemplate,
  deleteMessageTemplate,
  fetchCompanyTheme,
  fetchCompany,
  updateCompany,
  deleteCompany,
  fetchCompanyOwnershipStatus,
  fetchCompanyPlanStatus,
  updateTheme,
  inviteMember,
  fetchInvite,
  confirmInvite,
  removeMember,
  createMediaUpload,
  createLiveRecording,
  startLiveRecording,
  stopLiveRecording,
  completeMediaUploads,
  fetchMediaGroups,
  createCheckoutSession,
  fetchCheckoutSessionStatus,
  createPaymentPortalSession,
  createLeverOAuthHref,
  completeLeverOAuth,
  abortLeverOAuth,
  createIntegration,
  fetchIntegrations,
  deleteIntegration,
  on,
  off,
};

export default api;
