import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Typography } from '@material-ui/core';
import { useHistory, useParams } from 'react-router';

import api from '../../API';
import useQuery from '../../hooks/useQuery';

const STATUS_REFRESH_MILLIS = 5000;

const ConfirmCheckoutPage = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { companyId } = useParams();
  const query = useQuery();
  const frontendReferenceId = query.get('frontendReferenceId');
  const [checkoutSessionStatus, setCheckoutSessionStatus] = useState(null);

  const handleRedirect = useCallback(() => {
    let redirectPath = localStorage.getItem('canvass-post-checkout-redirect-path');
    localStorage.removeItem('canvass-post-checkout-redirect-path');
    if (!redirectPath) {
      redirectPath = `/companies/${companyId}`
    }
    history.push(redirectPath);
  }, [history, companyId]);

  const fetchCheckoutSessionStatus = useCallback(async () => {
    const response = await api.fetchCheckoutSessionStatus(frontendReferenceId);
    if (response.checkoutSessionStatus === 'completed') {
      handleRedirect();
    }
    setCheckoutSessionStatus(response.checkoutSessionStatus);
  }, [frontendReferenceId, handleRedirect, setCheckoutSessionStatus]);

  // Fetch whenever the frontend reference ID changes
  useEffect(fetchCheckoutSessionStatus, [fetchCheckoutSessionStatus]);

  // Poll in background until checkout session has completed
  useEffect(() => {
    let intervalId;
    if (checkoutSessionStatus !== 'completed') {
      intervalId = setInterval(fetchCheckoutSessionStatus, STATUS_REFRESH_MILLIS);
    }
    return () => clearInterval(intervalId);
  }, [checkoutSessionStatus, fetchCheckoutSessionStatus]);

  return (
    <Box
      position="absolute"
      top={0} right={0} bottom={0} left={0}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Box display="flex" flexDirection="column" alignItems="center">
        <CircularProgress color="primary" />
        <Box mt={2}>
          <Typography variant="h6">
            {t('Confirming payment...')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ConfirmCheckoutPage;