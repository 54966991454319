import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';

import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Bowser from "bowser";
import getBrowserRtc from 'get-browser-rtc';
import { AppBar, Backdrop, Box, Button, ButtonGroup, CircularProgress, Container, Dialog, DialogActions, DialogContent, Grid, Grow, IconButton, MenuItem, MenuList, Paper, Popper, Slide, Toolbar, Typography, useMediaQuery } from '@material-ui/core';
import VideocamIcon from '@material-ui/icons/Videocam';
import StopIcon from '@material-ui/icons/Stop';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import CloseIcon from '@material-ui/icons/Close';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SettingsIcon from '@material-ui/icons/Settings';
import { styled, useTheme } from '@material-ui/core/styles';

import Webcam from "react-webcam";
import { Trans, useTranslation } from 'react-i18next';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Space, SpaceEvent } from '@mux/spaces-web';

import api from '../API';
import { trackError, trackErrorMessage } from '../errors';
import FullWidthSelectControl from './FullWidthSelectControl';
import AspectRatio from './AspectRatio';
import useCheckMediaRecorder from '../hooks/useCheckMediaRecorder';

const WEBRTC_SUPPORT = !!getBrowserRtc();

const Countdown = styled(({ startingCount, pending, active, onFinish, ...props }) => {
  const [count, setCount] = useState(startingCount);

  const nextTick = useCallback(() => {
    if (count <= 1) {
      onFinish();
    } else {
      setCount(currentCount => currentCount - 1);
    }
  }, [count, onFinish, setCount]);

  useEffect(() => {
    let timerId;
    if (active) {
      timerId = setInterval(nextTick, 1000);
    }
    return () => clearInterval(timerId);
  }, [active, nextTick]);

  return (
    <Backdrop open={pending || active} {...props}>
      {pending && <CircularProgress color="inherit" />}
      {active && <>{count || null}</>}
    </Backdrop>
  );
})({
  zIndex: 1099,
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  fontSize: '80px',
  color: 'white',
});

const BASE_AUDIO_CONSTRAINTS = {};

const BASE_VIDEO_CONSTRAINTS = {
  facingMode: 'user',
};

const THUMBNAIL_CONTENT_TYPES = ['image/png'];
const SCREENSHOT_QUALITY = 1;
const IMAGE_SMOOTHING_ENABLED = true;

const SelectInputsDialog = ({ open, mediaStream, closeDialog, onUpdated, ...props }) => {
  const { t } = useTranslation();
  const [devices, setDevices] = useState([]);
  const [audioDeviceId, setAudioDeviceId] = useState();
  const [videoDeviceId, setVideoDeviceId] = useState();

  const buildDeviceLabel = (device) => {
    if (device.deviceId === 'default') {
      return t('{{deviceLabel}} (default)', { deviceLabel: device.label });
    }

    return device.label;
  };

  const handleDevices = useCallback(mediaDevices => {
    setDevices(mediaDevices);
  }, [setDevices]);

  useEffect(() => {
    // mediaStream included as dependency to address issue with Safari:
    // https://stackoverflow.com/questions/51387564/navigator-mediadevices-enumeratedevices-only-returns-default-devices-on-safari
    const canEnumerateDevices = !!navigator?.mediaDevices?.enumerateDevices;
    if (canEnumerateDevices) {
      navigator.mediaDevices.enumerateDevices().then(handleDevices);
    }
  }, [handleDevices, mediaStream]);

  useEffect(() => {
    if (mediaStream) {
      const audioTracks = mediaStream.getAudioTracks();
      if (audioTracks && audioTracks.length > 0) {
        const settings = audioTracks[0].getSettings();
        setAudioDeviceId(settings.deviceId);
      }
    }
  }, [mediaStream, setAudioDeviceId]);

  useEffect(() => {
    if (mediaStream) {
      const videoTracks = mediaStream.getVideoTracks();
      if (videoTracks && videoTracks.length > 0) {
        const settings = videoTracks[0].getSettings();
        setVideoDeviceId(settings.deviceId);
      }
    }
  }, [mediaStream, setVideoDeviceId]);

  const audioDevices = devices.filter(device => device.kind === 'audioinput');
  const audioOptions = audioDevices.map(
    device => [buildDeviceLabel(device), device.deviceId]
  );

  const videoDevices = devices.filter(device => device.kind === 'videoinput');
  const videoOptions = videoDevices.map(
    device => [buildDeviceLabel(device), device.deviceId]
  );

  const handleAudioDeviceChanged = (event) => {
    const newAudioDeviceId = event.target.value;
    setAudioDeviceId(newAudioDeviceId);
    onUpdated({
      audioDeviceId: newAudioDeviceId,
      videoDeviceId,
    });
  };

  const handleVideoDeviceChanged = (event) => {
    const newVideoDeviceId = event.target.value;
    setVideoDeviceId(newVideoDeviceId);
    onUpdated({
      audioDeviceId,
      videoDeviceId: newVideoDeviceId,
    });
  };

  return (
    <Dialog open={open} onClose={closeDialog} {...props}>
      <DialogContent>
        <Box>
          <FullWidthSelectControl
            label={t('Audio')}
            labelId="select-audio-label"
            value={audioDeviceId}
            onChange={handleAudioDeviceChanged}
            options={audioOptions}
          />
        </Box>
        <Box mt={2}>
          <FullWidthSelectControl
            label={t('Video')}
            labelId="select-video-label"
            value={videoDeviceId}
            onChange={handleVideoDeviceChanged}
            options={videoOptions}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={closeDialog} color="primary">
          {t('Close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const WhiteSettingsIcon = styled(({ ...props }) =>
  <SettingsIcon {...props} />
)({
  color: 'white',
  fontSize: '2em',
});

const ExpandGroupButton = styled(({ ...props }) => {
  return (
    <Button {...props}>
      <ArrowDropDownIcon />
    </Button>
  );
})({
  width: 'auto !important'
});

const RecordButton = ({ onClickRecord, onSelectFile }) => {
  const { t } = useTranslation();

  const anchorRef = useRef(null);
  const inputFileRef = useRef(null);
  const [open, setOpen] = useState(false);

  const handleChangeFile = event => {
    if (event.target.files.length > 0) {
      onSelectFile(event.target.files[0]);
    }
  };

  const handleClickUploadExisting = () => {
    inputFileRef.current.click();
    setOpen(false);
  };

  const handleToggle = event => {
    event.stopPropagation();
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <input
        type="file"
        ref={inputFileRef}
        onChange={handleChangeFile}
        style={{ display: 'none' }}
      />
      <ButtonGroup ref={anchorRef} variant="contained" color="secondary">
        <Button size="large" startIcon={<VideocamIcon />} onClick={onClickRecord}>
          {t('Record')}
        </Button>
        <ExpandGroupButton size="small" onClick={handleToggle} />
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} placement="bottom-end" role={undefined} transition disablePortal style={{ zIndex: 3000 }}>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="video-source-menu">
                  <MenuItem key="upload-existing" onClick={handleClickUploadExisting}>
                    <CloudUploadIcon />&nbsp;&nbsp;<span>{t('Upload existing')}</span>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

const extractThumbnails = (videoPlayer, canvas) => {
  const ctx = canvas.getContext("2d");
  ctx.imageSmoothingEnabled = IMAGE_SMOOTHING_ENABLED;
  ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
  const thumbnails = THUMBNAIL_CONTENT_TYPES.map(
    contentType => ({
      contentType,
      base64Data: canvas.toDataURL(contentType, SCREENSHOT_QUALITY),
      properties: {
        width: canvas.width,
        height: canvas.height,
      },
    })
  );
  return thumbnails;
};

const getThumbnailsFromWebcam = webcam => {
  // Don't use the getScreenshot() method of the webcam directly
  // because it'll be the mirror of the video recording...
  const { video } = webcam;
  const canvas = webcam.getCanvas();
  return extractThumbnails(video, canvas);
};

const getThumbnailsFromFile = (file, seekTo = 0.0) => {
  /*
   * Inspired by:
   * https://stackoverflow.com/questions/23640869/create-thumbnail-from-video-file-via-file-input
   */
  return new Promise((resolve, reject) => {
    const videoPlayer = document.createElement('video');
    videoPlayer.setAttribute('src', URL.createObjectURL(file));
    videoPlayer.load();
    videoPlayer.addEventListener('error', reject);
    videoPlayer.addEventListener('loadedmetadata', () => {
      if (videoPlayer.duration < seekTo) {
        reject();
        return;
      }
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        videoPlayer.currentTime = seekTo;
      }, 200);
      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener('seeked', () => {
        const canvas = document.createElement("canvas");
        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;
        const thumbnails = extractThumbnails(videoPlayer, canvas);
        resolve(thumbnails);
      });
    });
  });
};

const Videobooth = ({ onUploaded, isAborted, setAbortable }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isExtraSmall = useMediaQuery(theme.breakpoints.down('xs'));
  const webcamRef = useRef(null);
  const recorderRef = useRef(null);
  const [mediaStream, setMediaStream] = useState();
  const [audioDeviceId, setAudioDeviceId] = useState(
    localStorage.getItem('canvass-audio-device-id'),
  );
  const [videoDeviceId, setVideoDeviceId] = useState(
    localStorage.getItem('canvass-video-device-id'),
  );
  const [isConnecting, setIsConnecting] = useState(true);
  const [videoSizeProps, setVideoSizeProps] = useState({});
  const [isSelectingInputs, setIsSelectingInputs] = useState(false);
  const [isCountingDown, setIsCountingDown] = useState(false);
  const [isConnectingToRecorderService, setIsConnectingToRecorderService] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [connectError, setConnectError] = useState();
  const [saveError, setSaveError] = useState();
  const [recording, setRecording] = useState();
  const [space, setSpace] = useState();
  const [participant, setParticipant] = useState();
  const {
    error: recorderError,
    contentType: supportedContentType,
  } = useCheckMediaRecorder();

  const canUseRecorderService = WEBRTC_SUPPORT;
  const shouldForceRecorderService = localStorage.getItem('canvass-force-recorder-service') === 'true';
  const shouldUseRecorderService = canUseRecorderService && (
    shouldForceRecorderService || !supportedContentType
  );

  const audioConstraints = useMemo(() => {
    const constraints = { ...BASE_AUDIO_CONSTRAINTS };
    if (audioDeviceId) {
      constraints.deviceId = audioDeviceId;
    }
    return constraints;
  }, [audioDeviceId]);

  const videoConstraints = useMemo(() => {
    const constraints = { ...BASE_VIDEO_CONSTRAINTS };

    const browserSettings = Bowser.parse(window.navigator.userAgent);
    if (browserSettings?.platform?.type === 'desktop') {
      constraints.width = 852;
      constraints.height = 480;
      constraints.aspectRatio = 1.777777778;
    }

    if (videoDeviceId) {
      constraints.deviceId = videoDeviceId;
    }

    return constraints;
  }, [videoDeviceId]);

  const handleUserMedia = (mediaStream) => {
    setMediaStream(mediaStream);
    setIsConnecting(false);
    const videoTracks = mediaStream.getVideoTracks();
    const settings = videoTracks[0].getSettings();
    const sizeProps = (isExtraSmall || !settings.aspectRatio || settings.aspectRatio <= 1)
      ? { height: '100%' }
      : { width: '100%' };
    setVideoSizeProps(sizeProps);
  };

  const handleUserMediaError = (error) => {
    setIsConnecting(false);
    setConnectError(error);
  };

  const connectToRecorderService = async () => {
    setIsConnectingToRecorderService(true);

    let newRecording, newSpace, newParticipant;
    try {
      newRecording = await api.createLiveRecording();
      newSpace = new Space(newRecording.muxSpaceJwt);
      newParticipant = await newSpace.join();

      const localTracks = mediaStream.getTracks().map(mediaTrack =>
        newParticipant.localTrackFromMediaTrack(
          mediaTrack,
          false, /* Not a screen share... */
        )
      );
      await newParticipant.publishTracks(localTracks);
    } catch (error) {
      trackError(error);
      setSaveError(t('Unable to connect to recorder'));
      return;
    }

    setRecording(newRecording);
    setSpace(newSpace);
    setParticipant(newParticipant);

    try {
      // There's few seconds lag before stream is actually captured, so
      // initiate recording before starting the countdown...
      await api.startLiveRecording(newRecording.recordingId);
    } catch (error) {
      trackError(error);
      setSaveError(t('Unable to start recording'));
      return;
    }

    setIsConnectingToRecorderService(false);
    startCountdown();
  };

  const startCountdown = () => {
    setIsCountingDown(true);
    setAbortable(false);
  };

  const handleClickRecord = () => {
    if (shouldUseRecorderService) {
      connectToRecorderService();
    } else {
      startCountdown();
    }
  };

  const handleSelectFile = async existingFile => {
    if (!existingFile.type.startsWith('video')) {
      setSaveError(t('Must upload a video file'));
      setAbortable(true);
    } else {
      setIsSaving(true);
      let thumbnails;
      try {
        thumbnails = await getThumbnailsFromFile(existingFile);
      } catch (error) {
        setSaveError(t('Unable to extract a thumbnail'));
        setAbortable(true);
        return;
      }
      const videos = [{ blobData: existingFile }];
      await saveMediaToBackend(thumbnails, videos)
    }
  };

  const handleCountdownFinish = async () => {
    setIsCountingDown(false);
    setIsRecording(true);

    const thumbnails = getThumbnailsFromWebcam(webcamRef.current);

    const videoTracks = mediaStream.getVideoTracks();
    const videoSettings = videoTracks[0].getSettings();

    const audioTracks = mediaStream.getAudioTracks();
    const audioSettings = audioTracks[0].getSettings();

    const browserSettings = Bowser.parse(window.navigator.userAgent);

    const videoPromises = [];

    if (shouldUseRecorderService) {
      videoPromises.push(
        new Promise(resolve => {
          space.on(SpaceEvent.BroadcastStateChanged, isBroadcasting => {
            if (!isBroadcasting) {
              resolve(_.pick(recording, ['videoId', 'videoGroupId']));
              participant.unpublishAllTracks().then(() => {
                space.leave();
              });
            }
          });
        })
      );
    }

    if (supportedContentType) {
      // MediaRecorder can be tempermental so don't actually force it to use
      // the content type we've deemed as best supported... It's good enough to 
      // simply know that there is a supported type going in then see what we get...
      recorderRef.current = new MediaRecorder(webcamRef.current.stream);

      const recordedBlobs = [];
      recorderRef.current.addEventListener(
        'dataavailable',
        event => {
          if (event.data && event.data.size > 0) {
            recordedBlobs.push(event.data);
          }
        }
      );

      videoPromises.push(
        new Promise(resolve => {
          recorderRef.current.addEventListener(
            'stop', () => {
              const combinedBlob = new Blob(recordedBlobs, {
                type: recordedBlobs.length > 0 ? recordedBlobs[0].type : supportedContentType
              });
              resolve({ data: combinedBlob });
            },
          );
        })
      );

      recorderRef.current.start(250);
    }

    const videoData = await Promise.all(videoPromises);
    const videoMetadata = { videoSettings, audioSettings, browserSettings };
    const videos = videoData.map(result => {
      if (result.videoId) {
        return {
          ...result,
          ...videoMetadata,
        };
      }
      return {
        blobData: result.data,
        videoSize: result.data.size,
        ...videoMetadata,
      }
    });

    await saveMediaToBackend(thumbnails, videos);
  };

  const handleClickStop = async () => {
    setIsRecording(false);
    setIsSaving(true);

    if (shouldUseRecorderService) {
      await api.stopLiveRecording(recording.recordingId);
    }

    if (recorderRef.current) {
      recorderRef.current.stop();
      recorderRef.current = null;
    }
  };

  const saveMediaToBackend = async (thumbnails, videos) => {
    if (isAborted) {
      return;
    }

    try {
      const thumbnailImageGroupId = uuidv4();
      const thumbnailMediaIds = [];
      const thumbnailErrors = new Set();
      for (const { base64Data, contentType, properties } of thumbnails) {
        const thumbnailBlob = await fetch(base64Data).then(r => r.blob());
        if (thumbnailBlob.size <= 0) {
          thumbnailErrors.add(t('Empty file encountered'))
          continue;
        }
        const thumbnailDetails = await api.createMediaUpload(
          thumbnailImageGroupId, contentType, properties,
        );
        const thumbnailResponse = await fetch(thumbnailDetails.uploadUrl, {
          method: 'PUT',
          headers: {
            'Content-Type': contentType,
          },
          body: thumbnailBlob,
        });
        if (!thumbnailResponse.ok) {
          thumbnailErrors.add(t('Unable to upload thumbnail'))
          continue;
        }
        thumbnailMediaIds.push(thumbnailDetails.mediaId);
      }

      if (thumbnailMediaIds.length <= 0) {
        const thumbnailErrorMessage = [t('Unable to capture thumbnail'), ...thumbnailErrors].join('. ') + '.';
        setSaveError(thumbnailErrorMessage);
        setAbortable(true);
        trackErrorMessage(thumbnailErrorMessage);
        return;
      }

      const videoGroupIds = _.filter(videos.map(video => video.videoGroupId));
      const videoGroupId = videoGroupIds.length > 0 ? videoGroupIds[0] : uuidv4();
      const videoMediaIds = [];
      const videoErrors = new Set();
      for (const video of videos) {
        const properties = _.pick(
          video,
          ['videoSize', 'audioSettings', 'videoSettings', 'browserSettings'],
        );

        if (video.videoId) {
          videoMediaIds.push(video.videoId);
        } else {
          if (video.blobData.size <= 0) {
            videoErrors.add(t('Empty file encountered'));
            continue;
          }
          const videoDetails = await api.createMediaUpload(
            videoGroupId, video.blobData.type, properties,
          );
          const videoResponse = await fetch(videoDetails.uploadUrl, {
            method: 'PUT',
            headers: {
              'Content-Type': video.blobData.type,
            },
            body: video.blobData,
          });
          if (!videoResponse.ok) {
            videoErrors.add(t('Upload failure'));
            continue;
          }
          videoMediaIds.push(videoDetails.mediaId);
        }
      }

      if (videoMediaIds.length <= 0) {
        const videoErrorMessage = [t('Unable to record video'), ...videoErrors].join('. ') + '.';
        setSaveError(videoErrorMessage);
        setAbortable(true);
        trackErrorMessage(videoErrorMessage);
        return;
      }

      const mediaIds = [...videoMediaIds, ...thumbnailMediaIds];
      await api.completeMediaUploads(mediaIds);

      onUploaded({ thumbnailImageGroupId, videoGroupId });
    } catch (error) {
      setSaveError(t('Unable to save video'));
      setAbortable(true);
      trackError(error);
      return;
    }
  };

  const handleSelectInputs = () => {
    setIsSelectingInputs(true);
  };

  const handleInputsUpdated = ({ audioDeviceId, videoDeviceId }) => {
    localStorage.setItem('canvass-audio-device-id', audioDeviceId);
    setAudioDeviceId(audioDeviceId);
    localStorage.setItem('canvass-video-device-id', videoDeviceId);
    setVideoDeviceId(videoDeviceId);
  };

  const unableToRecord = recorderError && !shouldUseRecorderService;
  if (unableToRecord) {
    return (
      <Alert severity="error">
        <AlertTitle>{recorderError}</AlertTitle>
        <Trans>
          We're unable to record video in your browser. Please try another
          browser or device. Don't hesitate to contact us at {{ supportEmail: process.env.REACT_APP_CANVASS_SUPPORT_EMAIL }} for
          further assistance.
        </Trans>
      </Alert>
    );
  }

  if (connectError) {
    return (
      <Alert severity="error">
        <AlertTitle>{connectError.message}</AlertTitle>
        <Trans>
          Unable to access your camera or microphone. Please ensure that you've
          granted the necessary permissions in your browser settings. Don't hesitate
          to contact us at {{ supportEmail: process.env.REACT_APP_CANVASS_SUPPORT_EMAIL }} for
          further assistance.
        </Trans>
      </Alert>
    );
  }

  if (saveError) {
    return (
      <Alert severity="error">
        <AlertTitle>{saveError}</AlertTitle>
        <Trans>
          Unable to save your video to our platform. Please try recording from another device
          and contact us at {{ supportEmail: process.env.REACT_APP_CANVASS_SUPPORT_EMAIL }} for
          assistance if the problem persists.
        </Trans>
      </Alert>
    );
  }

  const showSpinner = isConnecting || isSaving;
  const canRecord = !isConnecting && !isConnectingToRecorderService && !isSaving && !isCountingDown && !isRecording;
  const hasControls = canRecord || isRecording;

  return (
    <>
      <Box display={showSpinner ? "none" : "block"} position='relative' fontSize={0}>
        <AspectRatio horizontal={16} vertical={9}>
          <Webcam
            muted
            ref={webcamRef}
            audio={true}
            imageSmoothing={IMAGE_SMOOTHING_ENABLED}
            mirrored={true}
            audioConstraints={audioConstraints}
            forceScreenshotSourceSize={true}
            screenshotFormat={THUMBNAIL_CONTENT_TYPES[0]} // Not actually used
            screenshotQuality={SCREENSHOT_QUALITY} // Not actually used
            videoConstraints={videoConstraints}
            onUserMedia={handleUserMedia}
            onUserMediaError={handleUserMediaError}
            {...videoSizeProps}
          />
        </AspectRatio>
        <Countdown startingCount={3} pending={isConnectingToRecorderService} active={isCountingDown} onFinish={handleCountdownFinish} />
        {
          canRecord &&
          <Box position="absolute" top={0} right={0}>
            <IconButton onClick={handleSelectInputs}>
              <WhiteSettingsIcon />
            </IconButton>
          </Box>
        }
      </Box>
      <Box display="flex" flexDirection="column" alignItems="center">
        {
          showSpinner &&
          <>
            <CircularProgress color="secondary" />
            <Box mt={2}>
              {
                isConnecting &&
                <Typography variant="h6">
                  {t('Connecting to your camera...')}
                </Typography>
              }
              {
                isSaving &&
                <Typography variant="h6">
                  {t('Saving your video...')}
                </Typography>
              }
            </Box>
          </>
        }
        <Box mt={hasControls ? 5 : 10}>
          {
            canRecord
            &&
            <RecordButton onClickRecord={handleClickRecord} onSelectFile={handleSelectFile} />
          }
          {
            isRecording
            &&
            <Button size="large" variant="contained" color="secondary"
              startIcon={<StopIcon />}
              onClick={handleClickStop}
            >
              {t('Stop')}
            </Button>
          }
        </Box>
      </Box>
      <SelectInputsDialog
        open={isSelectingInputs}
        mediaStream={mediaStream}
        closeDialog={() => setIsSelectingInputs(false)}
        onUpdated={handleInputsUpdated}
      />
    </>
  );
};

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useVideoboothMaxWidth = () => {
  const hasLargeHeight = useMediaQuery('(min-height:950px)');
  const hasMediumHeight = useMediaQuery('(min-height:775px)');
  if (hasLargeHeight) {
    return 'lg';
  } else if (hasMediumHeight) {
    return 'md';
  } else {
    return 'sm';
  }
};

const RecordVideoDialog = ({ title, open, onClose, onUploaded, ...props }) => {
  const { t } = useTranslation();
  const [isCloseAvailable, setIsCloseAvailable] = useState(true);
  const maxWidth = useVideoboothMaxWidth();
  title = title || t('Record video');
  return (
    <Dialog fullScreen open={open} TransitionComponent={Transition} {...props}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton edge="start" color="inherit" disabled={!isCloseAvailable} onClick={onClose}>
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
        <Container maxWidth={maxWidth}>
          <Videobooth onUploaded={onUploaded} isAborted={!open} setAbortable={setIsCloseAvailable} />
        </Container>
      </Grid>
    </Dialog >
  );
};

export default RecordVideoDialog;