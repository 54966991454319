import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Link, TextField, Typography } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { styled } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import { generatePath } from "react-router";
import StyledProductName from '../StyledProductName';
import ProgressButton from '../ProgressButton'
import AuthPage from '../AuthPage';
import api from '../../API';
import { APIError } from '../../API';
import useQuery from '../../hooks/useQuery';

const SigninLink = ({ ...props }) => {
  const { t } = useTranslation();
  return (
    <Box align="center" {...props}>
      <Typography variant="overline" component="span">
        {t('What is Canvass?')}
      </Typography>{' '}
      <Link variant="overline" href={process.env.REACT_APP_CANVASS_WEBSITE_HREF} target="_blank">
        {t('Learn more')}
      </Link>
    </Box>
  );
};

const RegisterForm = styled(({ tags, ...props }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [error, setError] = useState(null);

  const handleEmailChange = event => {
    setError(null);
    setEmail(event.target.value);
  };

  const handleCompanyNameChange = event => {
    setError(null);
    setCompanyName(event.target.value);
  };

  const canContinue = !!email && !!companyName;

  const handleContinue = async event => {
    event?.preventDefault();

    if (!canContinue) {
      return;
    }

    setLoading(true)
    try {
      await api.createAccount(email, companyName, tags);
      setLoading(false);
      history.push(`/magic-code?email=${encodeURIComponent(email)}`);
    } catch (e) {
      setLoading(false);
      if (e instanceof APIError && e.applicationCode() === 'already_exists') {
        setError(
          <Trans>
            You already have an account and can <Link href={'#' + generatePath('/signin')}>sign in</Link>.
          </Trans>
        );
      } else {
        setError(e?.userMessage?.() || t('Unable to register at this time. Please contact us.'));
      }
    }
  };

  return (
    <form noValidate {...props} onSubmit={handleContinue}>
      <Box mb={2} textAlign="center" fontSize="1.1rem">
        <Trans>
          You'll start with <span style={{ textDecoration: 'underline' }}>{{ interviewCredits: process.env.REACT_APP_CANVASS_WELCOME_PACK_SIZE }} interview credits</span> 🎉
        </Trans>
      </Box>
      {error && <Alert severity="error">{error}</Alert>}
      <TextField
        key="email"
        variant="outlined"
        margin="normal"
        fullWidth
        label={t('Email address')}
        autoFocus
        disabled={loading}
        value={email}
        onChange={handleEmailChange}
      />
      <TextField
        key="company-name"
        variant="outlined"
        margin="normal"
        fullWidth
        disabled={loading}
        label={t('Company name')}
        value={companyName}
        onChange={handleCompanyNameChange}
      />
      <ProgressButton
        label={t('Continue')}
        subLabel={
          <Trans>
            By continuing you agree to our <Link href={process.env.REACT_APP_CANVASS_TOS_HREF} target="_blank">Terms of Service</Link> and <Link href={process.env.REACT_APP_CANVASS_PRIVACY_POLICY_HREF} target="_blank">Privacy Policy</Link>
          </Trans>
        }
        progress={loading}
        disabled={!canContinue}
        onClick={handleContinue}
      />
    </form>
  );
}
)(props => ({
  marginTop: props.theme.spacing(2),
}));

export default function RegisterPage() {
  const { t } = useTranslation();
  const query = useQuery();
  const tags = query.get('tags');

  const styledTitle = (
    <>
      <span>{t('Sign up for')}</span>&nbsp;
      <StyledProductName />
    </>
  );

  return (
    <AuthPage title={styledTitle}>
      <RegisterForm tags={tags} />
      <SigninLink mt={6} />
    </AuthPage>
  );
}