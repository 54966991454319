import { useEffect } from 'react';

const useCrispChat = () => {
  useEffect(() => {
    window.$crisp = [];

    window.$crisp.push(["on", "session:loaded", () => {
      // Hidden by default unless has unread messages
      if (window.$crisp.get("chat:unread:count") <= 0) {
        window.$crisp.push(["do", "chat:hide"]);
      }
    }]);

    window.$crisp.push(["on", "chat:closed", () => {
      // Hide whenever closed
      window.$crisp.push(["do", "chat:hide"]);
    }]);

    window.$crisp.push(["on", "message:received", () => {
      // Show whenever receive an inbound message
      window.$crisp.push(["do", "chat:show"]);
    }]);

    window.CRISP_WEBSITE_ID = process.env.REACT_APP_CANVASS_CRISP_WEBSITE_ID;
    const script = document.createElement("script");
    script.src = "https://client.crisp.chat/l.js";
    script.async = true;
    document.getElementsByTagName("head")[0].appendChild(script);
  }, []);
};

export default useCrispChat;
