import _ from 'lodash';

import React, { useCallback, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import {
  HashRouter,
  Route,
  Switch,
  useHistory,
  useLocation,
} from "react-router-dom";
import { useParams } from 'react-router';
import { useTheme } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';
import { Box, Container, CssBaseline, Snackbar, ThemeProvider, useMediaQuery } from '@material-ui/core';

import api from './API';
import Navbar from './components/Navbar';
import PublicNavbar from './components/PublicNavbar';
import theme from './theme';

import SigninPage from './components/pages/SigninPage';
import MagicCodePage from './components/pages/MagicCodePage';
import ViewInvitePage from './components/pages/ViewInvitePage';
import RegisterPage from './components/pages/RegisterPage';
import CompaniesPage from './components/pages/CompaniesPage';
import CompleteLeverIntegration from './components/pages/CompleteLeverIntegration';
import ConfirmCheckoutPage from './components/pages/ConfirmCheckoutPage';
import PublicCandidatePage from './components/pages/PublicCandidatePage';
import NewPositionPage from './components/pages/NewPositionPage';
import PositionPage from './components/pages/PositionPage';
import PositionsPage from './components/pages/PositionsPage';
import CandidatePage from './components/pages/CandidatePage';

import useAnalytics from './hooks/useAnalytics';
import useCrispChat from './hooks/useCrispChat';

const SignoutListener = () => {
  const history = useHistory();

  const goToSignin = useCallback(() => {
    history.push('/signin');
  }, [history]);

  useEffect(() => {
    api.on('signout', goToSignin);
    return () => {
      api.off('signout', goToSignin);
    };
  }, [goToSignin]);

  return null;
};

const ScrollSpacer = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  return (
    <Box p={isSmall ? 1 : 7}>
      {/* Scroll spacer so components not pinned to bottom... */}
    </Box>
  );
};

const PublicPage = ({ children, ...props }) => {
  return (
    <Container maxWidth="md" {...props}>
      <PublicNavbar />
      {children}
      <ScrollSpacer />
    </Container>
  );
};

const CompanyPage = ({ children, ...props }) => {
  const { companyId } = useParams();
  return (
    <Container maxWidth="md" {...props}>
      <Navbar companyId={companyId} />
      {children}
      <ScrollSpacer />
    </Container>
  );
};

const CompanyLandingPage = () => {
  const history = useHistory();
  const { companyId } = useParams();
  useEffect(() => {
    history.replace(`/companies/${companyId}/positions`);
  }, [history, companyId])
  return null;
};

const LandingPage = () => {
  const history = useHistory();
  useEffect(() => {
    if (api.hasSession()) {
      history.replace('/companies');
    } else {
      history.replace('/signin');
    }
  }, [history]);
  return null;
};

const usePageviewEvents = () => {
  const location = useLocation();
  const analytics = useAnalytics();

  useEffect(() => {
    const properties = _.pick(location, ['pathname', 'search', 'hash']);
    analytics.trackEvent('pageview', properties);
  }, [location, analytics]);
};

const App = () => {
  usePageviewEvents();
  useCrispChat();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <SignoutListener />
      <Switch>
        <Route path="/signin">
          <SigninPage />
        </Route>
        <Route path="/register">
          <RegisterPage />
        </Route>
        <Route path="/magic-code">
          <MagicCodePage />
        </Route>
        <Route path="/invite/:nonce">
          <ViewInvitePage />
        </Route>
        <Route path="/complete-lever-integration">
          <CompleteLeverIntegration />
        </Route>
        <Route path="/companies/:companyId/confirm-checkout">
          <ConfirmCheckoutPage />
        </Route>
        <Route path="/candidates/:publicCandidateId">
          <PublicPage>
            <PublicCandidatePage />
          </PublicPage>
        </Route>
        <Route path="/companies/:companyId" render={props => (
          <Switch>
            <Route path={`${props.match.path}/new-position-wizard`}>
              <CompanyPage>
                <NewPositionPage />
              </CompanyPage>
            </Route>
            <Route path={`${props.match.path}/positions/:positionId/candidates/:candidateId`}>
              <CompanyPage maxWidth='lg'>
                <CandidatePage />
              </CompanyPage>
            </Route>
            <Route path={`${props.match.path}/positions/:positionId`}>
              <CompanyPage>
                <PositionPage />
              </CompanyPage>
            </Route>
            <Route path={`${props.match.path}/positions`}>
              <CompanyPage>
                <PositionsPage />
              </CompanyPage>
            </Route>
            <Route path={`${props.match.path}`}>
              <CompanyPage>
                <CompanyLandingPage />
              </CompanyPage>
            </Route>
          </Switch>
        )}
        />
        <Route path="/companies">
          <CompaniesPage />
        </Route>
        <Route path="/">
          <LandingPage />
        </Route>
      </Switch>
    </ThemeProvider>
  );
}

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const GlobalAlert = () => {
  const { pathname } = useLocation();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [severity, setSeverity] = useState(null);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const pendingAlertStr = localStorage.getItem('canvass-global-alert');
    localStorage.removeItem('canvass-global-alert');

    if (!!pendingAlertStr) {
      const pendingAlert = JSON.parse(pendingAlertStr);
      setMessage(pendingAlert.message);
      setSeverity(pendingAlert.severity);
      setOpen(true);
    }
  }, [pathname]);

  return (
    <Snackbar open={open} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} autoHideDuration={5000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity}>
        {message}
      </Alert>
    </Snackbar>
  );
};

const startApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <HashRouter>
        <ScrollToTop />
        <GlobalAlert />
        <App />
      </HashRouter>
    </React.StrictMode>,
    document.getElementById('root'),
  );
};

export default startApp;
