import { useTranslation } from 'react-i18next';

const usePacingOptions = () => {
  const { t } = useTranslation();

  const thinkingTimeOptions = [
    [t('None'), 0],
    [t('15 seconds'), 15],
    [t('30 seconds'), 30],
    [t('45 seconds'), 45],
    [t('1 minute'), 60],
    [t('2 minutes'), 120],
    [t('3 minutes'), 180],
    [t('4 minutes'), 240],
    [t('5 minutes'), 300],
    [t('6 minutes'), 360],
    [t('7 minutes'), 420],
    [t('8 minutes'), 480],
    [t('9 minutes'), 540],
    [t('10 minutes'), 600],
    [t('Unlimited'), -1],
  ];

  const answerTimeOptions = [
    [t('15 seconds'), 15],
    [t('30 seconds'), 30],
    [t('45 seconds'), 45],
    [t('1 minute'), 60],
    [t('2 minutes'), 120],
    [t('3 minutes'), 180],
    [t('4 minutes'), 240],
    [t('5 minutes'), 300],
    [t('6 minutes'), 360],
    [t('7 minutes'), 420],
    [t('8 minutes'), 480],
    [t('9 minutes'), 540],
    [t('10 minutes'), 600],
  ];

  const retakesOptions = [
    [t('None'), 0],
    [t('1 retake'), 1],
    [t('2 retakes'), 2],
    [t('3 retakes'), 3],
    [t('Unlimited'), -1],
  ];

  return { thinkingTimeOptions, answerTimeOptions, retakesOptions };
};

export default usePacingOptions;